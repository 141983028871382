// Librairies React
import React, { useState, useEffect } from 'react'
import useUser from '../hooks/useUser'
import DatePickerCustom from '../components/DatePickerCustom'
import TimePickerCustom from '../components/TimePickerCustom'
import TaskReservationEquipier from '../components/TaskReservationEquipier'
import useTaskTypes from '../hooks/useLoadDatas'
import Icon from '../components/Icon'

// Composant
export default function TaskReservation(props) {
  // Détermine le statut de l'utilisateur connecté
  const { user, checkUser, isManager, isConcierge, isCleaner } = useUser()

  const [selectedDate, setSelectedDate] = useState(props.datas.date.replace('+00:00', ''))

  const dateChange = (newdate) => {
    setSelectedDate(newdate)
    props.changeDate(newdate, props.index)
  }
  const timeChange = (newdate) => {
    setSelectedDate(newdate)
    props.changeTime(newdate, props.index)
  }

  let taskLocation = []
  if ( props.datas.in_location ) { // Si depuis location
    taskLocation = [
      {
        value: 1,
        label: 'Intervention pour une réservation',
      },
      {
        value: 0,
        label: 'Intervention hors réservation'
      }
    ]
  }
  else { // Si depuis logement
    taskLocation = [
      {
        value: 0,
        label: 'Intervention hors réservation'
      }
    ]
  }

  const [idLocation, setIdLocation] = useState(props.datas.in_location)
  const changeLocation = (e) => {
    const new_id_location = e.target.value
    setIdLocation(new_id_location)
    setRouteTaskTypes('/task-types?isOnLocation='+new_id_location)
    setIdTaskType(null)
    props.changeInput(e)
  }

  const [idTaskType, setIdTaskType] = useState(props.datas.taskType)
  const changeTaskType = (e) => {
    const new_id_task_type = e.target.value
    setIdTaskType(new_id_task_type)
    props.changeInput(e)
  }

  const [routeTaskTypes, setRouteTaskTypes] = useState('/task-types?isOnLocation='+(idLocation ? 1 : 0))
  const [fetchTaskTypes] = useTaskTypes()
  const [taskTypes, setTaskTypes] = useState([])
  useEffect(() => {
    if ( props.datas.id_event === 3 ) {
      loadTaskTypes()
    }
    async function loadTaskTypes() {
      let items = []
      const task_types = await fetchTaskTypes(routeTaskTypes)
      if ( !task_types.error ) {
        task_types['hydra:member'].forEach((item, i) => {
          items.push({
            value: item.id,
            label: item.label
          })
        })
        setTaskTypes([...items])
      }
    }
  }, [routeTaskTypes])


  // Traite les indispos sur la date de la tâche
  const [listTaskEquipiers, setTaskEquipiers] = useState([])
  useEffect(() => {
    loadTaskEquipiers()
    async function loadTaskEquipiers() {
      let taskEquipiers = []
      props.listEquipiers.forEach((listEquipier, i) => {
        let equipier = {...listEquipier, indispo: false}
        if ( equipier.indispos.length ) {
          equipier.indispos.forEach((indispo, i) => {
            // S'il y a des indispos, on compare les dates
            if ( props.datas.date >= indispo.startAt && props.datas.date <= indispo.endAt ) {
              equipier.indispo = true
            }
          })
        }
        else {
          equipier.indispo = false
        }
        taskEquipiers.push(equipier)
      })
      setTaskEquipiers([...taskEquipiers])
    }
  }, [props.listEquipiers])

  return (
    <div className={`task event_${props.datas.id_event}`}>
      <div className="name">
        {props.datas.name !== undefined
        ?
          <>
            <strong>{props.datas.name}</strong>

            {(props.datas.id_event === 3 && !props.datas.ticket) &&
              <>
                <div className={`input-field ${(idLocation !== null ? 'active' : '')}`}>
                  <label htmlFor={`location_${props.index}`}>Sélectionner le type d'intervention</label>
                  <select id={`location_${props.index}`} name={`location_${props.index}`}
                          onChange={(e) => changeLocation(e)}
                          defaultValue={idLocation !== null ? idLocation : 'default'}>
                    <option value="default" disabled>Le type de l'intervention *</option>
                    {
                      taskLocation.map( (option, i) => {
                        return <option key={i} value={option.value}>{option.label}</option>
                      })
                    }
                  </select>
                </div>

                <div className={`input-field ${(idTaskType !== null ? 'active' : '')}`}>
                  <label htmlFor={`taskType_${props.index}`}>Sélectionner le nom de l'intervention</label>
                  <select id={`taskType_${props.index}`} name={`taskType_${props.index}`}
                          onChange={(e) => changeTaskType(e)}
                          value={(idTaskType !== null ? idTaskType : 'default')}>
                    <option value="default" disabled>Le nom de l'intervention *</option>
                    {
                      taskTypes.map( (option, i) => {
                        return <option key={i} value={option.value}>{option.label}</option>
                      })
                    }
                  </select>
                </div>
              </>
            }
          </>
        :
          ''
        }

        {props.datas.precision !== undefined
        ?
          <div className="option">
            <div className="precision">
              <label htmlFor={`precision_${props.index}`}>Précision</label>
              <input id={`precision_${props.index}`} name={`precision_${props.index}`} type="text"
                     defaultValue={props.datas.precision}
                     onChange={(e) => props.changeInput(e)} />
            </div>
          </div>
        :
          ''
        }

        {props.datas.comment !== undefined
        ?
          <div className="option">
            <div className="comment">
              <label htmlFor={`comment_${props.index}`}>Commentaire</label>
              <textarea id={`comment_${props.index}`} name={`comment_${props.index}`}
                     defaultValue={props.datas.comment}
                     onChange={(e) => props.changeInput(e)} />
            </div>
          </div>
        :
          ''
        }

        {props.datas.id_event === 3 &&
          <div className="datetime">
            <div className="date">
              <label htmlFor={`date_${props.index}`}>Date</label>
              <DatePickerCustom
                date={selectedDate}
                onChange={(date) => dateChange(date)}
              />
            </div>
            <div className="time">
              <label htmlFor={`time_${props.index}`}>Heure</label>
              <TimePickerCustom
                time={selectedDate}
                onChange={(date) => timeChange(date)}
              />
            </div>
          </div>
        }
      </div>

      {props.datas.equipiers.map((equip, index) => {
          return (
            <TaskReservationEquipier key={index}
                                     index={index}
                                     task_index={props.index}
                                     listTaskEquipiers={listTaskEquipiers}
                                     durees={props.durees}
                                     datas={equip}
                                     changeInput={props.changeEquipierInput}
                                     changeTime={props.changeEquipierTime} />
          )
        })
      }

      <div className="task-actions">
        {(props.datas.id && props.datas.id_event === 3 && !props.datas.closed && (!props.datas.ticket || isManager)) &&
          <div className="delete-task">
            <button type="button" className="link" onClick={() => props.setDeleteTaskPopin(props.datas.id)}>
              Supprimer l'intervention
            </button>
          </div>
        }
        <div className="ajout-equipier">
          <button type="button" className="link-icon" onClick={() => props.addEquipier(props.index)}>
            <span>
              Ajouter un co-équipier
              <Icon icon="plus" />
            </span>
          </button>
        </div>
      </div>
    </div>
  )
}
