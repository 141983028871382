import React, { useState, useEffect } from 'react'
import { NavLink, useParams, useHistory } from 'react-router-dom'
import useBlockHistory from '../hooks/useBlockHistory'
import TaskWithoutReservationContent from '../components/TaskWithoutReservationContent'

// Objet contenant les données initiales au chargement de la réservation
export default function TaskWithoutReservation() {
  const { id_renting, id_task } = useParams()
  const history = useHistory()

  const [title, setTitle] = useState("Chargement des données...")
  const [closedTask, setClosedTask] = useState()
  const {hasChanges, setHasChanges, gotoUrl, setGotoUrl, saveBeforeContinue, continueWithoutSaving} = useBlockHistory()

  return (
    <main className={`reservation`}>
      <div className="header">
        <h1>{title}</h1>
      </div>

      <TaskWithoutReservationContent
        id_renting={id_renting}
        id_task={id_task}
        setTitle={setTitle}
        history={history}
        gotoUrl={gotoUrl}
        setGotoUrl={setGotoUrl}
        hasChanges={hasChanges}
        saveBeforeContinue={saveBeforeContinue}
        continueWithoutSaving={continueWithoutSaving}
        setHasChanges={setHasChanges} />
    </main>
  )
}
