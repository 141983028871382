/*
 * Routes nécessaires sur cet écran :
 - Route GET Si ID équipier, Récupération des infos de l'équipier
 - Route POST Enregistrement des infos de l'équipier (si ID équipier défini alors Modification, sinon Création ?)
 */

import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import useUser from '../hooks/useUser'
import useLoadTicketLevels from '../hooks/useLoadDatas'
import useSaveDatas from '../hooks/useSaveDatas'
import Loader from '../components/Loader'
import NotFound from '../components/NotFound'
import Icon from '../components/Icon'
import useForm from '../hooks/useForm'
import useBlockHistory from '../hooks/useBlockHistory'

export default function TicketNew() {
  const { id_renting } = useParams()

  // Détermine le statut de l'utilisateur connecté
  const { user, isManager, isConcierge, isCleaner } = useUser()

  const {hasChanges, setHasChanges, gotoUrl, saveBeforeContinue, continueWithoutSaving} = useBlockHistory()

  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  // Nettoie les classes du planning
  document.getElementsByTagName("body")[0].classList.remove("popin-tasks-active")


  let init_fields = [
    {
      name: "level",
      type: "select",
      label: "Sélectionner la priorité",
      placeholder: "Sélectionner la priorité",
      error: "La priorité du ticket",
      options: [],
      required: true,
      // required_format: "email",
      // error_format: "L'identifiant doit être un email",
      value: '',
    },
    {
      name: "subject",
      type: "text",
      label: "Objet",
      placeholder: "Saisissez l'objet",
      error: "L'objet",
      required: true,
      // required_format: "email",
      // error_format: "L'identifiant doit être un email",
      value: ''
    },
    {
      name: "content",
      type: "textarea",
      label: "Décrire le problème précisément",
      placeholder: "Saisissez le problème",
      error: "La description du problème",
      required: true,
      // required_format: "email",
      // error_format: "L'identifiant doit être un email",
      value: ''
    },
    {
      name: "images",
      type: "files",
      label: "Photos justificatives (10 max)",
      button_label: "Charger mes photos",
      error: "Les photos",
      required: false,
      // required_format: "",
      // error_format: "",
      value: []
    }
  ]

  // Vide les fichiers au chargement
  const [emptyFiles, setEmptyFiles] = useState(false)
  if ( !emptyFiles ) {
    localStorage.setItem('files', JSON.stringify([]))
    setEmptyFiles(true)
  }

  // Chargement des listes de valeurs
  const [ticketLevels, setTicketLevels] = useState([])
  const routeTicketLevels = '/ticket-levels'
  const [fetchTicketLevels] = useLoadTicketLevels()
  useEffect(() => {
    loadTicketLevels()
    async function loadTicketLevels() {
      const json_levels = await fetchTicketLevels(routeTicketLevels)
      if ( json_levels["hydra:member"] ) {
        let ticket_levels = []
        json_levels["hydra:member"].forEach((level) => {
          ticket_levels.push(
            {
              value: level.id,
              label: level.label
            }
          )
        })
        init_fields[0].options = ticket_levels
        setFields([...init_fields])
      }
    }
  }, [routeTicketLevels])

  // Formulaires
  // ---------------------------------------------------------------------------
  const [isFormLoading, setIsFormLoading] = useState(false)
  const { setErrors, showFields, getFields, setFields, setSuccess, showReturnMessage } = useForm(init_fields)

  // Enregistrement des données
  // ---------------------------------------------------------------------------
  const [saveDatas] = useSaveDatas()
  const formSubmit = async (e) => {
    e.preventDefault()

    // Réinitialise avant traitement
    setIsFormLoading(true)
    setErrors({})

    // Traitement
    const parameters = getFields()
    if ( Object.keys(parameters.errors).length > 0 ) {
      setIsFormLoading(false)
      setErrors({
        message: "Veuillez compléter ou corriger les champs suivants :",
        fields: parameters.errors
      })
    }
    else {
      // On ajuste les paramètres à envoyer
      // parameters.values.level = parseInt(parameters.values.level)
      parameters.values.renting = id_renting

      const formdata = new FormData()
      Object.keys(parameters.values).forEach((param_key) => {
        switch (param_key) {
          case "images":
            if ( parameters.values[param_key].length ) {
              parameters.values[param_key].forEach((parameter) => {
                formdata.append(param_key+"[]", parameter.src)
              })
            }
            else {
              formdata.append(param_key+"[]", '')
            }
          break;
          default:
            formdata.append(param_key, parameters.values[param_key])
        }
      })

      const json_datas = await saveDatas('/tickets', {type: 'file'}, formdata)
      setIsFormLoading(false)
      if ( json_datas.error ) { // Si erreur
        setErrors({
          message: json_datas.message,
          fields: json_datas.errors
        })
      }
      else {
        if ( json_datas.id ) {
          setSuccess({
            message: "Votre ticket a été ouvert et transmis au coordinateur avec succès !\nDans quelques secondes, vous serez automatiquement redirigé vers l'écran du ticket..."
          })

          // Et on redirige après 3 secondes
          setTimeout(function(e) {
            window.location = process.env.REACT_APP_URI+'/ticket/'+json_datas.id+'/'
          }, 3000)
        }
        else {
          setSuccess({
            message: "Votre ticket a été ouvert et transmis au coordinateur avec succès !"
          })
        }
      }
    }
  }

  return (
    <main>
      <div className="header">
        <h1>Ouvrir un ticket</h1>
      </div>
      <div className={`content innerSmallWidth hasloader ${isLoading ? 'loadin' : ''}`}>
        {!isLoading &&
          <>
            {isError // Si pas de résultats de la route
            ?
              <NotFound />
            :
              <form id="ticketForm"
                    className={`form hasloader ${isFormLoading ? 'loadin' : ''}`}
                    onSubmit={formSubmit}>

                { showReturnMessage() }

                { showFields() }

                <div className="form-submit">
                  <button type="submit" className="btn btn-icon">
                    <span>
                      Envoyer à mon coordinateur
                      <Icon icon="check" />
                    </span>
                  </button>
                </div>

                <Loader />
              </form>
            }
          </>
        }
        <Loader />
      </div>
    </main>
  )
}
