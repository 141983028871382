/*
 * Routes nécessaires sur cet écran :
 - Route GET Récupération des rentings
 */

import React, { useState, useEffect } from 'react'
import useUser from '../hooks/useUser'
import useLoadRentings from '../hooks/useLoadDatas'
import useScroll from '../hooks/useScroll'
import useScrollBottom from '../hooks/useScrollBottom'
import Loader from '../components/Loader'
import Icon from '../components/Icon'
import NotFound from '../components/NotFound'
import BlocLogement from '../components/BlocLogement'

// Objet contenant les données initiales au chargement des rentings
export default function Logements() {
  // Détermine le statut de l'utilisateur connecté
  const { user, checkUser, isManager, isConcierge, isCleaner } = useUser()

  const isFixed = useScroll('filters', 'header')

  const queryParams = new URLSearchParams(window.location.search)
  let init_search = (queryParams.get('rech') ? queryParams.get('rech') : '')
  const [search, setSearch] = useState(init_search)

  // Structure la route de requêtes des rentings
  const updateRouteRentings = (page, perPage, search) => {
    let renting_by_user_id = ''
    switch (user.role) {
      case 'ROLE_MANAGER':
        renting_by_user_id = '&user='+user.hoomyId
      break;
      case 'ROLE_EQUIPIER':
      case 'ROLE_CLEANER':
        renting_by_user_id = (user.parent ? '&user='+user.parent.hoomyId : '')
      break;
      // case 'ROLE_ADMIN':
      //   const adminUser = JSON.parse(localStorage.getItem('admin_user'))
      //   if ( adminUser ) {
      //     renting_by_user_id = (adminUser.role == 'ROLE_MANAGER' ? '&user='+adminUser.user : '')
      //   }
      // break;
    }
    let params = '?order[owner.lastName]=asc'+renting_by_user_id+'&order[hoomyId]=asc'
    if ( perPage ) {
      params += (params ? '&' : '')+'itemsPerPage='+perPage
    }
    if ( page ) {
      params += (params ? '&' : '')+'page='+page
    }
    if ( search ) {
      params += (params ? '&' : '')+'search='+search
    }
    return '/rentings'+params
  }

  // Chargement des rentings
  // ---------------------------------------------------------------------------
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)

  const [totalItems, setTotalItems] = useState()
  const [page, setPage] = useState(1)
  const perPage = 20

  // Route rentings
  const [routeRentings, setRouteRentings] = useState( updateRouteRentings(page, perPage, search) )
  const [fetchRentings] = useLoadRentings()

  const [rentings, setRentings] = useState([])
  const [isBottom, setIsBottom, handleScroll, isStopped, setIsStopped] = useScrollBottom('.content.hasElements') // Scroll infini

  useEffect(() => {
    loadRentings()
    async function loadRentings() {
      const rentings_datas = await fetchRentings(routeRentings)
      if ( rentings_datas.error ) {
        setIsError(true)
      }
      else {
        // S'il y a des éléments
        if ( rentings_datas['hydra:member'] ) {
          rentings_datas['hydra:member'].forEach((renting, i) => {
            let title = renting.owner.lastName.toUpperCase().trim()
            title += (renting.rentingNameAlt ? (title ? ' | ' : '')+renting.rentingNameAlt : '')
            title += (title ? ' | ' : '')+renting.rentingName.toUpperCase()

            let adresse = renting.address.trim()
            adresse += ((adresse && renting.addressBis) ? " " : "")+renting.addressBis.trim()
            adresse += ((adresse && (renting.postalCode || renting.city)) ? "," : "")
            adresse += (adresse ? " " : "")+renting.postalCode.trim()
            adresse += (adresse ? " " : "")+renting.city.trim()

            // On ajoute le renting
            rentings.push({
              id: renting.hoomyId,
              title: title,
              address: adresse,
              lat: renting.latitude,
              lng: renting.longitude,
              book: (renting.book ? renting.book.url : ''),
              accessible: (isManager || isConcierge)
            })
          })
          setRentings([...rentings])
        }

        // MAJ le nombre total d'items
        if ( !totalItems ) {
          setTotalItems(rentings_datas['hydra:totalItems'])
        }
      }

      setIsBottom(false)
      setIsLoading(false)
    }
  }, [routeRentings])


  // Gestion du scroll et du chargement de la pagination
  useEffect(() => {
    // console.log(totalItems, '>', perPage * page, page)
    // console.log(isBottom, isStopped, isLoading)
    if ( totalItems ) {
      if ( totalItems > perPage * page ) {
        handleScroll()
        if ( isBottom ) {
          const next_page = page+1
          setPage(next_page)
          setRouteRentings( updateRouteRentings(next_page, perPage, search) )
        }
      }
      else {
        setIsStopped(true)
      }
    }
  }, [isBottom, rentings])


  // Soumission de la recherche par la touche Entrée
  const searchKeyPress = (e) => {
    if ( e.key === "Enter" ) {
      submitSearch(e)
    }
  }

  // Execute la requete avec recherche
  const submitSearch = async (e) => {
    e.preventDefault()

    // Seulement si la recherche est différente
    const prev_url = new URL(window.location)
    const prev_search = (prev_url.searchParams.get('rech') ? prev_url.searchParams.get('rech') : '')
    if ( prev_search !== search ) {
      // Ajuste les routes
      setPage(1)
      setRentings([])
      setIsBottom(false)
      setIsStopped(false)
      setIsLoading(true)

      const url = new URL(window.location)
      url.searchParams.set('rech', search)
      window.history.pushState({}, '', url)

      setRouteRentings( updateRouteRentings(1, perPage, search) )
    }
  }

  return (
    <main className="logements">
      <div className="header">
        <h1>Logements</h1>
        <div id="filters" className={isFixed ? 'fixed' : ''}>
          <div className="innerMediumWidth">
            <div className="search">
              <div className="input-search">
                <input id="search" name="search" type="search"
                        onChange={(e) => setSearch(e.target.value)}
                        onKeyPress={(e) => searchKeyPress(e)}
                        placeholder="Recherche..."
                        value={(search ? search : '')} />
                <button type="button" className="btn-search" onClick={submitSearch}>
                  <Icon icon='loupe' />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`content innerMediumWidth ${Object.keys(rentings).length > 0 ? 'hasElements' : ''}`}>
        {rentings.length > 0
          ?
          rentings.map((value, index) => (
            <BlocLogement key={index} {...value} />
          ))
          :
          <>
            {!isLoading &&
              <em className="no-result">Aucun logement à traiter.</em>
            }
          </>
        }
        <div className={`more-tasks hasloader ${(isStopped ? 'hide' : ((isBottom || isLoading) ? 'loadin' : ''))}`}>
          <Loader />
        </div>
      </div>
    </main>
  )
}
