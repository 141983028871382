/*
 * Routes nécessaires sur cet écran :
 - Route GET Récupération des infos de la réservation
 - Route GET Récupération des tâches liées à la réservation ? si dissociée de la route précédente
 - Route DELETE Suppression d'une tâche ?
 - Route GET Si manager, Récupération de la liste de ses équipiers
 - Route POST Enregistrement des infos modifiées
 - Route GET Récupération des fichiers
 - Route POST Enregistrement des fichiers
 - Route DELETE Suppression d'un fichier ?
 */

import React, { useState, useEffect } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import useUser from '../hooks/useUser'
import useLoadDatas from '../hooks/useLoadDatas'
import useLoadEquipiers from '../hooks/useLoadDatas'
import useLoadFiles from '../hooks/useLoadDatas'
import useTaskEvents from '../hooks/useLoadDatas'
import useTaskStates from '../hooks/useLoadDatas'
import useTaskTypes from '../hooks/useLoadDatas'
import useSaveDatas from '../hooks/useSaveDatas'
import useSaveTask from '../hooks/useSaveDatas'
import useDeleteTask from '../hooks/useDeleteDatas'
import useSaveUserTask from '../hooks/useSaveDatas'
import useDeleteUserTask from '../hooks/useDeleteDatas'
import useSaveFiles from '../hooks/useSaveDatas'
import useSaveCommentTask from '../hooks/useSaveDatas'
import TaskReservation from '../components/TaskReservation'
import InputFiles from '../components/InputFiles'
import Icon from '../components/Icon'
import GPSUrl from '../components/GPSUrl'
import Loader from '../components/Loader'
import NotFound from '../components/NotFound'
import DatePickerCustom from '../components/DatePickerCustom'
import TimePickerCustom from '../components/TimePickerCustom'
import {formatQty, getFrenchDate, formatHour, formatDatetime, formatDateString, formatTimeString, formatDuration, getCurrentDate} from '../functions/functions.js'

// Liste pour le sélecteur de durées
// Propose toutes les 15min de 0 à 3h et toutes 30min de 3h à 8h
let durees = []
for (let i=1; i<=32; i++) {
  const value = (i/4)
  const check_quart = (value/0.5)
  if ( check_quart <= 6 || (check_quart > 6 && Number.isInteger(check_quart)) ) {
    durees.push({
      value: value,
      label: formatTimeString(value)
    })
  }
}

let initialDatas = {}

// Objet contenant les données initiales au chargement de la réservation
export default function TaskWithoutReservationContent(props) {
  const id_renting = props.id_renting
  const id_task = props.id_task

  // Détermine le statut de l'utilisateur connecté
  const { user, checkUser, isManager, isConcierge, isCleaner } = useUser()
  const [equipierIndex, setEquipierIndex] = useState(null) // Equipier courant
  const [isCoequipier, setIsCoequipier] = useState(false)
  const taskEvent = 3 // Hors réservation, toujours Intervention
  const [closedTask, setClosedTask] = useState()
  const [deleteTaskPopin, setDeleteTaskPopin] = useState(false)

  const timeMinDateMessage = "L'heure ne doit pas être antérieure à la date minimale"
  const timeInvalidDateMessage = "Le format de l'heure n'est pas valide"

  // Met à jour l'objet à enregistrer si la tâche n'est pas clôturée
  const updateChanges = (datas, forceChange) => {
    if ( !closedTask || forceChange ) {
      props.setHasChanges( initialDatas !== JSON.stringify(datas) )
    }
  }

  // Chargement des données
  // ---------------------------------------------------------------------------
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [routeRenting, setRouteRenting] = useState('/rentings/'+id_renting)
  const [routeTask, setRouteTask] = useState('/task-todos/'+id_task)
  const [fetchData] = useLoadDatas()
  const [datas, setDatas] = useState({}) // Objet des données
  const [commentLength, setCommentLength] = useState(0)
  const [inventoryId, setInventoryId] = useState(null) // Inventaire des fichiers de la tâche
  const [taskIsBillable, setTaskIsBillable] = useState(false) // Détermine si la tâche courante est facturable ou non
  const [savedTaskBillable, setSavedTaskBillable] = useState(false) // Détermine si la tâche courante est facturée ou non

  // Rechargement depuis la popin
  useEffect(() => {
    setIsLoading(true)
    setRouteRenting('/rentings/'+id_renting)
    setRouteTask('/task-todos/'+id_task)
  }, [id_renting, id_task])

  // Chargement des équipiers
  // ---------------------------------------------------------------------------
  const [listEquipiers, setEquipiers] = useState([])
  const [routeFavEquipiers, setRouteFavEquipiers] = useState()
  const [routeEquipiers, setRouteEquipiers] = useState()
  const [fetchEquipiers] = useLoadEquipiers()
  useEffect(() => {
    loadFavEquipiers()
    async function loadFavEquipiers() {
      if ( routeFavEquipiers && (isManager || isConcierge) ) {
        const json_datas = await fetchEquipiers(routeFavEquipiers)
        if ( json_datas && json_datas["hydra:member"] ) {
          json_datas["hydra:member"].forEach((equipier) => {
            listEquipiers.push(
              {
                id: equipier.hoomyId,
                name: (equipier.hoomyId === user.hoomyId ? 'Moi' : equipier.firstName+" "+equipier.lastName),
                tel: equipier.phone,
                email: equipier.email,
                status: equipier.isActive,
                color: equipier.color,
                fav: true,
                indispos: []
              }
            )
          })
          setEquipiers([...listEquipiers])
        }
      }
    }
  }, [routeFavEquipiers])

  useEffect(() => {
    loadEquipiers()
    async function loadEquipiers() {
      if ( routeEquipiers && (isManager || isConcierge) ) {
        const json_datas = await fetchEquipiers(routeEquipiers)
        if ( json_datas && json_datas["hydra:member"] ) {
          json_datas["hydra:member"].forEach((equipier) => {
            const equipIndex = listEquipiers.findIndex((equip) => equip.id === equipier.hoomyId)
            if ( equipIndex >= 0 ) { // Si équipier existe déjà dans la liste
              listEquipiers[equipIndex].indispos = (equipier.events ? equipier.events : [])
            }
            else {
              listEquipiers.push(
                {
                  id: equipier.hoomyId,
                  name: (equipier.hoomyId === user.hoomyId ? 'Moi' : equipier.firstName+" "+equipier.lastName),
                  tel: equipier.phone,
                  email: equipier.email,
                  status: equipier.isActive,
                  color: equipier.color,
                  fav: false,
                  indispos: (equipier.events ? equipier.events : [])
                }
              )
            }
          })

          // S'il n'est pas dans la liste, on ajoute l'utilisateur courant
          const equipIndex = listEquipiers.findIndex((equip) => equip.id === user.hoomyId)
          if ( equipIndex < 0 ) { // Si équipier existe déjà dans la liste
            listEquipiers.push(
              {
                id: user.hoomyId,
                name: 'Moi',
                fav: false,
                indispos: []
              }
            )
          }

          setEquipiers([...listEquipiers])
        }
      }
    }
  }, [routeEquipiers])

  useEffect(() => {
    loadData()
    async function loadData() {
      const renting_datas = await fetchData(routeRenting)

      // Si erreur
      if ( renting_datas.error ) {
        props.setTitle('Chargement en erreur')
        setIsError(true)
      }
      // Si succès
      else {
        setRouteFavEquipiers('/users?pagination=false&isActive=1&order[role]=DESC&favoriteRentings.renting.hoomyIds[]='+renting_datas.hoomyId)

        // Charge les données de la tâche
        const task_datas = await fetchData(routeTask)

        let inventory_id = null

        // Si Intervention
        initialDatas = {
          files: [],
          inputs: {
            comment: renting_datas.commentUser,
            satisfait: renting_datas.isRelaunchedClient
          },
          contents: {
            num: renting_datas.hoomyId,
            adresse_nom: renting_datas.rentingNameAlt,
            adresse: renting_datas.address,
            adresse2: renting_datas.addressBis,
            cp: renting_datas.postalCode,
            ville: renting_datas.city,
            lat: renting_datas.latitude,
            lng: renting_datas.longitude,
            coequipiers: [],
            comment_hoomy: renting_datas.commentHoomy,
            book: (renting_datas.book ? renting_datas.book.url : null)
          }
        }

        if ( task_datas.error ) {
          props.setTitle('Chargement en erreur')
          setIsError(true)
        }
        else {
          // Titre de l'écran : "Accueil | Dupont Françoise | n°1 | Ménage"
          props.setTitle(
            'Intervention'
            + (task_datas.taskType ? ' '+task_datas.taskType.label : '')
            + ' | '+renting_datas.owner.lastName.trim()
            + (renting_datas.rentingNameAlt ? ' | '+renting_datas.rentingNameAlt : '')
            + ' | '+renting_datas.rentingName.toUpperCase()
            + (task_datas.precision ? ' | '+task_datas.precision : '')
          )

          let equipiers = []
          if ( task_datas.userTaskTodos.length ) {
            task_datas.userTaskTodos.forEach((userTaskTodo, i) => {
              let equipier = {}

              if ( userTaskTodo.isTeammate ) {
                equipiers.push({
                  user_id: userTaskTodo.id,
                  equipier: userTaskTodo.user.hoomyId,
                  heure: userTaskTodo.teammateStartAt,
                  duree: userTaskTodo.duration,
                  duree_string: formatDuration(userTaskTodo.duration),
                  coequipier: userTaskTodo.isTeammate,
                  user: userTaskTodo.user
                })
              }
              else {
                // Si équipier principal, on le force au début du tableau
                equipiers.unshift({
                  user_id: userTaskTodo.id,
                  equipier: userTaskTodo.user.hoomyId,
                  heure: userTaskTodo.teammateStartAt,
                  duree: userTaskTodo.duration,
                  duree_string: formatDuration(userTaskTodo.duration),
                  coequipier: userTaskTodo.isTeammate,
                  user: userTaskTodo.user
                })
              }
            })
          }
          else {
            // Sinon, par défaut, on ajoute un champ équipier
            equipiers.push({
              user_id: null,
              equipier: null,
              heure: null,
              duree: null,
              duree_string: '',
              coequipier: false
            })
          }


          // Si Intervention
          initialDatas.task = {
            id: task_datas.id,
            name: task_datas.taskEvent.label,
            id_event: task_datas.taskEvent.id,
            id_state: task_datas.taskState.id,
            taskType: (task_datas.taskType ? task_datas.taskType.id : null),
            in_location: (task_datas.taskType ? task_datas.taskType.isOnLocation : false),
            date: task_datas.startAt,
            // precision: task_datas.name,
            id_comment: (task_datas.taskComments[0] ? task_datas.taskComments[0].id : null),
            comment: (task_datas.taskComments[0] ? task_datas.taskComments[0].content : ''),
            equipiers: equipiers,
            closed: task_datas.checkedEvent,
            billable: (task_datas.taskType ? task_datas.taskType.isBillable : false),
            billing: (task_datas.taskBilling ? task_datas.taskBilling.id : null)
          }

          // traitement de la facturation fait si Facturé (2) ou Offert (3)
          setSavedTaskBillable(([2,3].includes(initialDatas.task.billing) ? true : false))
          setClosedTask(initialDatas.task.closed)

          if ( task_datas.inventory
               &&  (!id_task || parseInt(id_task) === task_datas.id) ) {
            inventory_id = task_datas.inventory.id
            setInventoryId(inventory_id)
          }

          let startDate = task_datas.startAt
          let endDate = task_datas.endAt

          // Enchaine sur la route de tous les utilisateurs
          // setRouteEquipiers('/users?pagination=false&isActive=1&withEvents=true&events.timeMin='+startDate+'&events.timeMax='+endDate)
          setRouteEquipiers('/users?pagination=false&isActive=1')

          // S'il y a des équipiers
          if ( initialDatas.task.equipiers.length ) {
            // On récupère le user qui n'est pas le user connecté
            initialDatas.task.equipiers.forEach((equip, i) => {
              if ( equip.user_id ) {
                // Dans le cas de l'utilisateur connecté
                if ( equip.equipier === user.hoomyId ) {
                  setEquipierIndex(i)
                  if ( equip.coequipier ) {
                    setIsCoequipier(equip.coequipier)
                  }
                }
                // Si coéquipier
                else if ( equip.equipier !== user.hoomyId ) {
                  initialDatas.contents.coequipiers.push({
                    nom: equip.user.firstName.trim()+' '+equip.user.lastName.trim(),
                    isCoequipier: equip.coequipier,
                    tel: equip.user.phone,
                    mail: equip.user.email,
                    heure_debut: (equip.heure ? formatHour(equip.heure) : null),
                    duree: equip.duree_string
                  })
                }
              }
            })
          }
        }

        // Chargement initial des fichiers
        let has_files = false
        if ( inventory_id ) {
          const json_files = await fetchFiles('/inventories/'+inventory_id)
          if ( json_files && json_files.inventoryImages ) {
            let loaded_files = []
            for (const file of json_files.inventoryImages) {
              const token = await checkUser(user)

              const fetchHeaders = new Headers()
              fetchHeaders.append("Accept", "image/jpeg")
              fetchHeaders.append("Authorization", "Bearer "+token)

              await fetch(process.env.REACT_APP_API+file.contentUrl, {
                method: 'GET',
                headers: fetchHeaders
              })
              .then(response => {
                return response.blob()
              })
              .then(async (blob) => {
                await loaded_files.push({
                  id: file.id,
                  src: new File([blob], file.displayName, {type: "application/octet-stream"})
                })
              })
              .catch(error => {
                return {
                  error: true,
                  message: "Erreur de chargement d'image"
                }
              })
            } // for

            // S'il y a des fichiers, on les stocke et les affiche
            if ( loaded_files.length ) {
              initialDatas.files = await loadInitBlobs(loaded_files)
              has_files = true
            }
          }
        }

        // Si pas de fichiers on vide le localStorage
        if ( !has_files ) {
          localStorage.setItem('files', JSON.stringify([]))
        }

        if ( initialDatas.task ) {
          setTaskIsBillable(initialDatas.task.billable)
        }

        // On met à jour les données
        setDatas({...initialDatas})
        initialDatas = JSON.stringify(initialDatas) // Stocke le JSON de ces données initiales
      }

      // Fin de chargement
      setIsLoading(false)
    }
  }, [routeRenting, routeTask])

  const loadInitBlobs = async (files) => {
    let index = 0
    let blobs = []
    for (const file of files) {
      const blob = new Blob([file.src], {type: file.src.type})
      file.blob = await new Promise((resolve, reject) => {
        let fileReader = new FileReader()
        fileReader.onload = function(e) {
          resolve(e.target.result)
          blobs[index] = e.target.result
        }
        fileReader.onerror = reject
        fileReader.readAsDataURL(blob)
      })
      index++
    }
    localStorage.setItem('files', JSON.stringify(blobs))
    return files
  }

  /*
   * Task Editor
   * ------------------------------------------------------------------------ */
  // taskEditor :
  // => true : Affiché/Déplié
  // => false : Caché/Replié
  const [taskEditor, setTaskEditor] = useState((!id_task ? true : false ))
  const toggleTaskEdit = () => {
    setTaskEditor((taskEditor ? false : true))
  }

  // Ajoute un équipier
  const addEquipier = (index) => {
    datas.task.equipiers.push({
      user_id: null,
      equipier: null,
      heure: null,
      duree: null,
      duree_string: '',
      coequipier: true
    })
    setDatas({...datas})

    // Compare l'objet initial à l'objet à enregistrer
    updateChanges(datas)
  }

  /*
   * Fonctions liées à la réservation
   * ------------------------------------------------------------------------ */
  const [showOtherOrders, setShowOtherOrders] = useState(false)
  const toggleOtherOrders = () => {
    setShowOtherOrders((showOtherOrders ? false : true))
  }
  const changeTaskDate = (input_date, index) => {
    if ( input_date.$d != 'Invalid Date' ) {
      const datetime = new Date(input_date)
      const format_date = datetime.getFullYear()+'-'+String(datetime.getMonth()+1).padStart(2, '0')+'-'+String(datetime.getDate()).padStart(2, '0')

      const initialDate = new Date(datas.task.date)
      const format_time = initialDate.toLocaleTimeString('fr-FR', {hour:'2-digit', minute:'2-digit'})

      const date = format_date+'T'+format_time+':00' // '2021-04-21T18:00'
      datas.task.date = date
      setDatas({...datas})

      // Compare l'objet initial à l'objet à enregistrer
      updateChanges(datas)
    }
  }
  const changeTaskTime = (input_date, index) => {
    if ( input_date.$d != 'Invalid Date' ) {
      const initialDate = new Date(datas.task.date)
      const format_date = initialDate.getFullYear()+'-'+String(initialDate.getMonth()+1).padStart(2, '0')+'-'+String(initialDate.getDate()).padStart(2, '0')

      const datetime = new Date(input_date)
      const format_time = datetime.toLocaleTimeString('fr-FR', {hour:'2-digit', minute:'2-digit'})

      const date = format_date+'T'+format_time+':00' // '2021-04-21T18:00'
      datas.task.date = date
      setDatas({...datas})

      // Compare l'objet initial à l'objet à enregistrer
      updateChanges(datas)
    }
  }
  const changeTaskInput = (e) => {
    const names = e.target.name.split("_")
    const name = names[0]
    const index = names[1]

    datas.task[name] = e.target.value
    setDatas({...datas})

    // Compare l'objet initial à l'objet à enregistrer
    updateChanges(datas)
  }
  const changeTaskEquipierInput = (e) => {
    const names = e.target.name.split("_")
    const name = names[0]
    const index = names[1]
    const sub_index = names[2]

    datas.task.equipiers[sub_index][name] = e.target.value
    setDatas({...datas})

    // Compare l'objet initial à l'objet à enregistrer
    updateChanges(datas)
  }

  // Change l'heure d'un équipier
  const changeTaskEquipierTime = (task_index, equipier_index, input_date) => {
    if ( input_date.$d != 'Invalid Date' ) {
      // Récupère la date de la tâche
      const task_date = new Date(datas.task.date)
      const format_date = task_date.getFullYear()+'-'+String(task_date.getMonth()+1).padStart(2, '0')+'-'+String(task_date.getDate()).padStart(2, '0')

      // Récupère l'heure de l'équipier
      const equipier_time = new Date(input_date)
      const format_time = equipier_time.toLocaleTimeString('fr-FR', {hour:'2-digit', minute:'2-digit'})

      // Combine la date de la tâche avec l'heure de l'équipier
      const date = format_date+'T'+format_time+':00' // '2021-04-21T18:00:00'

      // On enregistre la modification d'heure équipier
      datas.task.equipiers[equipier_index].heure = date

      setDatas({...datas})

      // Compare l'objet initial à l'objet à enregistrer
      updateChanges(datas)
    }
  }

  const changeTime = (name, input_date) => {
    if ( input_date.$d != 'Invalid Date' ) {

      const datetime = new Date(input_date)
      const format_date = datetime.getFullYear()+'-'+String(datetime.getMonth()+1).padStart(2, '0')+'-'+String(datetime.getDate()).padStart(2, '0')
      const format_time = datetime.toLocaleTimeString('fr-FR', {hour:'2-digit', minute:'2-digit'})
      const date = format_date+'T'+format_time+':00' // '2021-04-21T18:00:00'
      datas.inputs[name] = date

      setDatas({...datas})

      // Compare l'objet initial à l'objet à enregistrer
      updateChanges(datas)
    }
  }

  const changeTimeIntervention = (input_date) => {
    const datetime = new Date(input_date)
    const format_date = datetime.getFullYear()+'-'+String(datetime.getMonth()+1).padStart(2, '0')+'-'+String(datetime.getDate()).padStart(2, '0')
    const format_time = datetime.toLocaleTimeString('fr-FR', {hour:'2-digit', minute:'2-digit'})
    const date = format_date+'T'+format_time+':00'
    datas.task.date = date
    setDatas({...datas})

    // Compare l'objet initial à l'objet à enregistrer
    updateChanges(datas)
  }
  const changeInput = (e) => {
    datas.inputs[e.target.name] = (e.target.type === "number" ? parseFloat(e.target.value ? e.target.value : 0) : e.target.value)
    setDatas({...datas})

    // Compare l'objet initial à l'objet à enregistrer
    updateChanges(datas)

    if ( e.target.name === "commentUser" ) {
      setCommentLength(e.target.value.length)
    }
  }

  // Met à un jour un service déjà commandé
  const changeOrder = (e, order_id) => {
    // On incrémente la quantité
    datas.orders[order_id].additionalQuantity = parseFloat(e.target.value ? e.target.value : 0)
    setDatas({...datas})

    // Compare l'objet initial à l'objet à enregistrer
    updateChanges(datas)
  }

  // Met à un jour un service supplémentaire
  const changeOtherOrder = (e, order_id) => {
    // On incrémente la quantité
    datas.other_orders[order_id].additionalQuantity = parseFloat(e.target.value ? e.target.value : 0)
    setDatas({...datas})

    // Compare l'objet initial à l'objet à enregistrer
    updateChanges(datas)
  }
  const toggleSatisfaction = (e) => {
    datas.inputs[e.target.name] = (datas.inputs.satisfait ? false : true)
    setDatas({...datas})

    // Compare l'objet initial à l'objet à enregistrer
    updateChanges(datas)
  }
  const toggleBilling = (e, id_billing) => {
    datas.task.billing = (e.target.checked ? id_billing : null)
    setDatas({...datas})

    // Compare l'objet initial à l'objet à enregistrer
    updateChanges(datas, true) // On force la comparaison
  }


  // Modification des fichiers
  // ---------------------------------------------------------------------------
  const [fetchFiles] = useLoadFiles()
  const changeFiles = (files) => {
    let newdatas = {...datas}
    let no_file_added = true
    let skip = false
    files.forEach( function(file, index) {
      if ( !skip ) {
        if ( newdatas.files.length < 10 ) {
          newdatas.files.push(file)
          no_file_added = false
        }
        else {
          // alert("Vous ne pouvez ajouter plus de 10 fichiers !")
          skip = true
        }
      }
    })

    if ( !no_file_added ) {
      setDatas({...newdatas})

      // Compare l'objet initial à l'objet à enregistrer
      updateChanges(datas)
    }
  }
  const removeFile = (index) => {
    datas.files.splice(index, 1)
    setDatas({...datas})

    // Suppression du blob lié
    const blobs = JSON.parse(localStorage.getItem("files"))
    blobs.splice(index, 1)
    localStorage.setItem("files", JSON.stringify(blobs))

    // Compare l'objet initial à l'objet à enregistrer
    updateChanges(datas)
  }


  /*
   * Fonction d'enregistrement des données
   * ------------------------------------------------------------------------ */
  const [isSaving, setIsSaving] = useState(false)
  const [messageSaving, setMessageSaving] = useState('')
  const [errorSaving, setErrorSaving] = useState(null)
  const [saveDatas] = useSaveDatas() // '/locations/'+id_location, {method: 'PUT'}
  const [saveTask] = useSaveTask() // '/task-todos', {method: 'POST'}
  const [deleteTask] = useDeleteTask() // '/task-todos', {method: 'POST'}
  const [saveUserTask] = useSaveUserTask() // '/user-task-todos', {method: 'POST'}
  const [deleteUserTask] = useDeleteUserTask()
  const [saveFiles] = useSaveFiles()
  const [saveCommentTask] = useSaveCommentTask() // '/task-comments', {method: 'POST'}

  const submitDatas = async (e) => {
    e.preventDefault()

    // Réinitialise avant traitement
    setMessageSaving('')
    setErrorSaving(null)
    setIsSaving(true)

    // Cas spécifique si la tâche est déjà close
    // On ne peut alors n'enregistrer que l'état de la facture sur la tâche
    let getSaveInfos = {}
    if ( closedTask && taskIsBillable ) {
      // On n'enregistre que la modification du billing
      if ( datas.task ) {
        const task_id = datas.task.id
        const updateParams = {
          taskBilling: datas.task.billing
        }
        let getErrors = {
          error: false,
          message: '',
          errors: []
        }
        // fetchData('/task-billings')
        // fetchData('/task-todos/'+task_id)
        const update_task = await saveTask('/task-todos/'+task_id, {method: 'PUT'}, updateParams)
        if ( update_task && update_task.error ) { // Si erreur
          getSaveInfos.error = true
          getSaveInfos.message = update_task.message
          if ( update_task.errors ) {
            update_task.errors.forEach((error, i) => {
              getSaveInfos.errors.push('Intervention : '+error)
            })
          }
        }
        else {
          getSaveInfos = 'Vos données ont bien été enregistrées.'
          setSavedTaskBillable(true)
        }
      } // saveTaskTodos
    }
    else {

      // Traitement données formulaire
      // Comparer l'objet initial à l'objet à enregistrer
      // let formdata = new FormData()
      let datasToSave = {}
      let taskToCreate = null
      let taskToUpdate = null
      let ordersToSave = []
      let otherOrdersToSave = []
      let userTaskTodos = []
      let filesToSave = []
      let commentToSave = {}
      const compareDatas = JSON.parse(initialDatas)

      for (const key in compareDatas) {
        switch (key) {
          case 'contents':
            // Pas de modification possible de ces champs
          break;

          case 'task':
            const ctask = datas[key]
            const initask = compareDatas.task
            // Si la tâche n'existe pas ou si la tâche a été modifiée
            if ( ctask.id === null || (JSON.stringify(initask) !== JSON.stringify(ctask)) ) {
              // Compose l'objet de modification des équipiers
              if ( ctask.equipiers ) {
                ctask.equipiers.forEach((equip, i) => {
                  if ( !initask || JSON.stringify(initask.equipiers[i]) !== JSON.stringify(equip) ) {
                    let values = {
                      user: (equip.equipier ? parseInt(equip.equipier) : null),
                      duration: equip.duree,
                      isTeammate: equip.coequipier
                    }
                    if ( equip.heure && equip.coequipier ) {
                      values.teammateStartAt = equip.heure+(!equip.heure.includes("+") ? '+00:00' : '')
                    }
                    userTaskTodos.push({
                      id: equip.user_id,
                      values: values
                    })
                  }
                })
              }

              // Par défaut, on prend la date de la tâche, mais si la date autre est définie, on prend celle-ci
              let task_date = ctask.date+(!ctask.date.includes("+") ? '+00:00' : '')

              const taskToSave = {
                name: null,
                startAt: task_date,
                endAt: task_date,
                checkedEvent: false,
                location: null,
                renting: id_renting,
                taskEvent: ctask.id_event,
                taskState: ctask.id_state,
                taskType: ctask.taskType
                // inventory: null
              }

              // On ajoute l'objet commentaire
              if ( (!initask || initask.comment !== ctask.comment) ) {
                commentToSave = {
                  id: ctask.id_comment,
                  content: ctask.comment
                }
              }

              if ( ctask.id ) { // Si task.id défini, on update la tâche
                taskToSave.id = ctask.id
                taskToUpdate = taskToSave
              }
              else { // Sinon, on crée la tâche
                taskToCreate = taskToSave
              }
            }
          break;

          case 'files':
            const files = datas[key]
            for (const file in files) {
              if ( !files[file].id ) { // Si id du fichier null, on doit le sauvegarder
                filesToSave.push({
                  index: file,
                  src: files[file].src
                })
              }
            }
          break;

          case 'inputs':
            const inputs = datas[key]
            for (const input in inputs) {
              if ( compareDatas.inputs[input] !== inputs[input] ) {
                // formdata.append(key, datas[key])

                // Cas spécifiques des dates
                switch ( input ) {
                  case "satisfait":
                    datasToSave['isRelaunchedClient'] = inputs[input] // Renvoie un booléen
                  break;
                  default:
                    datasToSave[input] = String(inputs[input])
                }
              }
            }
          break;
        }
      }

      // Sauvegarde les différents éléments
      getSaveInfos = await saveAll(datasToSave, filesToSave, ordersToSave, otherOrdersToSave, taskToCreate, taskToUpdate, userTaskTodos, commentToSave)
    }

    if ( getSaveInfos.error ) {
      setErrorSaving(getSaveInfos)
      props.setGotoUrl(null)
      setIsSaving(false)

      // Scroll to top
      if ( props.fromPlanningBoard ) {
        document.getElementById('popin-content').scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
      else {
        window.scrollTo({
          top: document.getElementsByClassName('header')[0].offsetHeight+(document.getElementsByClassName('admin_reservation').length ? document.getElementsByClassName('admin_reservation')[0].offsetHeight : 0),
          behavior: 'smooth'
        })
      }
    }
    else {
      setMessageSaving(getSaveInfos)
      setIsSaving(false)

      if ( props.fromPlanningBoard ) {
        props.setIsSaved(true)
      }

      if ( props.gotoUrl ) {
        props.saveBeforeContinue()
      }
      else {
        setTimeout(function(){
          props.setHasChanges(false)
          setTimeout(function(){
            setMessageSaving('')
          }, 600)
        }, 1000)
      }
    }
  }

  // Fonction regroupant toutes les routes de sauvegardes
  const saveAll = async (datasToSave, filesToSave, ordersToSave, otherOrdersToSave, taskToCreate, taskToUpdate, userTaskTodos, commentToSave) => {
    let newdatas = datas // {...datas}
    let create_task = null
    let update_task = null
    let save_user_task = null
    let delete_user_task = null
    let save_datas = null
    let save_order = null
    let save_files = null
    let save_comment = null
    let getErrors = {
      error: false,
      message: '',
      errors: []
    }

    // Sauve les fichiers, si on est sur une tâche
    if ( id_task && filesToSave.length ) {
      var formdata = new FormData()
      formdata.append('inventoryAt', getCurrentDate())
      formdata.append('inventoryType', 'Etat des lieux')
      for (const file of filesToSave) {
        formdata.append('files[]', file.src)
      }
      save_files = await saveFiles('/inventories'+(inventoryId ? '/'+inventoryId : ''), {method: 'POST', type: 'file'}, formdata)

      if ( save_files && save_files.error ) { // Si erreur
        getErrors.error = true
        getErrors.message = save_files.message
        if ( save_files.errors ) {
          save_files.errors.forEach((error, i) => {
            getErrors.errors.push(error)
          })
        }
      }
      else {
        // Si tout est ok
        // Mise à jour des ids des nouvelles images
        filesToSave.forEach((item, i) => {
          newdatas.files[item.index].id = save_files.inventoryImages[item.index].id
        })
        setDatas({...newdatas})

        // Met à jour l'inventaire sur la tâche
        if ( taskToUpdate ) {
          taskToUpdate.inventory = save_files.id
        }
        else {
          const ctask = datas.task
          taskToUpdate = {
            id: ctask.id,
            name: (ctask.precision ? ctask.precision : ''),
            startAt: ctask.date,
            endAt: ctask.date,
            checkedEvent: false,
            renting: id_renting,
            taskEvent: ctask.id_event,
            taskState: ctask.id_state,
            taskType: ctask.taskType,
            inventory: save_files.id
          }
        }

        // Nettoie le localStorage des fichiers
        // localStorage.setItem('files', JSON.stringify([]))
      }
    } // filesToSave.length

    // Sauve les tâches
    if ( taskToUpdate ) { // Mise à jour de tâches
      const task_id = taskToUpdate.id
      update_task = await saveTask('/task-todos/'+task_id, {method: 'PUT'}, taskToUpdate)
      if ( update_task && update_task.error ) { // Si erreur
        getErrors.error = true
        getErrors.message = update_task.message
        if ( update_task.errors ) {
          update_task.errors.forEach((error, i) => {
            getErrors.errors.push('Intervention : '+error)
          })
        }
      }
      else {
        if ( userTaskTodos.length ) { // Mise à jour des équipiers
          await Promise.all(
            userTaskTodos.map(async (userTask, j) => {
              userTask.values.taskTodo = taskToUpdate.id
              const user_task_id = userTask.id // Vérifie si l'équipier existe déjà
              if ( user_task_id && !userTask.values.user ) { // Suppression
                delete_user_task = await deleteUserTask('/user-task-todos/'+user_task_id, {method: 'DELETE'})
              }
              else if ( userTask.values.user ) { // Edition ou Création
                save_user_task = await saveUserTask('/user-task-todos'+(user_task_id ? '/'+user_task_id : ''), {method: (user_task_id ? 'PUT' : 'POST')}, userTask.values)
                if ( save_user_task && save_user_task.error ) { // Si erreur
                  getErrors.error = true
                  getErrors.message = save_user_task.message
                  if ( save_user_task.errors ) {
                    save_user_task.errors.forEach((error, i) => {
                      getErrors.errors.push('Intervention : '+error)
                    })
                  }
                }
                else { // Sinon succès
                  newdatas.task.equipiers[j].user_id = parseInt(save_user_task.id)
                }
              }
            })
          )
        }

        // Commentaires (uniquement sur les tâches interventions)
        if ( Object.keys(commentToSave).length ) { // Mise à jour des commentaires
          const comment = commentToSave
          // Edition ou Création
          let comment_params = {
            content: comment.content,
            taskTodo: task_id
          }
          save_comment = await saveCommentTask('/task-comments'+(comment.id ? '/'+comment.id : ''), {method: (comment.id ? 'PUT' : 'POST')}, comment_params)
          if ( save_comment && save_comment.error ) { // Si erreur
            getErrors.error = true
            getErrors.message = save_comment.message
            if ( save_comment.errors ) {
              save_comment.errors.forEach((error, i) => {
                getErrors.errors.push('Intervention : '+error)
              })
            }
          }
          else if ( !comment.id ) { // Si succès POST
            newdatas.task.id_comment = parseInt(save_comment.id)
          }
        } // commentToSave
      }
    } // taskToUpdate

    if ( getErrors.error ) {
      return getErrors
    }
    else {
      return 'Vos données ont bien été enregistrées.'
    }
  }

  const closeTask = async () => {
    // 6 => EXPEDIER_COLIS_COURRIER OU 14 => EXPEDITION_COLIS
    // Si tâche pas intervention, on vérifie au préalable s'il y a au moins 2 fichiers d'uploadés sur la tâche
    if ( (taskEvent !== 3 || datas.task.taskType === 6 || datas.task.taskType === 14) && datas.files.length < 2 ) {
      setErrorSaving({
        error: true,
        message: "Veuillez charger au moins 2 photos avant de clôturer cette tâche."
      })

      // Scroll to top
      if ( props.fromPlanningBoard ) {
        document.getElementById('popin-content').scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
      else {
        window.scrollTo({
          top: document.getElementsByClassName('header')[0].offsetHeight+(document.getElementsByClassName('admin_reservation').length ? document.getElementsByClassName('admin_reservation')[0].offsetHeight : 0),
          behavior: 'smooth'
        })
      }
    }
    else {
      // S'il y a suffisamment de fichiers, on sauvegarde
      let params = {
        checkedEvent: true
      }
      // Si la tâche est facturable et non définie encore
      if ( datas.task.billable && !datas.task.billing ) {
        params.taskBilling = 1
      }
      const close_task = await saveTask('/task-todos/'+id_task, {method: 'PUT'}, params)
      if ( close_task.error ) {
        const getErrors = {}
        getErrors.error = true
        getErrors.message = close_task.message
        if ( close_task.errors ) {
          close_task.errors.forEach((error, i) => {
            getErrors.errors.push(error)
          })
        }
        setErrorSaving(getErrors)
        setIsSaving(false)

        // Scroll to top
        if ( props.fromPlanningBoard ) {
          document.getElementById('popin-content').scrollTo({
            top: 0,
            behavior: 'smooth'
          })
        }
        else {
          window.scrollTo({
            top: document.getElementsByClassName('header')[0].offsetHeight,
            behavior: 'smooth'
          })
        }
      }
      else {
        if ( props.fromPlanningBoard ) {
          props.closePopinTasks()
        }
        else {
          props.history.go(-1) // Retourne automatiquement à l'écran précédent
        }
      }
    }
  }

  const uncloseTask = async () => {
    // On décloture la tâche
    const unclose_task = await saveTask('/task-todos/'+id_task, {method: 'PUT'}, {checkedEvent: false})
    if ( unclose_task.error ) {
      const getErrors = {}
      getErrors.error = true
      getErrors.message = unclose_task.message
      if ( unclose_task.errors ) {
        unclose_task.errors.forEach((error, i) => {
          getErrors.errors.push(error)
        })
      }
      setErrorSaving(getErrors)
      setIsSaving(false)

      // Scroll to top
      if ( props.fromPlanningBoard ) {
        document.getElementById('popin-content').scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
      else {
        window.scrollTo({
          top: document.getElementsByClassName('header')[0].offsetHeight,
          behavior: 'smooth'
        })
      }
    }
    else {
      setIsSaving(false)
      setClosedTask(false)

      // Compare l'objet initial à l'objet à enregistrer
      updateChanges(datas, true)
    }
  }

  // Supprime une tâche intervention
  const deleteTaskFromPopin = async () => {
    // Si tâche intervention
    if ( deleteTaskPopin ) {
      const delete_task = await deleteTask('/task-todos/'+deleteTaskPopin, {method: 'DELETE'})
      if ( delete_task.error ) {
        const getErrors = {}
        getErrors.error = true
        getErrors.message = delete_task.message
        if ( delete_task.errors ) {
          delete_task.errors.forEach((error, i) => {
            getErrors.errors.push(error)
          })
        }
        setErrorSaving(getErrors)
        setIsSaving(false)

        // Scroll to top
        if ( props.fromPlanningBoard ) {
          document.getElementById('popin-content').scrollTo({
            top: 0,
            behavior: 'smooth'
          })
        }
        else {
          window.scrollTo({
            top: document.getElementsByClassName('header')[0].offsetHeight,
            behavior: 'smooth'
          })
        }
      }
      else if ( parseInt(id_task) === deleteTaskPopin ) { // Si on est sur l'écran de la tâche
        if ( props.fromPlanningBoard ) {
          props.closePopinTasks()
        }
        else {
          props.history.go(-1) // Retourne automatiquement à l'écran précédent
        }
      }
      else {
        // Supprime la tâche
        delete datas.task
        setDatas({...datas})

        // Ferme la popin
        setDeleteTaskPopin(null)
      }
    }
  }

  return (
    <div className={`content content hasloader
                     ${(isLoading || isSaving) ? ' loadin' : ''}
                     ${(props.hasChanges && (!closedTask || (closedTask && taskIsBillable))) ? ' has-changes' : ''}
                     ${(props.gotoUrl && (!closedTask || (closedTask && taskIsBillable))) ? ' full-changes' : ''}`}>
      {!isLoading &&
        <>
          {isError // Si pas de résultats de la route
          ?
            <NotFound />
          :
            <>
              <div className="admin_links">
                <NavLink exact to={`/logement/${id_renting}/ticket/`} className="admin_link">
                  Ouvrir un ticket
                </NavLink>

                {id_task
                ?
                  (id_renting) &&
                    <>
                      <NavLink exact to={`/logement/${id_renting}`} className="admin_link">
                        Voir ce logement
                      </NavLink>

                      <NavLink exact to={`/logement/${id_renting}/intervention/`} className="admin_link">
                        Ouvrir une intervention
                      </NavLink>
                    </>
                :
                  (!id_task && id_renting && isManager) &&
                    <NavLink exact to={`/logement/${id_renting}`} className="admin_link">
                      Voir toutes les réservations de ce logement
                    </NavLink>
                }
              </div>

              {errorSaving &&
                <div className="return error">
                  <div className="innerMediumWidth">
                    {errorSaving.message && <span>{errorSaving.message}</span>}
                    {errorSaving.errors &&
                      <ul>
                        {errorSaving.errors.map((error, i) => {
                          return <li key={i}>{error}</li>
                        })}
                      </ul>
                    }
                  </div>
                </div>
              }

              <div className="innerMediumWidth">
                <div className="locataire">
                  <div className="intervention">
                    <div className="horaire">
                      <span>Intervention prévue le {formatDateString(datas.task.date)} à</span>
                      <TimePickerCustom
                        time={getFrenchDate(datas.task.date)}
                        disabled={(!isManager && isCoequipier) ? true : false}
                        className="date_autre"
                        onChange={changeTimeIntervention}
                        minDateMessage={timeMinDateMessage}
                        invalidDateMessage={timeInvalidDateMessage}
                      />
                    </div>
                    {equipierIndex !== null &&
                      <div className="horaire">
                        <span>
                          Temps prévu pour votre intervention :{(datas.task.equipiers[equipierIndex].duree_string ? ' '+datas.task.equipiers[equipierIndex].duree_string : '')} à partir de
                        </span>
                        <TimePickerCustom
                          time={getFrenchDate((datas.task.equipiers[equipierIndex].heure ? datas.task.equipiers[equipierIndex].heure : null))}
                          className="date_autre"
                          onChange={changeTimeIntervention}
                          minDateMessage={timeMinDateMessage}
                          invalidDateMessage={timeInvalidDateMessage}
                        />
                      </div>
                    }
                    {datas.task.comment !== null &&
                      <>
                      Commentaire : <em>{datas.task.comment}</em>
                      </>
                    }
                  </div>
                </div>

                {(datas && datas.task && (isManager || isConcierge)) &&
                  <div className={`tasks-editor ${!taskEditor && !id_task ? 'hide' : ''}`}>
                    <div className="innerMediumWidth">
                      {!id_task &&
                        <h2 onClick={toggleTaskEdit}>
                          Éditer les tâches
                          <Icon icon="fleche" />
                        </h2>
                      }
                      <TaskReservation index='index'
                                       datas={datas.task}
                                       listEquipiers={listEquipiers}
                                       durees={durees}
                                       changeInput={changeTaskInput}
                                       changeEquipierInput={changeTaskEquipierInput}
                                       changeEquipierTime={changeTaskEquipierTime}
                                       setDeleteTaskPopin={setDeleteTaskPopin}
                                       changeDate={changeTaskDate}
                                       changeTime={changeTaskTime}
                                       addEquipier={addEquipier} />
                    </div>
                  </div>
                }

                <div className="address">
                  <p>{(datas.contents.adresse_nom) && <strong>{datas.contents.adresse_nom}<br /></strong>}
                  {datas.contents.adresse}
                  {(datas.contents.adresse2) && <><br />{datas.contents.adresse2}</>}
                  {(datas.contents.ville) && <><br />{datas.contents.cp} {datas.contents.ville}</>}
                  </p>
                  <div className="buttons">
                    <GPSUrl
                      adresse={(datas.contents.adresse ? datas.contents.adresse : '')
                               + (datas.contents.adresse2 ? ' '+datas.contents.adresse2 : '')
                               + (datas.contents.cp ? ' '+datas.contents.cp : '')
                               + (datas.contents.ville ? ' '+datas.contents.ville : '')}
                      lat={datas && datas.contents.lat}
                      lng={datas && datas.contents.lng}
                      label='GPS' />

                    {datas.contents.book &&
                      <a href={datas.contents.book} target="_blank" rel="noreferrer" className="btn btn-icon">
                        <span>
                          Book
                          <Icon icon='book' />
                        </span>
                      </a>
                    }
                  </div>
                </div>

                {
                datas.contents.coequipiers.length
                ?
                  <div className="coequipiers">
                    <strong>Intervention connexe :</strong>
                    <span>{isCoequipier ? "Vous êtes en appui de" : "Vos co-équipiers"} : {}</span>
                    <ul>
                      {
                        datas.contents.coequipiers.map((coequipier, i) => {
                          return <li key={i}>
                            <span>{coequipier.nom} ({coequipier.isCoequipier ? 'coéquipier' : 'équipier'}) - Temps prévu : {coequipier.duree}{coequipier.heure_debut ? ' à partir de '+coequipier.heure_debut : ''}</span>
                            <span><a href={`tel:${coequipier.tel}`}>{coequipier.tel}</a> - <a href={`mailto:${coequipier.mail}`}>{coequipier.mail}</a></span>
                          </li>
                        })
                      }
                    </ul>
                  </div>
                :
                  ''
                }

                <div className={`etat_lieux input_files ${(!isManager && isCoequipier) ? 'disabled' : ''}`}>
                  <div className="titre">
                    <h2>État des lieux</h2>
                  </div>
                  <label htmlFor="etat_lieux">
                    {taskEvent !== 3
                      ?
                      <>Prendre la feuille d'état des lieux en photo recto/verso et chargez vos photos ici (10 max) * :</>
                      :
                      <>Vous pouvez ajouter des photos si besoin (10 max) :</>
                    }
                  </label>
                  <InputFiles
                    id="etat_lieux"
                    name="etat_lieux"
                    label="Ajouter des photos"
                    disabled={(!isManager && isCoequipier) ? true : false}
                    files={(datas && datas.files)}
                    onChangeFiles={changeFiles}
                    onRemoveFile={removeFile}
                    />
                </div>
              </div>

              <div className={`cloture ${id_task && 'task'}`}>
                {(id_task && (isManager || !isCoequipier)) &&
                  <>
                    {!closedTask
                    ?
                    <div className="btns">
                      {(taskEvent === 3 && isManager)
                      ?
                        <>
                          <button type="button" className="btn" onClick={() => setDeleteTaskPopin(true)}>
                            Supprimer
                          </button>
                        </>
                      :
                        ''
                      }
                      <button type="button" className="btn btn-icon" onClick={closeTask}>
                        <span>
                          Mission réalisée
                          <Icon icon='check' />
                        </span>
                      </button>
                    </div>
                    :
                    <>
                      <span>Mission réalisée.</span>

                      {isManager &&
                        <>
                          {taskIsBillable && !savedTaskBillable
                          ?
                            <div className="choose_billing">
                              <label htmlFor="offert_billing">
                                <input id="offert_billing" name="billing" type="checkbox"
                                       checked={datas.task.billing === 3 ? true : false}
                                       onChange={(e) => toggleBilling(e, 3)} />
                                <span>Offerte</span>
                              </label>
                              <label htmlFor="facture_billing">
                                <input id="facture_billing" name="billing" type="checkbox"
                                       checked={datas.task.billing === 2 ? true : false}
                                       onChange={(e) => toggleBilling(e, 2)} />
                                <span>Facturée</span>
                              </label>
                            </div>
                          :
                            <>
                              {datas.task.billing === 2
                              ?
                                <span>Intervention facturée.</span>
                              :
                                ''
                              }
                              {datas.task.billing === 3
                              ?
                                <span>Intervention offerte.</span>
                              :
                                ''
                              }
                            </>
                          }
                          {(!datas.task.billing || datas.task.billing === 1) &&
                            <div className="btns">
                              <button type="button" className="btn btn-icon" onClick={uncloseTask}>
                                <span>
                                  Ré-ouvrir cette tâche
                                  <Icon icon='check' />
                                </span>
                              </button>
                            </div>
                          }
                        </>
                      }
                    </>
                    }
                  </>
                }
              </div>
            </>
          }
        </>
      }

      <Loader />

      <div className={`popin popin-delete-task ${deleteTaskPopin ? 'active' : ''}`}>
        <div className="popin-container">
          <p><strong>Confirmez-vous la suppression de cette tâche ?</strong></p>
          <div className="btns">
            <button className="btn" type="button" onClick={deleteTaskFromPopin}>Oui</button>
            <button className="btn" type="button" onClick={() => setDeleteTaskPopin(null)}>Non</button>
          </div>
        </div>
        <div className="popin-overlay"></div>
      </div>

      {(!closedTask || (closedTask && taskIsBillable && !savedTaskBillable)) &&
        <div className={`fix-save ${messageSaving ? 'succes' : ''} ${isSaving ? 'savin' : ''}`}>
          <p>Attention, vous n'avez pas sauvegardé vos modifications !</p>

          <div className='content'>
            <button type="button" className="btn btn-icon" onClick={submitDatas}>
              <span>
                Enregistrer les modifications
                <Icon icon='fleche' />
              </span>
            </button>

            <div className="return">
              {messageSaving && <span>{messageSaving}</span>}
            </div>
          </div>

          <button type="button" className="link" onClick={props.continueWithoutSaving}>Poursuivre votre navigation sans enregistrer</button>
        </div>
      }
    </div>
  )
}
