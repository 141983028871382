import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import useUser from '../hooks/useUser'
import useLoadDatas from '../hooks/useLoadDatas'
import useScroll from '../hooks/useScroll'
import Equipier from '../components/Equipier'
import Loader from '../components/Loader'
import Icon from '../components/Icon'
import NotFound from '../components/NotFound'
import DatePickerCustom from '../components/DatePickerCustom'
import {formatDate, formatDuration} from '../functions/functions.js'

export default function Equipage() {

  // Détermine le statut de l'utilisateur connecté
  const { user, checkUser, isManager, isConcierge, isCleaner } = useUser()

  const isFixed = useScroll('filters', 'header')

  const queryParams = new URLSearchParams(window.location.search)
  let init_dates = {
    date_debut: (queryParams.get('debut') ? queryParams.get('debut') : null),
    date_fin: (queryParams.get('fin') ? queryParams.get('fin') : null)
  }
  if ( !init_dates.date_debut ) { // Si pas de date en paramètre, on passe la date du jour
    const date_d = new Date()
    date_d.setDate(date_d.getDate() + (((1 + 7 - date_d.getDay()) % 7) || 7))
    const format_deb = formatDate(date_d)
    init_dates.date_debut = format_deb.an+"-"+format_deb.nmois+"-"+format_deb.date
  }
  if ( !init_dates.date_fin ) { // Si pas de date en paramètre, on passe la date du jour
    const date_f = new Date()
    date_f.setDate(date_f.getDate() + (((1 + 7 - date_f.getDay()) % 7) || 7) + 6)
    const format_fin = formatDate(date_f)
    init_dates.date_fin = format_fin.an+"-"+format_fin.nmois+"-"+format_fin.date
  }
  const [selectedDates, setSelectedDates] = useState({...init_dates})

  let init_statut = (queryParams.get('statut') ? parseInt(queryParams.get('statut')) : 1)
  const [selectedStatus, setSelectedStatus] = useState(init_statut)

  const options_roles = [
    {
      value: 'ROLE_EQUIPIER',
      label: 'Happy Concierge'
    },
    {
      value: 'ROLE_CLEANER',
      label: 'Cleaner'
    }
  ]

  // Chargement des données
  // ---------------------------------------------------------------------------

  // Structure la route de requêtes des équipiers
  const updateRouteEquipiers = (date_debut, date_fin) => {
    let params = '?pagination=false&order[firstName]=ASC&withTotalDuration=true';
    if ( date_debut ) {
      params += '&userTaskTodos.taskTodo.startAt[after]='+date_debut
    }
    if ( date_fin ) {
      params += '&userTaskTodos.taskTodo.endAt[before]='+date_fin
    }
    return '/users'+params
  }

  // Filtre par date de fin => recharge les données
  const dateChange = (newdate, context) => {
    const format_date = formatDate(new Date(newdate))
    const new_dates = selectedDates
    switch (context) {
      case 'debut':
        new_dates.date_debut = format_date.an+'-'+format_date.nmois+'-'+format_date.date
      break;
      case 'fin':
        new_dates.date_fin = format_date.an+'-'+format_date.nmois+'-'+format_date.date
      break;
      default:
    }
    setSelectedDates({...new_dates})

    // Ajuste les routes
    setEquipiers([])

    const url = new URL(window.location)
    url.searchParams.set('debut', new_dates.date_debut)
    url.searchParams.set('fin', new_dates.date_fin)
    window.history.pushState({}, '', url)

    setRouteEquipiers( updateRouteEquipiers(new_dates.date_debut, new_dates.date_fin) )
  }

  // Filtre par date de début => recharge les données
  const dateChangeDebut = (newdate) => {
    dateChange(newdate, 'debut')
  }

  // Filtre par date de fin => recharge les données
  const dateChangeFin = (newdate) => {
    dateChange(newdate, 'fin')
  }

  // Filtre par statut => filtre d'interface
  const filterByStatus = (e) => {
    // Met à jour le statut
    setSelectedStatus(e.target.value)

    // Met à jour la visibilité des équipiers
    refreshEquipiers(parseInt(e.target.value))

    const url = new URL(window.location)
    url.searchParams.set('statut', e.target.value)
    window.history.pushState({}, '', url)
  }

  const refreshEquipiers = (selectedStatus) => {
    // Met à jour les équipiers
    equipiers.forEach((equipier, i) => {
      equipier.show = (selectedStatus === equipier.status ? true : false)
    })
    setEquipiers([...equipiers])
  }



  // Chargement initial des équipiers
  const [routeEquipiers, setRouteEquipiers] = useState(updateRouteEquipiers(selectedDates.date_debut, selectedDates.date_fin))
  const [equipiers, setEquipiers] = useState()
  const [fetchEquipiers, isLoading, isError] = useLoadDatas()
  useEffect(() => {
    loadEquipiers()
    async function loadEquipiers() {
      const json_datas = await fetchEquipiers(routeEquipiers)
      if ( json_datas["hydra:member"] ) {
        let json_equipiers = []
        json_datas["hydra:member"].forEach((equipier) => {
          // Seulement si le rôle de l'utilisateur existe dans la liste
          const roleIndex = options_roles.findIndex((role) => role.value === equipier.role)
          if ( roleIndex >= 0 || user.hoomyId === equipier.hoomyId ) {
            const status = (equipier.isActive ? 1 : 0)
            json_equipiers.push(
              {
                id: equipier.hoomyId,
                name: equipier.firstName+" "+equipier.lastName,
                tel: equipier.phone,
                email: equipier.email,
                status: status,
                show: (status === selectedStatus ? true : false),
                color: equipier.color,
                metier: (options_roles[roleIndex] ? options_roles[roleIndex].label : null),
                heures: ((isManager || isConcierge) ? formatDuration(equipier.totalDuration) : null),
                editable: (isManager || isConcierge)
              }
            )
          }
        })
        setEquipiers([...json_equipiers])
      }
    }
  }, [routeEquipiers])

  return (
    <main className="equipage">
      <div className="header">
        <h1>Mon équipage</h1>
        <div id="filters" className={isFixed ? 'fixed' : ''}>
          <div className="innerMediumWidth">
            <DatePickerCustom
              date={selectedDates.date_debut}
              label='date de début'
              onChange={(date) => dateChangeDebut(date)}
            />
            <DatePickerCustom
              date={selectedDates.date_fin}
              label='date de fin'
              onChange={(date) => dateChangeFin(date)}
            />
            {(isManager || isConcierge) &&
              <div className="status">
                <label htmlFor="status">statut</label>
                <select id="status" name="status"
                        onChange={(e) => filterByStatus(e)}
                        value={(selectedStatus !== null ? selectedStatus : 1)}>
                  <option value={1}>Actifs</option>
                  <option value={0}>Archivés</option>
                </select>
              </div>
            }
          </div>
        </div>
      </div>

      <div className="content innerMediumWidth">
        {(isManager || isConcierge) &&
          <div className="ajout-equipier">
            <NavLink exact to="/equipage/nouveau/" itemProp="url" className="link-icon">
              <span itemProp="name">
                Ajouter un équipier
                <Icon icon="plus" />
              </span>
            </NavLink>
          </div>
        }

        <div className={`equipiers hasloader${isLoading ? ' loadin' : ''}`}>
          {!isLoading &&
            <>
              {isError // Si pas de résultats de la route
              ?
                <NotFound />
              :
                <>
                  {equipiers
                  ?
                    equipiers.map((equipier, index) => {
                      return (
                        <Equipier key={index} datas={equipier}/>
                      )
                    })
                  :
                    <em className="no-result">Vous n'avez aucun équipier sous vos responsabilités.</em>
                  }
                </>
              }
            </>
          }
          <Loader />
        </div>
      </div>
    </main>
  )
}
