/*
 * Routes nécessaires sur cet écran :
 - Route GET Récupération paginée (par 3 jours) des tâches d'un concierge et, si manager, des tâches de ses équipiers
 - Route GET Récupération paginée filtrable par date
 - Route GET Récupération des statuts de tâche ? Ou sinon, simplement en dur dans l'app...
 - Route GET Si manager, Récupération de la liste de ses équipiers
 */

import React, { useState, useEffect } from 'react'
import useUser from '../hooks/useUser'
import useLoadTickets from '../hooks/useLoadDatas'
import useLoadTicketStates from '../hooks/useLoadDatas'
import useLoadTicketLevels from '../hooks/useLoadDatas'
import useScroll from '../hooks/useScroll'
import Loader from '../components/Loader'
import Icon from '../components/Icon'
import BlocTicket from '../components/BlocTicket'
// import NotFound from '../components/NotFound'
import DatePickerCustom from '../components/DatePickerCustom'
import {formatDate, formatTime} from '../functions/functions.js'

export default function Tickets() {

  // Détermine le statut de l'utilisateur connecté
  const { user, isManager, isConcierge, isCleaner } = useUser()

  const isFixed = useScroll('filters', 'header')

  const queryParams = new URLSearchParams(window.location.search)
  let init_date = queryParams.get('date')
  // if ( !init_date ) { // Si pas de date en paramètre, on passe la date du jour
  //   const now_date = formatDate(new Date())
  //   init_date = now_date.an+"-"+now_date.nmois+"-"+now_date.date
  // }
  let init_statut = (queryParams.get('statut') ? queryParams.get('statut') : null)
  let init_level = (queryParams.get('level') ? queryParams.get('level') : null)
  let init_search = (queryParams.get('rech') ? queryParams.get('rech') : '')

  const [selectedDate, setSelectedDate] = useState(init_date)
  const [selectedState, setSelectedState] = useState(init_statut)
  const [selectedLevel, setSelectedLevel] = useState(init_level)
  const [search, setSearch] = useState(init_search)

  // Structure la route de requêtes des tâches
  const updateRouteTickets = (page, perPage, date, search) => {
    let params = '?order[creationAt]=asc&order[level.id]=asc&pagination=false'
    if ( date ) {
      params += (params ? '&' : '')+'creationAt[after]='+date
    }
    // if ( perPage ) {
    //   params += (params ? '&' : '')+'itemsPerPage='+perPage
    // }
    // if ( page ) {
    //   params += (params ? '&' : '')+'page='+page
    // }
    if ( search ) {
      params += (params ? '&' : '')+'search='+search
    }
    return '/tickets'+params
  }

  // Filtre par date => recharge les données
  const dateChange = (newdate) => {
    const format_date = formatDate(new Date(newdate))
    const new_date = format_date.an+'-'+format_date.nmois+'-'+format_date.date
    setSelectedDate(new_date)

    // Ajuste les routes
    setPage(1)
    setTickets([])
    setIsLoading(true)

    const url = new URL(window.location)
    url.searchParams.set('date', new_date)
    window.history.pushState({}, '', url)

    setRouteTickets( updateRouteTickets(1, perPage, new_date, search) )
  }

  // Filtre par statut => filtre d'interface
  const filterByState = (e) => {
    // Met à jour le statut
    setSelectedState(e.target.value)

    // Met à jour la visibilité des tickets
    refreshTickets(e.target.value, selectedLevel)

    const url = new URL(window.location)
    url.searchParams.set('statut', e.target.value)
    window.history.pushState({}, '', url)
  }

  // Filtre par statut => filtre d'interface
  const filterByLevel = (e) => {
    // Met à jour le statut
    setSelectedLevel(e.target.value)

    // Met à jour la visibilité des tickets
    refreshTickets(selectedState, e.target.value)

    const url = new URL(window.location)
    url.searchParams.set('level', e.target.value)
    window.history.pushState({}, '', url)
  }

  const refreshTickets = (selectedState, selectedLevel) => {
    // Met à jour les tickets
    tickets.forEach((day, i) => {
      let show_day = false
      day.values.forEach((ticket, i) => {
        if ( (!selectedState && !selectedLevel && ticket.state !== "cloture" )
            || (selectedState === ticket.state && !selectedLevel)
            || (!selectedState && selectedLevel === ticket.level)
            || (selectedState === ticket.state && selectedLevel === ticket.level)
          ) {
          ticket.show = true
          show_day = true
        }
        else {
          ticket.show = false
        }
      })
      day.show_day = show_day
    })
    setTickets([...tickets])
  }

  // Soumission de la recherche par la touche Entrée
  const searchKeyPress = (e) => {
    if ( e.key === "Enter" ) {
      submitSearch(e)
    }
  }

  // Execute la requete avec recherche
  const submitSearch = async (e) => {
    e.preventDefault()

    // Seulement si la recherche est différente
    const prev_url = new URL(window.location)
    const prev_search = (prev_url.searchParams.get('rech') ? prev_url.searchParams.get('rech') : '')
    if ( prev_search !== search ) {
      // Ajuste les routes
      setPage(1)
      setTickets([])
      setIsLoading(true)

      const url = new URL(window.location)
      url.searchParams.set('rech', search)
      window.history.pushState({}, '', url)

      setRouteTickets( updateRouteTickets(1, perPage, selectedDate, search) )
    }
  }


  // Chargement des statuts
  // ---------------------------------------------------------------------------
  const [ticketStates, setTicketStates] = useState([])
  const routeTicketStates = '/ticket-states'
  const [fetchTicketStates] = useLoadTicketStates()
  useEffect(() => {
    loadTicketStates()
    async function loadTicketStates() {
      const json_states = await fetchTicketStates(routeTicketStates)
      if ( json_states["hydra:member"] ) {
        let ticket_states = []
        json_states["hydra:member"].forEach((state) => {
          // On ne remonte pas les tickets cloturés si le user n'est pas manager
          if ( isManager || (!isManager && state.code !== "CLOTURE") ) {
            ticket_states.push(
              {
                value: state.code.toLowerCase(),
                label: state.label
              }
            )
          }
        })
        setTicketStates([...ticket_states])
      }
    }
  }, [routeTicketStates])


  // Chargement des levels
  // ---------------------------------------------------------------------------
  const [ticketLevels, setTicketLevels] = useState([])
  const routeTicketLevels = '/ticket-levels'
  const [fetchTicketLevels] = useLoadTicketLevels()
  useEffect(() => {
    loadTicketLevels()
    async function loadTicketLevels() {
      const json_levels = await fetchTicketLevels(routeTicketLevels)
      if ( json_levels["hydra:member"] ) {
        let ticket_levels = []
        json_levels["hydra:member"].forEach((level) => {
          ticket_levels.push(
            {
              value: level.code.toLowerCase(),
              label: level.label
            }
          )
        })
        setTicketLevels([...ticket_levels])
      }
    }
  }, [routeTicketLevels])


  // Chargement des tâches / réservations
  // ---------------------------------------------------------------------------
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)

  const [page, setPage] = useState(1)
  const perPage = 20

  // Route tickets
  const [routeTickets, setRouteTickets] = useState( updateRouteTickets(page, perPage, selectedDate, search) )
  const [fetchTickets] = useLoadTickets()

  const [tickets, setTickets] = useState([])

  useEffect(() => {
    loadTickets()
    async function loadTickets() {
      const tickets_datas = await fetchTickets(routeTickets)

      if ( tickets_datas.error ) { // || rentings_datas.error ) {
        setIsError(true)
      }
      else {
        // S'il y a des éléments
        if ( tickets_datas['hydra:member'] ) {
          tickets_datas['hydra:member'].forEach((ticket, i) => {
            const date = formatDate(new Date(ticket.creationAt))
            const date_split = ticket.creationAt.split("T")
            const date_index = date_split[0].replaceAll('-', '')

            // Si la date n'existe pas dans l'objet, on crée la date
            let index = tickets.findIndex((ticket) => ticket.date_index === date_index)
            if ( index < 0 ) {
              index = tickets.length
              tickets.push({
                date_index: date_index,
                date: date,
                show_day: false,
                values: []
              })
            }

            // On ajoute la location à la date
            let title = (ticket.renting.owner.lastName.trim() ? ticket.renting.owner.lastName.toUpperCase().trim() : '')
            title += (ticket.renting.rentingNameAlt ? ' | '+ticket.renting.rentingNameAlt : '')
            title += (ticket.renting.rentingName ? ' | '+ticket.renting.rentingName.toUpperCase() : '')

            let title_more = (ticket.subject ? ticket.subject.trim() : '')+(ticket.level.label ? (ticket.subject ? ' - ' : '')+ticket.level.label.trim() : '')

            // Ajoute la tâche à la liste
            tickets[index].values.push({
              id: ticket.id,
              value: title,
              value_more: title_more,
              level: ticket.level.code.toLowerCase(),
              state: ticket.state.code.toLowerCase(),
              show: false
            })
          })

          // Met à jour les tickets selon les filtres actifs au chargement
          refreshTickets(selectedState, selectedLevel)
        }
      }

      setIsLoading(false)
    }
  }, [routeTickets])

  return (
    <main className="planning tickets">
      <div className="header">
        <h1>Tickets</h1>
        <div id="filters" className={isFixed ? 'fixed' : ''}>
          <div className="innerMediumWidth">
            <DatePickerCustom
              date={selectedDate}
              onChange={(date) => dateChange(date)}
            />

            <div className="states">
              <label htmlFor="states">statut</label>
              <select id="states" name="states"
                      onChange={(e) => filterByState(e)}
                      value={(selectedState ? selectedState : '')}>
                <option value="">Tous</option>
                {ticketStates.length &&
                  ticketStates.map((ticketState, index) => {
                    return (
                      <option key={index} value={ticketState.value}>
                        {ticketState.label}
                      </option>
                    )
                  })
                }
              </select>
            </div>

            {isManager &&
              <div className="level">
                <label htmlFor="level">urgence</label>
                <select id="level" name="level"
                        onChange={(e) => filterByLevel(e)}
                        value={(selectedLevel ? selectedLevel : '')}>
                  <option value="">Toutes</option>
                  {ticketLevels.length &&
                    ticketLevels.map((ticketLevel, index) => {
                      return (
                        <option key={index} value={ticketLevel.value}>
                          {ticketLevel.label}
                        </option>
                      )
                    })
                  }
                </select>
              </div>
            }

            <div className="search">
              <div className="input-search">
                <input id="search" name="search" type="search"
                        onChange={(e) => setSearch(e.target.value)}
                        onKeyPress={(e) => searchKeyPress(e)}
                        placeholder="Recherche..."
                        value={(search ? search : '')} />
                <button type="button" className="btn-search" onClick={submitSearch}>
                  <Icon icon='loupe' />
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className={`content innerMediumWidth ${tickets.length > 0 ? 'hasElements' : ''}`}>
        <div className="days">
          {tickets.length > 0
            ?
            tickets.map((ticket, index) => (
              <div key={index} className={`day${ticket.show_day ? ' active' : ''}`}>
                <div className="day-content">
                  <span className="date">{ticket.date.jour} <strong>{ticket.date.date}</strong> {ticket.date.mois}</span>
                  {ticket &&
                    <div className="tasks">
                      {ticket.values.map((value, index) => (
                        <BlocTicket key={index} {...value} />
                      ))}
                    </div>
                  }
                </div>
              </div>
            ))
            :
            <>
              {!isLoading &&
                <em className="no-result">Aucun ticket à traiter.</em>
              }
            </>
          }
        </div>
        <div className={`more-tasks hasloader ${(isLoading ? 'loadin' : 'hide')}`}>
          <Loader />
        </div>
      </div>
    </main>
  )
}
