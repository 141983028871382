import React from 'react'
import { NavLink } from 'react-router-dom'
import Icon from '../components/Icon'

export default function Equipier({datas}) {
  return (
    <div className={`equipier ${datas.show && "active"}`}>
      <div className="inner">
        <div className="content">
          <p><strong>{datas.name}</strong>{datas.metier ? ' - '+datas.metier : ''}</p>
          <p>{datas.tel &&
            <a href={`tel:${datas.tel}`}>{datas.tel}</a>
          }
          {datas.email &&
            <>
              {datas.tel ? ' - ' : ''}
              <a href={`mailto:${datas.email}`}>{datas.email}</a>
            </>
          }
          </p>
          {datas.heures &&
            <p>Nombre d'heures sur la période : {datas.heures}</p>
          }
        </div>
        <div className="icons">
          {datas.status
          ?
            <Icon icon="check" classe="statut active" />
          :
            <Icon icon="inactive" classe="statut inactive" />
          }
          <div className="couleur" style={{background: datas.color}}></div>
          {datas.editable &&
            <NavLink exact to={`/equipage/${datas.id}/`} itemProp="url" className="btn-edit">
              <span itemProp="name">
                <Icon icon="edit" title="Modifier l'équipier" />
              </span>
            </NavLink>
          }
        </div>
      </div>
    </div>
  )
}
