import React, { useState } from 'react'
import { NavLink, useParams, useHistory } from 'react-router-dom'
import useBlockHistory from '../hooks/useBlockHistory'
import TicketContent from '../components/TicketContent'

// Objet contenant les données initiales au chargement du ticket
export default function Ticket() {
  const { id_ticket, id_task } = useParams()
  const history = useHistory()

  const [title, setTitle] = useState("Chargement des données...")
  const [objet, setObjet] = useState("")
  const [closedTask, setClosedTask] = useState()
  const {hasChanges, setHasChanges, gotoUrl, setGotoUrl, saveBeforeContinue, continueWithoutSaving} = useBlockHistory()

  return (
    <main className="ticket reservation">
      <div className="header">
        <h1>{title}</h1>
        {objet}
      </div>

      <TicketContent
        id_ticket={id_ticket}
        id_task={id_task}
        setTitle={setTitle}
        setObjet={setObjet}
        history={history}
        gotoUrl={gotoUrl}
        setGotoUrl={setGotoUrl}
        hasChanges={hasChanges}
        saveBeforeContinue={saveBeforeContinue}
        continueWithoutSaving={continueWithoutSaving}
        setHasChanges={setHasChanges} />
    </main>
  )
}
