/*
 * Routes nécessaires sur cet écran :
 - Route GET Si ID équipier, Récupération des infos de l'équipier
 - Route POST Enregistrement des infos de l'équipier (si ID équipier défini alors Modification, sinon Création ?)
 */

import React, { useState, useEffect } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import useUser from '../hooks/useUser'
import useLoadTicket from '../hooks/useLoadDatas'
import useLoadTicketLevels from '../hooks/useLoadDatas'
import useLoadTicketImage from '../hooks/useLoadDatas'
import useLoadEquipiers from '../hooks/useLoadDatas'
import useTaskEvents from '../hooks/useLoadDatas'
import useTaskStates from '../hooks/useLoadDatas'
import useTaskTypes from '../hooks/useLoadDatas'
import useSaveDatas from '../hooks/useSaveDatas'
import useSaveTask from '../hooks/useSaveDatas'
import useDeleteTask from '../hooks/useDeleteDatas'
import useSaveUserTask from '../hooks/useSaveDatas'
import useDeleteUserTask from '../hooks/useDeleteDatas'
import Loader from '../components/Loader'
import NotFound from '../components/NotFound'
import Icon from '../components/Icon'
import GPSUrl from '../components/GPSUrl'
import TaskReservation from '../components/TaskReservation'
import TimePickerCustom from '../components/TimePickerCustom'
import useForm from '../hooks/useForm'
import {getFrenchDate, formatHour, formatDatetime, formatDateString, formatTimeString, formatDuration, getCurrentDate} from '../functions/functions.js'

// Liste pour le sélecteur de durées
// Propose toutes les 15min de 0 à 3h et toutes 30min de 3h à 8h
let durees = []
for (let i=1; i<=32; i++) {
  const value = (i/4)
  const check_quart = (value/0.5)
  if ( check_quart <= 6 || (check_quart > 6 && Number.isInteger(check_quart)) ) {
    durees.push({
      value: value,
      label: formatTimeString(value)
    })
  }
}

let initialDatas = {}

export default function TicketContent(props) {
  const id_ticket = props.id_ticket
  const id_task = props.id_task

  // Détermine le statut de l'utilisateur connecté
  const { user, checkUser, isManager, isConcierge, isCleaner } = useUser()
  const [equipierIndex, setEquipierIndex] = useState(null) // Equipier courant
  const [isCoequipier, setIsCoequipier] = useState(false)

  // Chargement des données
  // ---------------------------------------------------------------------------
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [routeTicket, setRouteTicket] = useState('/tickets/'+id_ticket)
  const [fetchTicket] = useLoadTicket()
  const [ticketLevels, setTicketLevels] = useState([])
  const [toggleRefresh, setToggleRefresh] = useState(false)
  const routeTicketLevels = '/ticket-levels'
  const [fetchTicketLevels] = useLoadTicketLevels()
  const [fetchTicketImage] = useLoadTicketImage()
  const [images, setImages] = useState(null)
  const [bigFile, setBigFile] = useState(null)

  const [datas, setDatas] = useState({}) // Objet des données
  const [commentLength, setCommentLength] = useState(0)
  const [listComments, setListComments] = useState([]) // Liste des services disponibles
  const [traiteTicket, setTraiteTicket] = useState(false)
  const [closedTicket, setClosedTicket] = useState(false)
  const [deleteTaskPopin, setDeleteTaskPopin] = useState(null)

  const timeMinDateMessage = "L'heure ne doit pas être antérieure à la date minimale"
  const timeInvalidDateMessage = "Le format de l'heure n'est pas valide"

  useEffect(() => {
    loadTicket()
    async function loadTicket() {
      const ticket_datas = await fetchTicket(routeTicket)

      // Si erreur
      if ( ticket_datas.error ||ticket_datas.error ) {
        props.setTitle('Chargement en erreur')
        setIsError(true)
      }
      // Si succès
      else {
        // Met à jour la route des équipiers favoris
        setRouteFavEquipiers('/users?pagination=false&isActive=1&order[role]=DESC&favoriteRentings.renting.hoomyIds[]='+ticket_datas.renting.hoomyId)

        // Chargement des levels
        // ---------------------------------------------------------------------------
        const json_levels = await fetchTicketLevels(routeTicketLevels)
        if ( json_levels["hydra:member"] ) {
          let ticket_levels = []
          json_levels["hydra:member"].forEach((level) => {
            ticket_levels.push(
              {
                id: level.id,
                label: level.label
              }
            )
          })
          setTicketLevels([...ticket_levels])
        }

        let comments = []
        if ( ticket_datas.comments.length ) {
          ticket_datas.comments.forEach((comment) => {
            comments.push(
              {
                id: comment.id,
                date: formatDateString(comment.createdAt),
                content: comment.content,
                username: (comment.user.firstName+(comment.user.lastName && ' '+comment.user.lastName)).trim()
              }
            )
          })
        }

        // Objet du ticket
        initialDatas = {
          id: ticket_datas.id,
          id_renting: ticket_datas.renting.hoomyId,
          level: ticket_datas.level.id,
          state: ticket_datas.state.id,
          new_comment: '',
          tasks: [],
          contents: {
            objet: ticket_datas.subject+(ticket_datas.level && ' - '+ticket_datas.level.label),
            ticket_date: ticket_datas.creationAt,
            ticket_resume: ticket_datas.content,
            ticket_username: ticket_datas.user.firstName+' '+ticket_datas.user.lastName,
            prenom: ticket_datas.renting.owner.firstName,
            nom: ticket_datas.renting.owner.lastName,
            adresse: ticket_datas.renting.address,
            adresse2: ticket_datas.renting.addressBis,
            cp: ticket_datas.renting.postalCode,
            ville: ticket_datas.renting.city,
            lat: ticket_datas.renting.latitude,
            lng: ticket_datas.renting.longitude,
            coequipiers: [],
            comments: comments,
            book: (ticket_datas.book ? ticket_datas.book.url : null)
          }
        }


        // S'il y a des images
        if ( ticket_datas.images.length ) {
          let token = await checkUser(user)
          const fetchHeaders = new Headers()
          // fetchHeaders.append("Content-Type", "application/json")
          fetchHeaders.append("Authorization", "Bearer "+token)
          let get_images = []
          await Promise.all(
            ticket_datas.images.map( async (image, i) => {
              const json_image = await fetch(process.env.REACT_APP_API+image.contentUrl, {
                method: 'GET',
                headers: fetchHeaders,
                mode: 'cors'
              })
              .then(response => response.blob()) // Gets the response and returns it as a blob
              .then(blob => {
                 let objectURL = URL.createObjectURL(blob)
                 get_images.push(objectURL)
              })
            })
          )
          setImages([...get_images])
        }

        // Ajout des tâches si Manager
        let initialTasks = []
        let task_index = null
        if ( ticket_datas.interventions.length ) {

          // S'il y a des tâches de définies, on les récupère
          let equipiers = []
          ticket_datas.interventions.map( (task, i) => {
            if ( id_task ) {
              task_index = i
            }

            if ( task.userTaskTodos.length ) {
              task.userTaskTodos.forEach((userTaskTodo, i) => {
                if ( userTaskTodo.isTeammate ) { // Si coéquipier
                  equipiers.push({
                    user_id: userTaskTodo.id,
                    equipier: userTaskTodo.user.hoomyId,
                    heure: userTaskTodo.teammateStartAt,
                    duree: userTaskTodo.duration,
                    duree_string: formatDuration(userTaskTodo.duration),
                    coequipier: userTaskTodo.isTeammate,
                    user: userTaskTodo.user
                  })
                }
                else {
                  // Si équipier principal, on le force au début du tableau
                  equipiers.unshift({
                    user_id: userTaskTodo.id,
                    equipier: userTaskTodo.user.hoomyId,
                    heure: userTaskTodo.teammateStartAt,
                    duree: userTaskTodo.duration,
                    duree_string: formatDuration(userTaskTodo.duration),
                    coequipier: userTaskTodo.isTeammate,
                    user: userTaskTodo.user
                  })
                }
              })
            }
            else {
              // Sinon, par défaut, on ajoute un champ équipier
              equipiers.push({
                user_id: null,
                equipier: null,
                heure: null,
                duree: null,
                duree_string: '',
                coequipier: false
              })
            }

            // inventory: null
            initialTasks.push({
              id: task.id,
              name: task.name,
              ticket: id_ticket,
              id_event: 3, // task.taskEvent.id
              id_state: 1, // task.taskState.id
              id_type: null, //task.taskType.id,
              date: task.startAt,
              precision: task.name,
              equipiers: equipiers,
              closed: task.checkedEvent
            })
          })
        }
        initialDatas.tasks = initialTasks

        if ( id_task ) { // Si écran Tâche
          if ( ticket_datas.interventions.length === 0 ) {
            window.location = process.env.REACT_APP_URI+'/ticket/'+id_ticket+'/'
          }
          // S'il y a des équipiers
          if ( initialTasks[task_index].equipiers.length ) {
            // On récupère le user qui n'est pas le user connecté
            initialTasks[task_index].equipiers.forEach((equip, i) => {
              if ( equip.user_id ) {
                // Dans le cas de l'utilisateur connecté
                if ( equip.equipier === user.hoomyId ) {
                  setEquipierIndex(i)
                  if ( equip.coequipier ) {
                    setIsCoequipier(equip.coequipier)
                  }
                }
                // Dans le cas de l'utilisateur non connecté
                else if ( equip.equipier !== user.hoomyId ) {
                  initialDatas.contents.coequipiers.push({
                    nom: equip.user.firstName.trim()+' '+equip.user.lastName.trim(),
                    isCoequipier: equip.coequipier,
                    tel: equip.user.phone,
                    mail: equip.user.email,
                    heure_debut: (equip.heure ? formatHour(equip.heure) : null),
                    duree: equip.duree_string
                  })
                }
              }
            })
          }
        }

        // Met à jour le titre de l'écran
        if ( id_task ) { // Si écran tâche du ticket
          props.setTitle(
            'Intervention'
            + ' | '+ticket_datas.renting.owner.lastName.trim()
            + (ticket_datas.renting.rentingNameAlt ? ' | '+ticket_datas.renting.rentingNameAlt : '') // id_location
            + ' | '+ticket_datas.renting.rentingName.toUpperCase() // id_location
            + ' | '+initialTasks[0].name
          )
        }
        else { // Si écran Ticket
          props.setTitle(
            ticket_datas.renting.owner.lastName.trim()
            + (ticket_datas.renting.rentingNameAlt ? ' | '+ticket_datas.renting.rentingNameAlt : '') // id_location
            + ' | '+ticket_datas.renting.rentingName.toUpperCase()
          )
        }
        props.setObjet(((datas && datas.contents) && datas.contents.objet))

        // Si intervention sur ticket
        if ( id_task ) {
          // Si tâche réalisée
          setClosedTicket(initialDatas.tasks[0].closed)
        }
        // Sinon, ticket
        else {
          // Si ticket traité ou cloturé
          setTraiteTicket(((ticket_datas.state.id !== 2) ? true : false))

          // Si ticket cloturé
          setClosedTicket((ticket_datas.state.id === 3 ? true : false))
        }

        // On met à jour les données de la réservation
        setDatas({...initialDatas})
        initialDatas = JSON.stringify(initialDatas) // Stocke le JSON de ces données initiales
      }

      // Fin de chargement
      setIsLoading(false)
    }
  }, [routeTicket, toggleRefresh])


  // Récupération des routes
  const routeTaskEvents = '/task-events'
  const [fetchTaskEvents] = useTaskEvents()
  const [taskEvents, setTaskEvents] = useState([])
  useEffect(() => {
    loadTaskEvents()
    async function loadTaskEvents() {
      const task_events = await fetchTaskEvents(routeTaskEvents)
      let items = []
      if ( !task_events.error ) {
        task_events['hydra:member'].forEach((item, i) => {
          items[item.id] = item.label
        })
        setTaskEvents([...items])
      }
    }
  }, [routeTaskEvents])


  // Chargement des équipiers
  // ---------------------------------------------------------------------------
  const [listEquipiers, setEquipiers] = useState([])
  const [routeFavEquipiers, setRouteFavEquipiers] = useState()
  const [routeEquipiers, setRouteEquipiers] = useState()
  let [startDate, setStartDate] = useState(null)
  let [endDate, setEndDate] = useState(null)
  const [fetchEquipiers] = useLoadEquipiers()
  useEffect(() => {
    loadFavEquipiers()
    async function loadFavEquipiers() {
      if ( routeFavEquipiers ) {
        const json_datas = await fetchEquipiers(routeFavEquipiers)
        if ( json_datas && json_datas["hydra:member"] ) {
          json_datas["hydra:member"].forEach((equipier) => {
            listEquipiers.push(
              {
                id: equipier.hoomyId,
                name: (equipier.hoomyId === user.hoomyId ? 'Moi' : equipier.firstName+" "+equipier.lastName),
                tel: equipier.phone,
                email: equipier.email,
                status: equipier.isActive,
                color: equipier.color,
                fav: true,
                // indispo: false,
                indispos: []
              }
            )
          })
          setEquipiers([...listEquipiers])
        }

        // Enchaine sur la route de tous les utilisateurs
        // setRouteEquipiers('/users?pagination=false&isActive=1&withEvents=true&events.timeMin='+startDate+'&events.timeMax='+endDate)
        setRouteEquipiers('/users?pagination=false&isActive=1')
      }
    }
  }, [routeFavEquipiers])

  useEffect(() => {
    loadEquipiers()
    async function loadEquipiers() {
      if ( routeEquipiers ) {
        const json_datas = await fetchEquipiers(routeEquipiers)
        if ( json_datas && json_datas["hydra:member"] ) {
          json_datas["hydra:member"].forEach((equipier) => {
            const equipIndex = listEquipiers.findIndex((equip) => equip.id === equipier.hoomyId)
            if ( equipIndex >= 0 ) { // Si équipier existe déjà dans la liste
              listEquipiers[equipIndex].indispos = (equipier.events ? equipier.events : [])
            }
            else {
              listEquipiers.push(
                {
                  id: equipier.hoomyId,
                  name: (equipier.hoomyId === user.hoomyId ? 'Moi' : equipier.firstName+" "+equipier.lastName),
                  tel: equipier.phone,
                  email: equipier.email,
                  status: equipier.isActive,
                  color: equipier.color,
                  fav: false,
                  // indispo: false,
                  indispos: (equipier.events ? equipier.events : [])
                }
              )
            }
          })

          // S'il n'est pas dans la liste, on ajoute l'utilisateur courant
          const equipIndex = listEquipiers.findIndex((equip) => equip.id === user.hoomyId)
          if ( equipIndex < 0 ) { // Si équipier existe déjà dans la liste
            listEquipiers.push(
              {
                id: user.hoomyId,
                name: 'Moi',
                fav: false,
                // indispo: false,
                indispos: []
              }
            )
          }

          setEquipiers([...listEquipiers])
        }
      }
    }
  }, [routeEquipiers])



  // Crée une task
  const addTask = () => {
    datas.tasks.push({
      id: null,
      name: "Intervention", //taskEvents[3],
      id_event: 3,
      id_state: 1,
      id_type: null,
      ticket: id_ticket,
      date: getFrenchDate(datas.contents.ticket_date),
      precision: "",
      equipiers: [{
        user_id: null,
        equipier: null,
        heure: null,
        duree: null,
        duree_string: '',
        coequipier: false
      }]
    })
    setDatas({...datas})

    // Compare l'objet initial à l'objet à enregistrer
    updateChanges(datas)
  }

  // Ajoute un équipier
  const addEquipier = (index) => {
    datas.tasks[index].equipiers.push({
      user_id: null,
      equipier: null,
      heure: null,
      duree: null,
      duree_string: '',
      coequipier: true
    })
    setDatas({...datas})

    // Compare l'objet initial à l'objet à enregistrer
    updateChanges(datas)
  }

  /*
   * Fonctions liées à la réservation
   * ------------------------------------------------------------------------ */
  const changeTaskDate = (input_date, index) => {
    if ( input_date.$d != 'Invalid Date' ) {
      const datetime = new Date(input_date)
      const format_date = datetime.getFullYear()+'-'+String(datetime.getMonth()+1).padStart(2, '0')+'-'+String(datetime.getDate()).padStart(2, '0')

      const initialDate = new Date(datas.tasks[index].date)
      const format_time = initialDate.toLocaleTimeString('fr-FR', {hour:'2-digit', minute:'2-digit'})

      const date = format_date+'T'+format_time+':00' // '2021-04-21T18:00'
      datas.tasks[index].date = date

      // S'il y a des équipiers sur la tâche, on change leur heure
      if ( datas.tasks[index].equipiers.length ) {
        datas.tasks[index].equipiers.forEach((equipier, i) => {
          // Récupère l'heure de l'équipier si l'heure est déjà définie
          if ( equipier.heure ) {
            const equipier_time = new Date(equipier.heure)
            const format_time = equipier_time.toLocaleTimeString('fr-FR', {hour:'2-digit', minute:'2-digit'})

            // Combine la date de la tâche avec l'heure de l'équipier
            const equipier_date = format_date+'T'+format_time+':00' // '2021-04-21T18:00:00'

            // On enregistre la modification d'heure équipier
            datas.tasks[index].equipiers[i].heure = equipier_date
          }
        });
      }

      // Enregistre la modification
      setDatas({...datas})

      // Compare l'objet initial à l'objet à enregistrer
      updateChanges(datas)
    }
  }
  const changeTaskTime = (input_date, index) => {
    if ( input_date.$d != 'Invalid Date' ) {
      const initialDate = new Date(datas.tasks[index].date)
      const format_date = initialDate.getFullYear()+'-'+String(initialDate.getMonth()+1).padStart(2, '0')+'-'+String(initialDate.getDate()).padStart(2, '0')

      const datetime = new Date(input_date)
      const format_time = datetime.toLocaleTimeString('fr-FR', {hour:'2-digit', minute:'2-digit'})

      const date = format_date+'T'+format_time+':00' // '2021-04-21T18:00'

      datas.tasks[index].date = date
      setDatas({...datas})

      // Compare l'objet initial à l'objet à enregistrer
      updateChanges(datas)
    }
  }
  const changeTaskInput = (e) => {
    const names = e.target.name.split("_")
    const name = names[0]
    const index = names[1]

    datas.tasks[index][name] = e.target.value
    setDatas({...datas})

    // Compare l'objet initial à l'objet à enregistrer
    updateChanges(datas)
  }
  const changeTaskEquipierInput = (e) => {
    const names = e.target.name.split("_")
    const name = names[0]
    const index = names[1]
    const sub_index = names[2]

    datas.tasks[index].equipiers[sub_index][name] = e.target.value
    setDatas({...datas})

    // Compare l'objet initial à l'objet à enregistrer
    updateChanges(datas)
  }

  // Change l'heure d'un équipier
  const changeTaskEquipierTime = (task_index, equipier_index, input_date) => {
    // Récupère la date de la tâche
    const task_date = new Date(datas.tasks[task_index].date)
    const format_date = task_date.getFullYear()+'-'+String(task_date.getMonth()+1).padStart(2, '0')+'-'+String(task_date.getDate()).padStart(2, '0')

    // Récupère l'heure de l'équipier
    const equipier_time = new Date(input_date)
    const format_time = equipier_time.toLocaleTimeString('fr-FR', {hour:'2-digit', minute:'2-digit'})

    // Combine la date de la tâche avec l'heure de l'équipier
    const date = format_date+'T'+format_time+':00' // '2021-04-21T18:00:00'

    // On enregistre la modification d'heure équipier
    datas.tasks[task_index].equipiers[equipier_index].heure = date

    setDatas({...datas})

    // Compare l'objet initial à l'objet à enregistrer
    updateChanges(datas)
  }
  const changeTimeIntervention = (input_date) => {
    const datetime = new Date(input_date)
    const format_date = datetime.getFullYear()+'-'+String(datetime.getMonth()+1).padStart(2, '0')+'-'+String(datetime.getDate()).padStart(2, '0')
    const format_time = datetime.toLocaleTimeString('fr-FR', {hour:'2-digit', minute:'2-digit'})
    const date = format_date+'T'+format_time+':00'
    datas.tasks[0].date = date
    setDatas({...datas})

    // Compare l'objet initial à l'objet à enregistrer
    updateChanges(datas)
  }

  // Met à jour l'objet à enregistrer si la tâche n'est pas clôturée
  const updateChanges = (datas) => {
    if ( !closedTicket ) {
      props.setHasChanges(initialDatas !== JSON.stringify(datas))
    }
  }

  const changeInput = (e) => {
    datas[e.target.name] = (e.target.name == "level" ? parseInt(e.target.value ? e.target.value : 0) : e.target.value)
    setDatas({...datas})

    // Compare l'objet initial à l'objet à enregistrer
    updateChanges(datas)

    if ( e.target.name === "new_comment" ) {
      setCommentLength(e.target.value.length)
    }
  }

  /*
   * Fonction d'enregistrement des données
   * ------------------------------------------------------------------------ */
  const [isSaving, setIsSaving] = useState(false)
  const [messageSaving, setMessageSaving] = useState('')
  const [errorSaving, setErrorSaving] = useState(null)
  const [saveDatas] = useSaveDatas() // '/locations/'+id_location, {method: 'PUT'}
  const [saveTask] = useSaveTask() // '/task-todos', {method: 'POST'}
  const [deleteTask] = useDeleteTask() // '/task-todos', {method: 'POST'}
  const [saveUserTask] = useSaveUserTask() // '/user-task-todos', {method: 'POST'}
  const [deleteUserTask] = useDeleteUserTask()

  const submitDatas = async (e) => {
    e.preventDefault()

    // Réinitialise avant traitement
    setMessageSaving('')
    setErrorSaving(null)
    setIsSaving(true)

    // Traitement données formulaire
    // Comparer l'objet initial à l'objet à enregistrer
    let datasToSave = {}
    let commentToSave = {}
    let tasksToCreate = []
    let tasksToUpdate = []
    let userTaskTodos = []
    const compareDatas = JSON.parse(initialDatas)

    for (const key in compareDatas) {
      switch (key) {
        case 'contents':
          // Pas de modification possible de ces champs
        break;

        case 'tasks':
          const tasks = datas[key]
          for (const task in tasks) {
            const initask = compareDatas.tasks[task]
            const ctask = tasks[task]

            if ( initask !== ctask ) {
              // Compose l'objet de modification des équipiers
              if ( ctask.equipiers ) {
                ctask.equipiers.forEach((equip, i) => {
                  if ( !initask || JSON.stringify(initask.equipiers[i]) !== JSON.stringify(equip) ) {
                    if ( !userTaskTodos[task] ) {
                      userTaskTodos[task] = []
                    }
                    let values = {
                      user: (equip.equipier ? parseInt(equip.equipier) : null),
                      duration: equip.duree,
                      isTeammate: equip.coequipier
                    }
                    if ( equip.heure ) { // On enregistre l'heure de départ que si elle est définit (null non accepté par l'API)
                      values.teammateStartAt = equip.heure+(!equip.heure.includes("+") ? '+00:00' : '')
                    }
                    userTaskTodos[task].push({
                      id: equip.user_id,
                      values: values
                    })
                  }
                })
              }
              const taskToSave = {
                name: ctask.precision,
                startAt: ctask.date+(!ctask.date.includes("+") ? '+00:00' : ''),
                endAt: ctask.date+(!ctask.date.includes("+") ? '+00:00' : ''),
                checkedEvent: false,
                // location: parseInt(id_location),
                taskEvent: ctask.id_event,
                taskState: ctask.id_state,
                ticket: ctask.ticket,
                taskType: null, // ctask.id_type,
                inventory: null
              }

              if ( ctask.id ) { // Si task.id défini, on update la tâche
                taskToSave.id = ctask.id
                tasksToUpdate[task] = taskToSave
              }
              else { // Sinon, on crée la tâche
                tasksToCreate[task] = taskToSave
              }
            }
          }
        break;

        case 'level':
          const level = datas[key]
          if ( compareDatas[key] !== level ) {
            datasToSave['level'] = level
            // datasToSave['state'] = datas.state
          }
        break;

        case 'new_comment':
          const new_comment = datas[key]
          if ( compareDatas[key] !== new_comment ) {
            commentToSave = {
              content: new_comment,
              ticket: datas.id
            }
          }
        break;
      }
    }


    // Sauvegarde les différents éléments
    const getSaveInfos = await saveAll(datasToSave, commentToSave, tasksToCreate, tasksToUpdate, userTaskTodos)

    if ( getSaveInfos.error ) {
      setErrorSaving(getSaveInfos)
      props.setGotoUrl(null)
      setIsSaving(false)

      // Scroll to top
      if ( props.fromPlanningBoard ) {
        document.getElementById('popin-content').scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
      else {
        window.scrollTo({
          top: document.getElementsByClassName('header')[0].offsetHeight+(document.getElementsByClassName('admin_reservation').length ? document.getElementsByClassName('admin_reservation')[0].offsetHeight : 0),
          behavior: 'smooth'
        })
      }
    }
    else {
      setMessageSaving(getSaveInfos)
      setIsSaving(false)

      if ( props.fromPlanningBoard ) {
        props.setIsSaved(true)
      }

      if ( props.gotoUrl ) {
        props.saveBeforeContinue()
      }
      else {
        setTimeout(function(){
          props.setHasChanges(false)
          setTimeout(function(){
            setMessageSaving('')
          }, 600)
        }, 1000)
      }
    }
  }


  // Fonction regroupant toutes les routes de sauvegardes
  const saveAll = async (datasToSave, commentToSave, tasksToCreate, tasksToUpdate, userTaskTodos) => {
    let newdatas = datas
    let create_task = null
    let update_task = null
    let save_user_task = null
    let delete_user_task = null
    let save_comment = null
    let save_datas = null
    let getErrors = {
      error: false,
      message: '',
      errors: []
    }

    // Sauve les tâches
    if ( tasksToCreate.length ) { // Création de tâches
       await Promise.all(
         tasksToCreate.map(async (taskToCreate, i) => {
          create_task = await saveTask('/task-todos', {method: 'POST'}, taskToCreate)
          taskToCreate.id = create_task.id

          if ( create_task && create_task.error ) { // Si erreur
            getErrors.error = true
            getErrors.message = create_task.message
            if ( create_task.errors ) {
              create_task.errors.forEach((error, i) => {
                getErrors.errors.push(taskEvents[taskToCreate.taskEvent]+' : '+error)
              })
            }
          }
          else {
            // Met à jour les ids des tâches
            newdatas.tasks[i].id = create_task.id

            // Equipiers
            if ( userTaskTodos[i] ) { // Mise à jour des équipiers
              await Promise.all(
                userTaskTodos[i].map(async (userTask, j) => {
                  if ( userTask && userTask.values.user ) { // Edition ou Création
                    userTask.values.taskTodo = taskToCreate.id
                    const user_task_id = userTask.id // Vérifie si l'équipier existe déjà
                    save_user_task = await saveUserTask('/user-task-todos'+(user_task_id ? '/'+user_task_id : ''), {method: (user_task_id ? 'PUT' : 'POST')}, userTask.values)
                    if ( save_user_task && save_user_task.error ) { // Si erreur
                      getErrors.error = true
                      getErrors.message = save_user_task.message
                      if ( save_user_task.errors ) {
                        save_user_task.errors.forEach((error, i) => {
                          getErrors.errors.push(taskEvents[taskToCreate.taskEvent]+' : '+error)
                        })
                      }
                    }
                    else { // Sinon succès
                      newdatas.tasks[i].equipiers[j].user_id = parseInt(save_user_task.id)
                    }
                  }
                })
              )
            }
          }
        })
      )
    }

    if ( tasksToUpdate.length ) { // Mise à jour de tâches
      await Promise.all(
        tasksToUpdate.map(async (taskToUpdate, i) => {
          const task_id = taskToUpdate.id
          update_task = await saveTask('/task-todos/'+task_id, {method: 'PUT'}, taskToUpdate)
          if ( update_task && update_task.error ) { // Si erreur
            getErrors.error = true
            getErrors.message = update_task.message
            if ( update_task.errors ) {
              update_task.errors.forEach((error, i) => {
                getErrors.errors.push(taskEvents[taskToUpdate.taskEvent]+' : '+error)
              })
            }
          }
          else if ( userTaskTodos[i] ) { // Mise à jour des équipiers
            await Promise.all(
              userTaskTodos[i].map(async (userTask, j) => {
                userTask.values.taskTodo = taskToUpdate.id
                const user_task_id = userTask.id // Vérifie si l'équipier existe déjà
                if ( user_task_id && !userTask.values.user ) { // Suppression
                  delete_user_task = await deleteUserTask('/user-task-todos/'+user_task_id, {method: 'DELETE'})
                }
                else if ( userTask.values.user ) { // Edition ou Création
                  save_user_task = await saveUserTask('/user-task-todos'+(user_task_id ? '/'+user_task_id : ''), {method: (user_task_id ? 'PUT' : 'POST')}, userTask.values)
                  if ( save_user_task && save_user_task.error ) { // Si erreur
                    getErrors.error = true
                    getErrors.message = save_user_task.message
                    if ( save_user_task.errors ) {
                      save_user_task.errors.forEach((error, i) => {
                        getErrors.errors.push(taskEvents[taskToUpdate.taskEvent]+' : '+error)
                      })
                    }
                  }
                  else { // Sinon succès
                    newdatas.tasks[i].equipiers[j].user_id = parseInt(save_user_task.id)
                  }
                }
              })
            )
          }
        })
      )
    }

    // Sauve les services
    if ( Object.keys(commentToSave).length ) {
      save_comment = await saveDatas('/ticket-comments', {}, commentToSave)
      if ( save_comment && save_comment.error ) { // Si erreur
        getErrors.error = true
        getErrors.message = save_comment.message
        if ( save_comment.errors ) {
          save_comment.errors.forEach((error, i) => {
            getErrors.errors.push(error)
          })
        }
      }
    }

    // Sauve les données
    if ( Object.keys(datasToSave).length ) {
      save_datas = await saveDatas('/tickets/'+datas.id, {method: 'PUT'}, datasToSave)
      if ( save_datas && save_datas.error ) { // Si erreur
        getErrors.error = true
        getErrors.message = save_datas.message
        if ( save_datas.errors ) {
          save_datas.errors.forEach((error, i) => {
            getErrors.errors.push(error)
          })
        }
      }
    }

    if ( getErrors.error ) {
      return getErrors
    }
    else {
      setToggleRefresh((toggleRefresh ? false : true))
      return 'Le ticket a bien été mise à jour.'
    }
  }

  const changeState = async () => {
    // State "Traité" => 1
    const close_ticket = await saveTask('/tickets/'+datas.id, {method: 'PUT'}, {state: 1})
    if ( close_ticket.error ) {
      const getErrors = {}
      getErrors.error = true
      getErrors.message = close_ticket.message
      if ( close_ticket.errors ) {
        close_ticket.errors.forEach((error, i) => {
          getErrors.errors.push(error)
        })
      }
      setErrorSaving(getErrors)
      setIsSaving(false)

      // Scroll to top
      if ( props.fromPlanningBoard ) {
        document.getElementById('popin-content').scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
      else {
        window.scrollTo({
          top: document.getElementsByClassName('header')[0].offsetHeight,
          behavior: 'smooth'
        })
      }
    }
    else {
      setMessageSaving('Le ticket a bien été traité.')
      setIsSaving(false)
      setTraiteTicket(true)
      if ( props.fromPlanningBoard ) {
        props.closePopinTasks()
      }
      else {
        props.history.go(-1) // Retourne automatiquement à l'écran précédent
      }
    }
  }

  const closeTicket = async () => {
    // State "Cloturé" => 3

    // Si on est sur une intervention sur le ticket
    if ( id_task ) {
      // S'il y a suffisamment de fichiers, on sauvegarde
      const close_task = await saveTask('/task-todos/'+id_task, {method: 'PUT'}, {checkedEvent: true})
      if ( close_task.error ) {
        const getErrors = {}
        getErrors.error = true
        getErrors.message = close_task.message
        if ( close_task.errors ) {
          close_task.errors.forEach((error, i) => {
            getErrors.errors.push(error)
          })
        }
        setErrorSaving(getErrors)
        setIsSaving(false)

        // Scroll to top
        if ( props.fromPlanningBoard ) {
          document.getElementById('popin-content').scrollTo({
            top: 0,
            behavior: 'smooth'
          })
        }
        else {
          window.scrollTo({
            top: document.getElementsByClassName('header')[0].offsetHeight,
            behavior: 'smooth'
          })
        }
      }
      else {
        setMessageSaving('La tâche a bien été clôturée.')
        setIsSaving(false)
        setClosedTicket(true)
        if ( props.fromPlanningBoard ) {
          props.closePopinTasks()
        }
        else {
          props.history.go(-1) // Retourne automatiquement à l'écran précédent
        }
      }
    }
    else {
      // Si tâche pas intervention, on vérifie au préalable s'il y a au moins 2 fichiers d'uploadés sur la tâche
      const close_ticket = await saveTask('/tickets/'+datas.id, {method: 'PUT'}, {state: 3})
      if ( close_ticket.error ) {
        const getErrors = {}
        getErrors.error = true
        getErrors.message = close_ticket.message
        if ( close_ticket.errors ) {
          close_ticket.errors.forEach((error, i) => {
            getErrors.errors.push(error)
          })
        }
        setErrorSaving(getErrors)
        setIsSaving(false)

        // Scroll to top
        if ( props.fromPlanningBoard ) {
          document.getElementById('popin-content').scrollTo({
            top: 0,
            behavior: 'smooth'
          })
        }
        else {
          window.scrollTo({
            top: document.getElementsByClassName('header')[0].offsetHeight,
            behavior: 'smooth'
          })
        }
      }
      else {
        setMessageSaving('Le ticket a bien été clôturé.')
        setIsSaving(false)
        setClosedTicket(true)
        if ( props.fromPlanningBoard ) {
          props.closePopinTasks()
        }
        else {
          props.history.go(-1) // Retourne automatiquement à l'écran précédent
        }
      }
    }
  }

  const uncloseTicket = async () => {
    // Si on est sur une intervention sur le ticket
    if ( id_task ) {
      // On décloture la tâche
      const unclose_task = await saveTask('/task-todos/'+id_task, {method: 'PUT'}, {checkedEvent: false})
      if ( unclose_task.error ) {
        const getErrors = {}
        getErrors.error = true
        getErrors.message = unclose_task.message
        if ( unclose_task.errors ) {
          unclose_task.errors.forEach((error, i) => {
            getErrors.errors.push(error)
          })
        }
        setErrorSaving(getErrors)
        setIsSaving(false)

        // Scroll to top
        if ( props.fromPlanningBoard ) {
          document.getElementById('popin-content').scrollTo({
            top: 0,
            behavior: 'smooth'
          })
        }
        else {
          window.scrollTo({
            top: document.getElementsByClassName('header')[0].offsetHeight,
            behavior: 'smooth'
          })
        }
      }
      else {
        setIsSaving(false)
        setClosedTicket(false)

        // Compare l'objet initial à l'objet à enregistrer
        updateChanges(datas, true)
      }
    }
    else {
      // On décloture la tâche
      const unclose_ticket = await saveTask('/tickets/'+id_ticket, {method: 'PUT'}, {checkedEvent: false})
      if ( unclose_ticket.error ) {
        const getErrors = {}
        getErrors.error = true
        getErrors.message = unclose_ticket.message
        if ( unclose_ticket.errors ) {
          unclose_ticket.errors.forEach((error, i) => {
            getErrors.errors.push(error)
          })
        }
        setErrorSaving(getErrors)
        setIsSaving(false)

        // Scroll to top
        if ( props.fromPlanningBoard ) {
          document.getElementById('popin-content').scrollTo({
            top: 0,
            behavior: 'smooth'
          })
        }
        else {
          window.scrollTo({
            top: document.getElementsByClassName('header')[0].offsetHeight,
            behavior: 'smooth'
          })
        }
      }
      else {
        setIsSaving(false)
        setClosedTicket(false)

        // Compare l'objet initial à l'objet à enregistrer
        updateChanges(datas, true)
      }
    }
  }

  const popupFile = (index, src_file) => {
    var img = new Image
    img.src = src_file
    img.onload = function() {
      const window_width = window.innerWidth-60 // marges: -60
      const window_height = window.innerHeight-120 // header: -60 marges: -60

      let width = 0
      let height = 0

      // Si largeur > hauteur, on se base sur la largeur
      if ( img.width > img.height ) {
        width = window_width
        height = parseInt(img.height * width / img.width)

        if ( height > window_height ) {
          width = width * window_height / height
          height = window_height
        }
      }
      // Sinon, hauteur > largeur, on se base sur la hauteur
      else {
        height = window_height
        width = parseInt(img.width * height / img.height)

        // Si l'image est trop grande par rapport à l'écran
        if ( width > window_width ) {
          height = height * window_width / width
          width = window_width
        }
      }

      setBigFile({
        src: img.src,
        width: width,
        height: height
      })
    }
  }
  const closePopup = () => {
    setBigFile("")
  }


  // Supprime une tâche intervention
  const deleteTaskFromPopin = async () => {
    // Si tâche intervention
    if ( deleteTaskPopin ) {
      const delete_task = await deleteTask('/task-todos/'+deleteTaskPopin, {method: 'DELETE'})
      if ( delete_task.error ) {
        const getErrors = {}
        getErrors.error = true
        getErrors.message = delete_task.message
        if ( delete_task.errors ) {
          delete_task.errors.forEach((error, i) => {
            getErrors.errors.push(error)
          })
        }
        setErrorSaving(getErrors)
        setIsSaving(false)

        // Scroll to top
        if ( props.fromPlanningBoard ) {
          document.getElementById('popin-content').scrollTo({
            top: 0,
            behavior: 'smooth'
          })
        }
        else {
          window.scrollTo({
            top: document.getElementsByClassName('header')[0].offsetHeight,
            behavior: 'smooth'
          })
        }
      }
      else if ( parseInt(id_task) === deleteTaskPopin ) { // Si on est sur l'écran de la tâche
        if ( props.fromPlanningBoard ) {
          props.closePopinTasks()
        }
        else {
          props.history.go(-1) // Retourne automatiquement à l'écran précédent
        }
      }
      else {
        // Supprime la tâche du tableau de tasks
        let taskIndex = datas.tasks.findIndex((task) => task.id === deleteTaskPopin)
        datas.tasks.splice(taskIndex, 1)
        setDatas({...datas})

        // Ferme la popin
        setDeleteTaskPopin(null)
      }
    }
  }

  return (
    <div className={`content hasloader
                     ${isLoading ? 'loadin' : ''}
                     ${(props.hasChanges && !closedTicket) ? 'has-changes' : ''}
                     ${props.gotoUrl && !closedTicket ? 'full-changes' : ''}`}>
      {!isLoading &&
        <>
          {isError // Si pas de résultats de la route
          ?
            <NotFound />
          :
          <>
            {(isManager || isConcierge) &&
              <div className="admin_links">
                <NavLink exact to={`/logement/${datas.id_renting}/`} className="admin_link">
                  Voir les réservations
                </NavLink>
              </div>
            }

            {errorSaving &&
              <div className="return error">
                <div className="innerMediumWidth">
                  {errorSaving.message && <span>{errorSaving.message}</span>}
                  {errorSaving.errors &&
                    <ul>
                      {errorSaving.errors.map((error, i) => {
                        return <li key={i}>{error}</li>
                      })}
                    </ul>
                  }
                </div>
              </div>
            }

            {(datas && (isManager || isConcierge)) &&
              <div className={`tasks-editor`}>
                <div className="innerMediumWidth">
                  {datas.tasks.map((data, index) => {
                      return (
                        <TaskReservation key={index}
                                         index={index}
                                         datas={data}
                                         listEquipiers={listEquipiers}
                                         durees={durees}
                                         changeInput={changeTaskInput}
                                         changeEquipierInput={changeTaskEquipierInput}
                                         changeEquipierTime={changeTaskEquipierTime}
                                         setDeleteTaskPopin={setDeleteTaskPopin}
                                         changeDate={changeTaskDate}
                                         changeTime={changeTaskTime}
                                         addEquipier={addEquipier} />
                      )
                    })
                  }
                  {datas.tasks.length === 0 &&
                    <div className="ajout-intervention">
                      <button type="button" className="link-icon" onClick={() => addTask()}>
                        <span>
                          Ajouter une intervention
                          <Icon icon="plus" />
                        </span>
                      </button>
                    </div>
                  }
                </div>
              </div>
            }

            <div className="innerMediumWidth">
              <div className="address">
                <p>
                  {datas.contents.adresse}
                  {(datas.contents.adresse2) && <><br />{datas.contents.adresse2}</>}
                  {(datas.contents.ville) && <><br />{datas.contents.cp} {datas.contents.ville}</>}
                </p>
                <div className="buttons">
                  <GPSUrl
                    adresse={(datas.contents.adresse ? datas.contents.adresse : '')
                             + (datas.contents.adresse2 ? ' '+datas.contents.adresse2 : '')
                             + (datas.contents.cp ? ' '+datas.contents.cp : '')
                             + (datas.contents.ville ? ' '+datas.contents.ville : '')}
                    lat={datas && datas.contents.lat}
                    lng={datas && datas.contents.lng}
                    label='GPS' />

                  {datas.contents.book &&
                    <a href={datas.contents.book} target="_blank" rel="noreferrer" className="btn btn-icon">
                      <span>
                        Book
                        <Icon icon='book' />
                      </span>
                    </a>
                  }
                </div>
              </div>

              {(id_task && datas.tasks.length > 0) &&
                <div className="intervention">
                  <div className="horaire">
                    <span>Intervention prévue le {formatDateString(datas.tasks[0] ? datas.tasks[0].date : datas.contents.date)} à</span>
                    <TimePickerCustom
                      time={(datas.tasks[0] ? getFrenchDate(datas.tasks[0].date) : null)}
                      disabled={(!isManager && isCoequipier) ? true : false}
                      onChange={changeTimeIntervention}
                      minDateMessage={timeMinDateMessage}
                      invalidDateMessage={timeInvalidDateMessage}
                    />
                  </div>
                  {equipierIndex !== null &&
                    <div className="horaire">
                      <span>
                        Temps prévu pour votre intervention :{(datas.tasks[0].equipiers[equipierIndex].duree_string ? ' '+datas.tasks[0].equipiers[equipierIndex].duree_string : '')} à partir de
                      </span>
                      <TimePickerCustom
                        time={getFrenchDate((datas.tasks[0].equipiers[equipierIndex].heure ? datas.tasks[0].equipiers[equipierIndex].heure : null))}
                        className="heure_equipier"
                        onChange={(e) => changeTaskEquipierTime(0, equipierIndex, e)}
                        minDateMessage={timeMinDateMessage}
                        invalidDateMessage={timeInvalidDateMessage}
                      />
                    </div>
                  }
                  {
                  datas.contents.coequipiers.length
                  ?
                    <div className="coequipiers">
                      <strong>Intervention connexe {(datas.tasks[0].id_event === 3 ? " :" : datas.tasks[0].name.toLowerCase()+' : '+datas.tasks[0].precision)}</strong>
                      <span>{isCoequipier ? "Vous êtes en appui de" : "Vos co-équipiers"} : {}</span>
                      <ul>
                        {
                          datas.contents.coequipiers.map((coequipier, i) => {
                            return <li key={i}>
                              <span>{coequipier.nom} ({coequipier.isCoequipier ? 'coéquipier' : 'équipier'}) - Temps prévu : {coequipier.duree}{coequipier.heure_debut ? ' à partir de '+coequipier.heure_debut : ''}</span>
                              <span><a href={`tel:${coequipier.tel}`}>{coequipier.tel}</a> - <a href={`mailto:${coequipier.mail}`}>{coequipier.mail}</a></span>
                            </li>
                          })
                        }
                      </ul>
                    </div>
                  :
                    ''
                  }
                </div>
              }
            </div>

            <div className='innerSmallWidth'>
              <div className='resume'>
                <em>Signalement saisi le {formatDateString(datas.contents.ticket_date)} par {datas.contents.ticket_username}</em>
                <p>{datas.contents.ticket_resume}</p>
                {images &&
                  <div className='images'>
                    {images.map((image, i) => {
                      return <img key={i} src={image} width='200' height='200' alt='' onClick={() => popupFile(i, image)} />
                    })}

                    <div className={`popup_file ${bigFile ? "show" : ""}`}>
                      <div className="popin_file">
                        {bigFile &&
                          <img src={bigFile.src} width={bigFile.width} height={bigFile.height} alt="" />
                        }

                        <button className="close" onClick={closePopup}>
                          <Icon icon="close" />
                        </button>
                      </div>
                      <div className="popout" onClick={closePopup}></div>
                    </div>
                  </div>
                }
              </div>

              <div className='comments'>
                {datas.contents.comments.length > 0 &&
                  <div className='list'>
                    {datas.contents.comments.map((comment, i) => {
                      return <div key={i} className="comment">
                               <em>{comment.username}, le {comment.date}</em>
                               <p>{comment.content}</p>
                             </div>
                    })}
                  </div>
                }
              </div>

              <div className='new_comment comment'>
                <label htmlFor='new_comment'>Ajouter un commentaire</label>
                <textarea id='new_comment'
                  name='new_comment'
                  placeholder=''
                  value={datas.new_comment}
                  rows='6'
                  onChange={changeInput}
                  maxLength={1000}
                  />
                <span className="counter">{commentLength} / 1000</span>
              </div>

              <div className="edit_level">
                <label htmlFor="level">Modifier la priorité</label>
                <select id="level" name="level"
                        onChange={changeInput}
                        value={(datas.level ? datas.level : '')}>
                  {ticketLevels.length &&
                    ticketLevels.map((ticketLevel, index) => {
                      return (
                        <option key={index} value={ticketLevel.id}>
                          {ticketLevel.label}
                        </option>
                      )
                    })
                  }
                </select>
              </div>
            </div>

            <div className={`cloture task`}>
              {(!id_task && traiteTicket && !closedTicket) &&
                <span>Ticket traité.</span>
              }

              {closedTicket &&
                <span>{id_task ? 'Mission réalisée.' : 'Ticket clôturé.'}</span>
              }

              {((!id_task && !traiteTicket) || !closedTicket) &&
                <div className="btns">
                  {(!id_task && !traiteTicket) &&
                    <button type="button" className="btn btn-icon" onClick={changeState}>
                      <span>
                        Traité
                        <Icon icon='check' />
                      </span>
                    </button>
                  }
                  {(!closedTicket && (id_task || (!id_task && isManager))) &&
                    <button type="button" className="btn btn-icon" onClick={closeTicket}>
                      <span>
                        <>{id_task ? 'Mission réalisée' : 'Ticket clôturé'}</>
                        <Icon icon='check' />
                      </span>
                    </button>
                  }
                </div>
              }

              {(isManager && id_task && closedTicket) &&
                <div className="btns">
                  <button type="button" className="btn btn-icon" onClick={uncloseTicket}>
                    <span>
                      Ré-ouvrir cette tâche
                      <Icon icon='check' />
                    </span>
                  </button>
                </div>
              }
            </div>
          </>
        }
      </>
      }

      {!closedTicket &&
        <div className={`fix-save ${messageSaving ? 'succes' : ''} ${isSaving ? 'savin' : ''}`}>
          <p>Attention, vous n'avez pas sauvegardé vos modifications !</p>

          <div className='content'>
            <button type="button" className="btn btn-icon" onClick={submitDatas}>
              <span>
                Enregistrer les modifications
                <Icon icon='fleche' />
              </span>
            </button>

            <div className="return">
              {messageSaving && <span>{messageSaving}</span>}
            </div>
          </div>

          <button type="button" className="link" onClick={props.continueWithoutSaving}>Poursuivre votre navigation sans enregistrer</button>
        </div>
      }

      <Loader />

      <div className={`popin popin-delete-task ${deleteTaskPopin ? 'active' : ''}`}>
        <div className="popin-container">
          <p><strong>Confirmez-vous la suppression de cette tâche ?</strong></p>
          <div className="btns">
            <button className="btn" type="button" onClick={deleteTaskFromPopin}>Oui</button>
            <button className="btn" type="button" onClick={() => setDeleteTaskPopin(null)}>Non</button>
          </div>
        </div>
        <div className="popin-overlay"></div>
      </div>
    </div>
  )
}
