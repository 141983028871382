import { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'

function useBlockHistory() {
  let [hasChanges, setHasChanges] = useState(false)
  let [gotoUrl, setGotoUrl] = useState(null)
  const unblock = useRef()
  const history = useHistory()

  useEffect(() => {
    if (hasChanges) {
      unblock.current = history.block((location, action) => {
        setGotoUrl(location)
        return false
      })
    }
    else if ( gotoUrl ) {
      unblock.current = null
      history.push(gotoUrl.pathname)
      // return true
    }
    return () => {
      if (unblock.current) {
        unblock.current()
      }
    }
  }, [hasChanges, gotoUrl, history])

  const saveBeforeContinue = () => {
    if ( gotoUrl ) {
      history.push(gotoUrl.pathname)
    }
    setHasChanges(false)
  }
  const continueWithoutSaving = () => {
    history.push(gotoUrl.pathname)
    setHasChanges(false)
  }

  return {
    hasChanges,
    setHasChanges,
    gotoUrl,
    setGotoUrl,
    saveBeforeContinue,
    continueWithoutSaving
  }
}

export default useBlockHistory
