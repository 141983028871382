import { useState } from 'react'
import useUser from './useUser'

const useLoadDatas = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  // const [data, setData] = useState(false)
  const { user, checkUser } = useUser()

  // const [state, dispatch] = useReducer(dataFetchReducer, {
  //   isLoading: false,
  //   isError: false,
  //   data: initialData
  // })

  const fetchData = async (route, user_token, abortController, force_admin=false) => {
    if ( !route ) return

    setIsError(false)
    setIsLoading(true)

    let token = user_token
    // S'il n'y a pas de token défini dans la fonction
    // Ou si le token n'est pas bon
    // Si Admin token
    if ( !force_admin && user && localStorage.getItem('admin_user') ) {
      token = JSON.parse(localStorage.getItem('admin_user')).token
    }
    // Sinon on utilise le token de l'utilisateur
    else if ( user && token !== user.token ) {
      token = await checkUser(user)
    }

    const fetchHeaders = new Headers()
    fetchHeaders.append("Content-Type", "application/json")
    fetchHeaders.append("Authorization", "Bearer "+token)

    return await fetch(process.env.REACT_APP_API+route, {
      method: 'GET',
      headers: fetchHeaders,
      mode: 'cors',
      signal: (abortController ? abortController.signal : null)
    })
    .then(response => {
      return {
        ok: response.ok,
        api: response.json()
      }
    })
    .then(json_datas => {
      setIsLoading(false)
      return json_datas.api.then(function(result) {
        if ( json_datas.ok ) { // Si succès
          return result
        }
        else { // Si erreur
          let errors = []
          if ( result.violations ) {
            result.violations.forEach((violation, i) => {
              errors.push(violation.message)
            })
            return {
              error: true,
              message: "Veuillez ajuster les erreurs suivantes :",
              errors: errors
            }
          }
          else {
            return {
              error: true,
              message: "Le chargement des données a rencontré un problème."
            }
          }
        }
      })
    })
    .catch(error => {
      setIsLoading(false)
      setIsError(true)
      return {
        error: true,
        message: "Erreur d'API."
      }
    })
  }

  // Unmount
  return [fetchData, isLoading, isError]
}

export default useLoadDatas
