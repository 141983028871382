import useUser from './useUser'

const useDeleteDatas = () => {
  const { user, checkUser  } = useUser()

  const deleteDatas = async (route, options) => {
    if ( !route ) return

    const token = await checkUser(user)

    const fetchHeaders = new Headers()
    fetchHeaders.append("Accept", "application/json")
    fetchHeaders.append("Content-Type", "application/json")
    fetchHeaders.append("Authorization", "Bearer "+token)

    return await fetch(process.env.REACT_APP_API+route, {
      method: 'DELETE',
      headers: fetchHeaders
    })
  }

  // Unmount
  return [deleteDatas]
}

export default useDeleteDatas
