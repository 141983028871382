import React, { useState } from 'react'
// import { useHistory } from 'react-router-dom'
import Loader from './Loader'
import useForm from '../hooks/useForm'
import Icon from '../components/Icon'

export default function ForgotPassword(props) {
  // const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const { setErrors, showFields, getFields,
          setSuccess, showReturnMessage } = useForm([
    {
      name: "email",
      type: "email",
      label: "Votre email",
      placeholder: "Saisissez votre email",
      error: "Votre email",
      required: true,
      required_format: "email",
      error_format: "Le format de votre email n'est pas correct",
      value: ""
    }
  ])

  const handleSubmit = async e => {
    e.preventDefault()

    // Réinitialise avant traitement
    setIsLoading(true)
    setErrors({})

    // Traitement
    const parameters = getFields(e.target)
    if ( Object.keys(parameters.errors).length > 0 ) {
      setIsLoading(false)
      setErrors({
        message: "Veuillez compléter ou corriger les champs suivants :",
        fields: parameters.errors
      })
    }
    else {
      await askPassword(parameters.values)
      setIsLoading(false)
    }
  }

  async function askPassword(parameters) {
    const fetchHeaders = new Headers()
    fetchHeaders.append("Content-Type", "application/json")

    return fetch(process.env.REACT_APP_API+'/users/forgot-password', {
      method: 'POST',
      headers: fetchHeaders,
      body: JSON.stringify(parameters)
    })
    .then(response => {
      setIsLoading(false)
      if ( response.ok ) { // Si succès
        return response.json()
      }
      else { // Sinon erreur
        setErrors({
          message: "Erreur de formulaire"
        })
        return false;
      }
    })
    .then(json_datas => {
      if ( json_datas ) {
        setSuccess({
          message: json_datas.message
        })
      }
    })
    .catch(error => {
      setIsLoading(false)
      setErrors({
        message: "Erreur de formulaire"
      })
    })
  }

  return (
    <div className="popin active">
      <div className="popin-container">
        <h2>Vous avez oublié votre mot de passe ?</h2>
        <p>Saisissez votre adresse email et vous recevrez un email pour regénérer votre mot de passe.</p>
        <form id="forgotPassForm"
              className={`form hasloader ${isLoading ? 'loadin' : ''}`}
              onSubmit={handleSubmit}>

          { showReturnMessage() }

          { showFields() }

          <div className="form-submit">
            <button type="submit" className="btn">Demander</button>
          </div>

          <Loader />
        </form>

        <button className="btn-close" type="button" onClick={() => props.onClose(false)}>
          <Icon icon='close' title="Fermer" />
          </button>
      </div>
      <div className="popin-overlay" onClick={() => props.onClose(false)}></div>
    </div>
  )
}
