// Librairies React
import React from 'react'
import TimePickerCustom from '../components/TimePickerCustom'
import Icon from '../components/Icon'

// Composant
export default function TaskReservationEquipier(props) {
  return (
    <div className="equipier">
      <div className="attribution">
        <label htmlFor={`equipier_${props.task_index}_${props.index}`}>{props.datas.coequipier ? 'Co-équipier' : 'Équipier'}</label>
        <select id={`equipier_${props.task_index}_${props.index}`} name={`equipier_${props.task_index}_${props.index}`} className="select-equipier"
                value={props.datas.equipier ? props.datas.equipier : ''}
                onChange={(e) => props.changeInput(e)}>
          <option value="">Non attribué</option>
          {props.listTaskEquipiers.length &&
            props.listTaskEquipiers.map((equip, index) => {
              return (
                <option key={index} value={equip.id}>
                  {equip.fav && <>⭑</>}
                  {equip.name} {equip.indispo && <>(indisponible)</>}
                </option>
              )
            })
          }
        </select>
      </div>
      {props.datas.coequipier &&
        <div className="datetime">
          <label htmlFor={`time_${props.task_index}_${props.index}`}>Heure</label>
          <TimePickerCustom
            time={props.datas.heure}
            onChange={(date) => props.changeTime(props.task_index, props.index, date)}
          />
        </div>
      }
      <div className="duree">
        <label htmlFor={`duree_${props.task_index}_${props.index}`}>Durée</label>
        <select id={`duree_${props.task_index}_${props.index}`} name={`duree_${props.task_index}_${props.index}`} className="select-duree"
                defaultValue={props.datas.duree}
                onChange={(e) => props.changeInput(e)}>
          <option value="">À fixer</option>
          {props.durees.length &&
            props.durees.map((duree, index) => {
              return (
                <option key={index} value={duree.value}>
                  {duree.label}
                </option>
              )
            })
          }
        </select>
      </div>
    </div>
  )
}
