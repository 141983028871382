import { useState, useLayoutEffect } from 'react'

export default function useScroll(target_id, header_id) {
  const [isFixed, setIsFixed] = useState(false)
  const [scrollBascule, setScrollBascule] = useState(null) // Limite de bascule
  const handleScroll = (e) => {
    if ( scrollBascule === null ) {
      // Si bascule non définie, on la définit
      // Se fait au 1er appel de la fonction uniquement
      let bascule = (target_id ? document.getElementById(target_id).offsetTop : 0)
      if ( header_id ) {
        bascule -= document.getElementById(header_id).offsetHeight
      }
      setScrollBascule(bascule)
    }
    else {
      // Si bascule définie, on vérifie la position par rapport à la limite du scroll
      if ( !isFixed && (scrollBascule <= window.scrollY) ) {
        setIsFixed(true)
      }
      else if ( isFixed && (scrollBascule > window.scrollY) ) {
        setIsFixed(false)
      }
    }
  }
  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll)

    // Unmount
    return () => window.removeEventListener('scroll', handleScroll)
  }, [scrollBascule, isFixed])

  return isFixed
}
