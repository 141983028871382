import React, {useCallback, useEffect, useRef, useState} from 'react';
import {createPortal, unstable_batchedUpdates} from 'react-dom';
import {
  CancelDrop,
  closestCenter,
  pointerWithin,
  rectIntersection,
  CollisionDetection,
  DndContext,
  DragOverlay,
  DropAnimation,
  getFirstCollision,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  Modifiers,
  useDroppable,
  UniqueIdentifier,
  useSensors,
  useSensor,
  MeasuringStrategy,
  KeyboardCoordinateGetter,
  defaultDropAnimationSideEffects,
} from '@dnd-kit/core';
import {
  AnimateLayoutChanges,
  SortableContext,
  useSortable,
  arrayMove,
  defaultAnimateLayoutChanges,
  verticalListSortingStrategy,
  SortingStrategy,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {coordinateGetter as multipleContainersCoordinateGetter} from './components/multipleContainersKeyboardCoordinates';

import useSaveRenting from '../../hooks/useSaveDatas'
import useSaveGroup from '../../hooks/useSaveDatas'
import useDeleteGroup from '../../hooks/useDeleteDatas'
import {Item, Container, ContainerProps} from './components';

export default {
  title: 'Presets/Sortable/Multiple Containers',
};

const animateLayoutChanges: AnimateLayoutChanges = (args) =>
  defaultAnimateLayoutChanges({...args, wasDragging: true});

function DroppableContainer({
  children,
  columns = 1,
  disabled,
  id,
  classes,
  items,
  style,
  ...props
}: ContainerProps & {
  disabled?: boolean;
  id: UniqueIdentifier;
  items: UniqueIdentifier[];
  style?: React.CSSProperties;
}) {
  const {
    active,
    attributes,
    isDragging,
    listeners,
    over,
    setNodeRef,
    transition,
    transform,
  } = useSortable({
    id,
    data: {
      type: 'container',
      children: items,
    },
    animateLayoutChanges,
  });
  const isOverContainer = over
    ? (id === over.id && active?.data.current?.type !== 'container') ||
      Object.keys(items).includes(over.id)
    : false;

  return (
    <Container
      ref={disabled ? undefined : setNodeRef}
      classes={classes}
      style={{
        ...style,
        transition,
        transform: CSS.Translate.toString(transform),
        opacity: isDragging ? 0.5 : undefined,
      }}
      hover={isOverContainer}
      draggableContainer={props.draggableContainer}
      handleProps={{
        ...attributes,
        ...listeners,
      }}
      columns={columns}
      {...props}
    >
      {children}
    </Container>
  );
}

const dropAnimation: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.5',
      },
    },
  }),
};

const empty: UniqueIdentifier[] = [];

export function MultipleContainers({
  collapsible,
  adjustScale = false,
  itemCount = 3,
  cancelDrop,
  columns,
  datas,
  newGroup,
  handle = false,
  containerStyle,
  coordinateGetter = multipleContainersCoordinateGetter,
  getItemStyles = () => ({}),
  wrapperStyle = () => ({}),
  minimal = false,
  modifiers,
  renderItem,
  strategy = verticalListSortingStrategy,
  draggableContainer = true,
  trashable = false,
  vertical = false,
  scrollable,
  loadPopinTasks,
  orderedDatas
}) {

  const [items, setItems] = useState({});
  const [containers, setContainers] = useState([]);
  const [activeId, setActiveId] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const lastOverId = useRef(null);
  const recentlyMovedToNewContainer = useRef(false);
  const isSortingContainer = activeId ? containers.includes(activeId) : false;

  const [saveRenting] = useSaveRenting()
  const [saveGroup] = useSaveGroup()
  const [deleteGroup] = useDeleteGroup()

  useEffect(() => {
    setItems({...datas})
    setContainers([...Object.keys(datas)])
  }, [datas])


  /**
   * Custom collision detection strategy optimized for multiple containers
   *
   * - First, find any droppable containers intersecting with the pointer.
   * - If there are none, find intersecting containers with the active draggable.
   * - If there are no intersecting containers, return the last matched intersection
   *
   */
  const collisionDetectionStrategy: CollisionDetection = useCallback(
    (args) => {
      if (activeId && activeId in items) {
        return closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter(
            (container) => container.id in items
          ),
        });
      }

      // Start by finding any intersecting droppable
      const pointerIntersections = pointerWithin(args);
      const intersections =
        pointerIntersections.length > 0
          ? // If there are droppables intersecting with the pointer, return those
            pointerIntersections
          : rectIntersection(args);
      let overId = getFirstCollision(intersections, 'id');

      if (overId != null) {
        if (overId in items) {
          const containerItems = items[overId].items;

          // If a container is matched and it contains items (columns 'A', 'B', 'C')
          if (containerItems.length > 0) {
            // Return the closest droppable within that container
            overId = closestCenter({
              ...args,
              droppableContainers: args.droppableContainers.filter(
                (container) =>
                  container.id !== overId &&
                  containerItems.includes(container.id)
              ),
            })[0]?.id;
          }
        }

        lastOverId.current = overId;

        return [{id: overId}];
      }

      // When a draggable item moves to a new container, the layout may shift
      // and the `overId` may become `null`. We manually set the cached `lastOverId`
      // to the id of the draggable item that was moved to the new container, otherwise
      // the previous `overId` will be returned which can cause items to incorrectly shift positions
      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = activeId;
      }

      // If no droppable is matched, return the last match
      return lastOverId.current ? [{id: lastOverId.current}] : [];
    },
    [activeId, items]
  );
  const [clonedItems, setClonedItems] = useState(null);
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter,
    })
  );
  const findContainer = (id: UniqueIdentifier) => {
    if (id in items) {
      return id;
    }
    // return Object.keys(items).find((key) => items[key].includes(id));
    return Object.keys(items).find((key) => items[key].items.some(function(item) {
      return item.id === id
    }));
  };

  const getIndex = (id: UniqueIdentifier) => {
    const container = findContainer(id);

    if (!container) {
      return -1;
    }

    const index = items[container].items.indexOf(id);

    return index;
  };

  const onDragCancel = () => {
    if (clonedItems) {
      // Reset items to their original state in case items have been
      // Dragged across containers
      setItems(clonedItems);
    }

    setActiveId(null);
    setActiveItem(null);
    setClonedItems(null);
  };

  const addGroup = async (e) => {
    e.preventDefault()

    const group_name = e.target.name.value
    const add_group = await saveGroup('/renting-groups', {method: 'POST'}, {name: group_name, position: containers.length-1})
    if ( add_group && add_group.error ) { // Si erreur
      // Erreur
    }
    else {
      unstable_batchedUpdates(() => {
        setContainers((containers) => [...containers, add_group.id]);
        setItems((items) => ({
          ...items,
          [add_group.id]: {id:add_group.id, name:group_name, position: containers.length-1, items:[]}
        }));
      });

      // On vide le champ input
      e.target.name.value = ""
    }
  }


  function handleCollapse(containerID: UniqueIdentifier) {
    if ( items[containerID].classes !== 'collapsed' ) {
      items[containerID].classes = 'collapsed'
    }
    else {
      items[containerID].classes = ''
    }
    setItems({...items})
  }

  const handleGroupName = async (group_name, containerID) => {
    const edit_group = await saveGroup('/renting-groups/'+containerID.replace('c-', ''), {method: 'PUT'}, {name: group_name})
    if ( edit_group && edit_group.error ) { // Si erreur
      // Erreur
    }
  }


  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [items]);


  useEffect(() => {
    if ( Object.values(items).length ) {
      // Renvoie les données réordonnées
      orderedDatas({...items})
    }
  }, [items, containers]);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={collisionDetectionStrategy}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
      onDragStart={({active}) => {
        setActiveId(active.id)

        const activeContainer = findContainer(active.id)
        const activeIndex = items[activeContainer].items.findIndex((item) => item.id === active.id)
        setActiveItem(items[activeContainer].items[activeIndex]);

        setClonedItems(items);
      }}
      onDragOver={({active, over}) => {
        const overId = over?.id;

        if (overId == null || active.id in items) {
          return;
        }

        const overContainer = findContainer(overId);
        const activeContainer = findContainer(active.id);

        if (!overContainer || !activeContainer) {
          return;
        }

        if (activeContainer !== overContainer) {
          setItems((items) => {
            const activeItems = items[activeContainer].items;
            const activeIndex = activeItems.findIndex((item) => item.id === active.id);
            const overItems = items[overContainer].items;
            const overIndex = overItems.findIndex((item) => item.id === overId);

            let newIndex: number;

            if (overId in items) {
              newIndex = overItems.length + 1;
            } else {
              const isBelowOverItem =
                over &&
                active.rect.current.translated &&
                active.rect.current.translated.top > over.rect.top + over.rect.height;

              const modifier = isBelowOverItem ? 1 : 0;

              newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
            }

            recentlyMovedToNewContainer.current = true;

            return {
              ...items,
              [activeContainer]: {
                id: items[activeContainer].id,
                name: items[activeContainer].name,
                items: items[activeContainer].items.filter(
                  (item) => item.id !== active.id
                )
              },
              [overContainer]: {
                id: items[overContainer].id,
                name: items[overContainer].name,
                items: [
                  ...items[overContainer].items.slice(0, newIndex),
                  items[activeContainer].items[activeIndex],
                  ...items[overContainer].items.slice(
                    newIndex,
                    items[overContainer].items.length
                  ),
                ]
              }
            };
          });
        }
      }}
      onDragEnd={({active, over}) => {
        let activeIndex
        let overIndex
        if (active.id in items && over?.id) {
          setContainers((containers) => {
            activeIndex = containers.indexOf(active.id);
            overIndex = containers.indexOf(over.id);

            return arrayMove(containers, activeIndex, overIndex);
          });

        }

        const activeContainer = findContainer(active.id);

        if (!activeContainer) {
          setActiveId(null);
          setActiveItem(null);
          return;
        }

        const overId = over?.id;

        if (overId == null) {
          setActiveId(null);
          setActiveItem(null);
          return;
        }

        const overContainer = findContainer(overId);

        // Si container différent, on a changé de place un container
        if ( activeContainer !== overContainer ) {
          const attrs = {
            position: overIndex
          }
          const update_renting_group = saveRenting('/renting-groups/'+activeContainer.replace('c-', ''), {method: 'PUT'}, attrs)
          // if ( update_renting && update_renting.error ) { // Si erreur
          //   // Erreur
          // }
          // else {
          //   console.log(update_renting)
          // }
        }

        if (overContainer) {
          const activeIndex = items[activeContainer].items.findIndex((item) => item.id === active.id);
          const overIndex = items[overContainer].items.findIndex((item) => item.id === overId);

          // Si item différent, on a changé de place un item
          if (activeIndex !== overIndex) {
            setItems((items) => ({
              ...items,
              [overContainer]: {
                id: items[overContainer].id,
                name: items[overContainer].name,
                items: arrayMove(
                  items[overContainer].items,
                  activeIndex,
                  overIndex
                )
              }
            }));
          }

          // Enregistre le groupe du renting s'il est différent du précédent
          if ( active.id !== activeContainer ) {
            const attrs = {
              position: overIndex
            }
            if ( activeContainer !== 'non-classes' ) {
              attrs.group = activeContainer.replace('c-', '')
            }
            const update_renting = saveRenting('/rentings/'+active.id, {method: 'PUT'}, attrs)
            // if ( update_renting && update_renting.error ) { // Si erreur
            //   // Erreur
            // }
            // else {
            //   console.log(update_renting)
            // }
          }
        }

        setActiveId(null);
        setActiveItem(null);
      }}
      cancelDrop={cancelDrop}
      onDragCancel={onDragCancel}
      modifiers={modifiers}
    >
      <div className='planningContainer'>
        <SortableContext
          items={[...containers]}
          strategy={
            vertical
              ? verticalListSortingStrategy
              : horizontalListSortingStrategy
          }
        >
          {containers.map((containerIndex) => (
            <DroppableContainer
              key={containerIndex}
              id={items[containerIndex].id}
              label={minimal ? undefined : items[containerIndex].name}
              classes={items[containerIndex].classes}
              columns={columns}
              items={items[containerIndex]}
              scrollable={scrollable}
              draggableContainer={draggableContainer}
              style={containerStyle}
              onCollapse={() => handleCollapse(containerIndex)}
              onEditGroupName={(newName) => handleGroupName(newName, containerIndex)}
              onRemove={() => handleRemove(containerIndex)}
            >
              <SortableContext items={items[containerIndex].items} strategy={strategy}>
                {items[containerIndex].items.map((value, index) => {
                  return (
                    <SortableItem
                      disabled={isSortingContainer}
                      key={value.id}
                      id={value.id}
                      data={value}
                      index={index}
                      handle={handle}
                      style={getItemStyles}
                      wrapperStyle={wrapperStyle}
                      renderItem={renderItem}
                      containerId={containerIndex}
                      getIndex={getIndex}
                      loadPopinTasks={loadPopinTasks}
                    />
                  );
                })}
              </SortableContext>
            </DroppableContainer>
          ))}


          {newGroup &&
            <form className="formGroup" onSubmit={addGroup}>
              <input type="text" name="name" placeholder="Nom du groupe" />
              <button type="submit" className="btn">Ajouter un groupe</button>
            </form>
          }
        </SortableContext>
      </div>
      {createPortal(
        <DragOverlay adjustScale={adjustScale} dropAnimation={dropAnimation}>
          {activeId
            ? containers.includes(activeId)
              ? renderContainerDragOverlay(activeId)
              : renderSortableItemDragOverlay(activeId)
            : null}
        </DragOverlay>,
        document.body
      )}
    </DndContext>
  );

  function renderSortableItemDragOverlay(id: UniqueIdentifier) {
    if ( !activeItem ) return;

    return (
      <Item
        value={id}
        data={activeItem}
        handle={handle}
        style={getItemStyles({
          containerId: findContainer(id),
          overIndex: -1,
          index: getIndex(id),
          value: id,
          isSorting: true,
          isDragging: true,
          isDragOverlay: true,
        })}
        wrapperStyle={wrapperStyle({index: 0})}
        renderItem={renderItem}
        dragOverlay
      />
    );
  }

  function renderContainerDragOverlay(containerId: UniqueIdentifier) {
    return (
      <Container
        label={items[containerId].name}
        classes={items[containerId].classes}
        columns={columns}
        draggableContainer={true}
        style={{
          height: '100%',
        }}
        shadow
      >
        {items[containerId].items.map((item, index) => (
          <Item
            key={item.id}
            value={item.id}
            data={item}
            handle={handle}
            style={getItemStyles({
              containerId,
              overIndex: -1,
              index: getIndex(item.id),
              value: item.id,
              isDragging: false,
              isSorting: false,
              isDragOverlay: false,
            })}
            wrapperStyle={wrapperStyle({index})}
            renderItem={renderItem}
          />
        ))}
      </Container>
    );
  }

  function handleCollapse(containerID: UniqueIdentifier) {
    if ( items[containerID].classes !== 'collapsed' ) {
      items[containerID].classes = 'collapsed'
    }
    else {
      items[containerID].classes = ''
    }
    setItems({...items})
  }

  function handleRemove(containerID: UniqueIdentifier) {
    setContainers((containers) =>
      containers.filter((id) => id !== containerID)
    )
  }

  // function handleAddColumn() {
  //   const newContainerId = getNextContainerId();
  //
  //   unstable_batchedUpdates(() => {
  //     setContainers((containers) => [...containers, newContainerId]);
  //     setItems((items) => ({
  //       ...items,
  //       [newContainerId]: {id:newContainerId, name:'Nouveau groupe', items:[]}
  //     }));
  //   });
  // }

  function getNextContainerId() {
    const containerIds = Object.keys(items);
    const lastContainerId = containerIds[containerIds.length - 1];

    return String.fromCharCode(lastContainerId.charCodeAt(0) + 1);
  }
}

interface SortableItemProps {
  containerId: UniqueIdentifier;
  id: UniqueIdentifier;
  index: number;
  handle: boolean;
  disabled?: boolean;
  style(args: any): React.CSSProperties;
  getIndex(id: UniqueIdentifier): number;
  renderItem(): React.ReactElement;
  // wrapperStyle({index}: {index: number}): React.CSSProperties;
}

function SortableItem({
  disabled,
  id,
  data,
  index,
  handle,
  renderItem,
  style,
  containerId,
  getIndex,
  wrapperStyle,
  loadPopinTasks,
}: SortableItemProps) {
  const {
    setNodeRef,
    setActivatorNodeRef,
    listeners,
    isDragging,
    isSorting,
    over,
    overIndex,
    transform,
    transition,
  } = useSortable({
    id,
  });
  const mounted = useMountStatus();
  const mountedWhileDragging = isDragging && !mounted;

  return (
    <Item
      ref={disabled ? undefined : setNodeRef}
      data={data}
      value={id}
      dragging={isDragging}
      sorting={isSorting}
      handle={handle}
      handleProps={handle ? {ref: setActivatorNodeRef} : undefined}
      index={index}
      wrapperStyle={wrapperStyle({index})}
      style={style({
        index,
        value: id,
        isDragging,
        isSorting,
        overIndex: over ? getIndex(over.id) : overIndex,
        containerId,
      })}
      transition={transition}
      transform={transform}
      fadeIn={mountedWhileDragging}
      listeners={listeners}
      renderItem={renderItem}
      loadPopinTasks={loadPopinTasks}
    />
  );
}

function useMountStatus() {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 500);

    return () => clearTimeout(timeout);
  }, []);

  return isMounted;
}
