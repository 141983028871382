// Librairies React
import React from 'react'
import { NavLink } from 'react-router-dom'
import Icon from './Icon'
import GPSUrl from '../components/GPSUrl'

export default function BlocLogement(props) {

  return (
    <div className='bloc-logement'>
      {props.accessible
      ?
        <NavLink exact to={`/logement/${props.id}`} itemProp="url" style={{background: props.color}}
                 className="task active">
          <span>{props.title}</span>
          <span>{props.address}</span>
        </NavLink>
      :
        <div style={{background: props.color}} className="task active">
          <span>{props.title}</span>
          <span>{props.address}</span>
        </div>
      }

      <div className="btns">
        <GPSUrl
          adresse={props.address}
          lat={props.lat}
          lng={props.lng}
          label='GPS' />

        {props.book &&
          <a href={props.book} target="_blank" rel="noreferrer" className="btn btn-icon">
            <span>
              Book
              <Icon icon='book' />
            </span>
          </a>
        }

        <NavLink exact to={`/logement/${props.id}/ticket`} itemProp="url" className="btn btn-icon btn-ticket">
          <span>
            Ticket
            <Icon icon='ticket' />
          </span>
        </NavLink>

        {props.accessible &&
          <NavLink exact to={`/logement/${props.id}/intervention`} itemProp="url" className="btn btn-intervention">
            <span>
              Intervention
            </span>
          </NavLink>
        }
      </div>
    </div>
  )
}
