
// Fonction de formattage d'un objet date
// Utilisée sur Planning.js, Reservations.js
export function formatDate(date) {
  return {
    jour: date.toLocaleDateString('fr-FR', {weekday: 'long'}),
    date: date.toLocaleDateString('fr-FR', {day:'2-digit'}),
    mois: date.toLocaleDateString('fr-FR', {month:'short'}),
    nmois: date.toLocaleDateString('fr-FR', {month:'2-digit'}),
    an: date.toLocaleDateString('fr-FR', {year:'numeric'})
  }
}

// Fonction de formattage d'un objet date
// Utilisée sur Header.js pour les notifications
export function formatNotifDate(param_date) {
  const date = new Date(param_date)
  let date_string = "Le "+date.toLocaleDateString('fr-FR', {day:'2-digit', month:'long', year:'numeric'})
  date_string += " à "+("0"+date.getHours()).slice(-2)+"h"+("0"+date.getMinutes()).slice(-2)
  return date_string
}

// Fonction de formattage de l'heure
// Utilisée sur planning
export function formatTime(date, duree) {
  const endDate = new Date()
  if ( duree > 0 ) {
    endDate.setTime(date.getTime()+(duree*60*60*1000))
  }
  return {
    // debut: ("0"+date.getUTCHours()).slice(-2)+"h"+("0"+date.getUTCMinutes()).slice(-2),
    debut: ("0"+date.getHours()).slice(-2)+"h"+("0"+date.getMinutes()).slice(-2),
    fin: (duree > 0 ? ("0"+endDate.getHours()).slice(-2)+"h"+("0"+endDate.getMinutes()).slice(-2) : null)
  }
}

// Fonction de formattage de l'heure
// Utilisée sur reservation et taskbillable
export function formatHour(date) {
  const new_date = new Date(date)
  return ("0"+new_date.getHours()).slice(-2)+"h"+("0"+new_date.getMinutes()).slice(-2)
}


// Formate 2 dates : "Du lundi 28 mars au samedi 3 avril"
// Utilisée sur Reservations.js
export function formatReservationDate(debut, fin) {
  let date = ""

  const options_jour = {day: "2-digit", weekday: "long"}
  const options_mois = {month: "long"}
  const options_an = {year: "numeric"}

  const date_debut = new Date(debut)
  const date_debut_jour = date_debut.toLocaleDateString("fr-FR", options_jour)
  const date_debut_mois = date_debut.toLocaleDateString("fr-FR", options_mois)
  const date_debut_an = date_debut.toLocaleDateString("fr-FR", options_an)

  const date_fin = new Date(fin)
  const date_fin_jour = date_fin.toLocaleDateString("fr-FR", options_jour)
  const date_fin_mois = date_fin.toLocaleDateString("fr-FR", options_mois)
  const date_fin_an = date_fin.toLocaleDateString("fr-FR", options_an)

  if ( date_debut_an === date_fin_an ) {
    if ( date_debut_mois === date_fin_mois ) {
      if ( date_debut_jour === date_fin_jour ) {
        date = "Le "+date_debut_jour+" "+date_debut_mois+" "+date_debut_an
      }
      else {
        date = "Du "+date_debut_jour +" au "+date_fin_jour+" "+date_debut_mois+" "+date_debut_an
      }
    }
    else {
      date = "Du "+date_debut_jour+" "+date_debut_mois+" au "+date_fin_jour+" "+date_fin_mois+" "+date_debut_an
    }
  }
  else { // année différente
    date = "Du "+date_debut_jour+" "+date_debut_mois+" "+date_debut_an+" au "+date_fin_jour+" "+date_fin_mois+" "+date_fin_an
  }

  return date
}

// Formate 2 dates : "Du lundi 28 mars au samedi 3 avril"
// Utilisée sur Reservation.js
export function formatQty(qty, singular, plural) {
  return parseInt(qty) + ' ' + (parseInt(qty)<= 1 ? singular : plural)
}

// Transforme 2021-06-25T00:00:00+00:00
//         en 2021-06-25T00:00:00
// Utilisée sur Reservation.js
export function getFrenchDate(datetime) {
  if ( !datetime ) return null
  const date = datetime.split('+')
  return date[0]
}

// Utilisée sur Reservation.js
export function formatDatetime(datetime) {
  const date = new Date(datetime)
  return ("0"+date.getDate()).slice(-2)+"/"+("0"+(date.getMonth()+1)).slice(-2)+"/"+date.getFullYear()+" "+date.getHours()+"h"+date.getMinutes()
}

// Utilisée sur Reservation.js
export function formatDateString(datetime) {
  const date = new Date(datetime)
  return date.toLocaleDateString('fr-FR', {weekday: 'long'})+" "+("0"+date.getDate()).slice(-2)+"/"+("0"+(date.getMonth()+1)).slice(-2)+"/"+date.getFullYear()
}

// Utilisée sur Reservation.js
export function formatTimeString(hours) {
  var rhours = Math.floor(hours)
  var minutes = (hours - rhours) * 60
  var rminutes = Math.round(minutes)
  return "0" + rhours + ":" + (rminutes<10 ? "0" : "") +rminutes
}

// Utilisée sur ReservationContent.js
export function formatHourString(datetime) {
  const date = new Date(datetime)
  return String(date.getHours()).padStart(2, '0')+"h"+String(date.getMinutes()).padStart(2, '0')
}

export function formatDuration(duree) {
  if ( !duree ) return null
  const hours = parseInt(duree)
  let minutes = String(Math.round((duree-hours)*60 * 100) / 100)
  if ( minutes > 0 ) {
    minutes = minutes.padStart(2, '0')
  }
  else {
    minutes = ""
  }
  return hours+"h"+minutes
}

// Utilisée sur Reservation.js
export function getCurrentDate() {
  const date = new Date()
  return date.getFullYear()+'-'+("0"+(date.getMonth()+1)).slice(-2)+'-'+("0"+date.getDate()).slice(-2)+"T"+("0"+date.getHours()).slice(-2)+":"+("0"+date.getMinutes()).slice(-2)+":"+("0"+date.getSeconds()).slice(-2)+"Z"
}
