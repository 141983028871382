/*
 * Routes nécessaires sur cet écran :
 - Route GET Si ID équipier, Récupération des infos de l'équipier
 - Route POST Enregistrement des infos de l'équipier (si ID équipier défini alors Modification, sinon Création ?)
 */

import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import useUser from '../hooks/useUser'
import useLoadDatas from '../hooks/useLoadDatas'
import useSaveDatas from '../hooks/useSaveDatas'
import useLoadRentings from '../hooks/useLoadDatas'
import useSaveFavRenting from '../hooks/useSaveDatas'
import useDeleteFavRenting from '../hooks/useDeleteDatas'
import Loader from '../components/Loader'
import NotFound from '../components/NotFound'
import Icon from '../components/Icon'
import useForm from '../hooks/useForm'

export default function EquipageEdit() {
  const { id } = useParams()

  // Détermine le statut de l'utilisateur connecté
  const { user, isManager, isConcierge, isCleaner } = useUser()

  const options_roles = [
    {
      value: 'ROLE_EQUIPIER',
      label: 'Happy Concierge'
    },
    {
      value: 'ROLE_CLEANER',
      label: 'Cleaner'
    }
  ]

  // Formulaires
  // ---------------------------------------------------------------------------
  const [isFormLoading, setIsFormLoading] = useState(false)
  const { setErrors, showFields, getFields, setFields, setSuccess, showReturnMessage } = useForm([])

  // Chargement des données
  // ---------------------------------------------------------------------------
  const route ='/users/'+id
  const [fetchData, isLoading, isError] = useLoadDatas()
  const [equipierId, setEquipierId] = useState()
  const [equipierRole, setEquipierRole] = useState()
  const [tempRole, setTempRole] = useState(false)
  const [agendaId, setAgendaId] = useState()
  const [favRentings, setFavRentings] = useState()
  const route_rentings ='/rentings?pagination=false'+(!isManager && '&user='+id)+'&order[owner.lastName]=ASC&withNumberFavoriteLowerThan=2&withFavoriteUserRole='
  const [fetchRentings, isLoadingRentings, isErrorRentings] = useLoadRentings()
  useEffect(() => {
    loadData()
    async function loadData() {
      const json_datas = await fetchData(route)
      setEquipierId(json_datas.hoomyId)
      setAgendaId(json_datas.agendaId)

      let favoriteRentings = []
      let initFavoriteRentings = []
      if ( json_datas.favoriteRentings.length ) {
        json_datas.favoriteRentings.forEach((fav, i) => {
          // const name_owner = fav.renting.owner.lastName+" "+fav.renting.owner.firstName
          favoriteRentings.push({
            id: fav.id,
            renting: fav.renting.hoomyId,
            owner: fav.renting.owner.lastName,
            rentingName: fav.renting.rentingName,
            rentingNameAlt: fav.renting.rentingNameAlt
          })
          initFavoriteRentings.push(fav.hoomyId)
        })
      }
      setFavRentings(favoriteRentings)

      // On récupère le rôle de l'équipier affiché
      if ( json_datas.role !== 'ROLE_ADMIN' || json_datas.role !== 'ROLE_MANAGER' ) {
        const roleIndex = options_roles.findIndex((role) => role.value === json_datas.role)
        if ( roleIndex >= 0 ) {
          setEquipierRole({
            value: json_datas.role,
            label: options_roles[roleIndex].label
          })
          setTempRole(json_datas.role)
        }
      }

      if ( json_datas ) {
        setFields([
          {
            name: "lastName",
            type: "text",
            label: "Nom",
            placeholder: "Saisissez le nom",
            error: "Le nom",
            required: true,
            // required_format: "email",
            // error_format: "L'identifiant doit être un email",
            value: json_datas.lastName
          },
          {
            name: "firstName",
            type: "text",
            label: "Prénom",
            placeholder: "Saisissez le prénom",
            error: "Le prénom",
            required: true,
            // required_format: "email",
            // error_format: "L'identifiant doit être un email",
            value: json_datas.firstName
          },
          {
            name: "phone",
            type: "tel",
            label: "Téléphone",
            placeholder: "Saisissez le téléphone",
            error: "Le téléphone",
            required: true,
            // required_format: "email",
            // error_format: "L'identifiant doit être un email",
            value: json_datas.phone
          },
          {
            name: "email",
            type: "email",
            label: "Email",
            placeholder: "Saisissez l'email",
            error: "L'email",
            required: true,
            required_format: "email",
            error_format: "L'identifiant doit être un email",
            value: json_datas.email
          },
          {
            name: "agendaId",
            type: "text",
            label: "ID de son Google Calendar",
            placeholder: "Saisissez l'id du Google Calendar",
            error: "L'id du Google Calendar",
            required: false,
            value: json_datas.agendaId
          },
          {
            name: "color",
            type: "color",
            label: "Code couleur à utiliser dans le planning",
            placeholder: "Saisissez la couleur",
            error: "La couleur",
            required: false,
            // required_format: "email",
            // error_format: "L'identifiant doit être un email",
            value: json_datas.color
          },
          {
            name: "isActive",
            type: "switch",
            label_true: "Profil actif",
            label_false: "Profil inactif",
            value: json_datas.isActive
          }
        ])
      }
    }
  }, [route])



  // Bloc Métier / Rôle
  const [showSelectRole, setShowSelectRole] = useState(false)
  const toggleShowSelectRole = () => {
    if ( showSelectRole ) {
      setShowSelectRole(false)
    }
    else {
      setShowSelectRole(true)
    }
  }
  const changeTempRole = (e) => {
    setTempRole(e.target.value)
  }
  const editRole = async () => {
    const parameters = {
      role: tempRole
    }
    const json_datas = await saveDatas('/users/'+equipierId, {method: 'PUT'}, parameters)
    setIsFormLoading(false)
    if ( json_datas.error ) { // Si erreur
      setErrors({
        message: json_datas.message,
        fields: json_datas.errors
      })
    }
    else {
      const roleIndex = options_roles.findIndex((role) => role.value === tempRole)
      setEquipierRole({
        value: tempRole,
        label: options_roles[roleIndex].label
      })
      setShowSelectRole(false)
      setFavRentings([]) // On vide les favoris
      setSuccess({
        message: "Le métier de l'équipier a été modifié avec succès !"
      })
    }
  }


  // Bloc Favoris
  const [rentings, setRentings] = useState()
  const [rentingsPopin, setRentingsPopin] = useState(null)

  const openRentingsPopin = async (e) => {
    // Récupère les rentings avant ouverture
    let fav_role = ''
    switch (equipierRole.value) {
      case options_roles[0].value: // ROLE_EQUIPIER
        fav_role = options_roles[1].value // ROLE_CLEANER
      break;
      case options_roles[1].value: // ROLE_CLEANER
        fav_role = options_roles[0].value // ROLE_EQUIPIER
      break;
    }
    const json_rentings = await fetchRentings(route_rentings+fav_role)
    let listRentings = []
    json_rentings['hydra:member'].forEach((renting, i) => {
      // Si le renting n'est pas déjà dans la liste des favoris de l'équipier
      // const name_owner = renting.owner.lastName+" "+renting.owner.firstName
      const is_favorite = (favRentings.includes(renting.hoomyId) ? true : false)
      listRentings.push({
        id: renting.hoomyId,
        owner: renting.owner.lastName, // name_owner.trim(),
        rentingName: renting.rentingName,
        rentingNameAlt: renting.rentingNameAlt,
        is_favorite: is_favorite
      })
    })
    setRentings(listRentings)

    // Ouvre la popin
    setRentingsPopin(true)
  }

  const [saveFavRenting] = useSaveFavRenting()
  const submitFavRenting = async (id_renting) => {
    const parameters = {
      user: equipierId,
      renting: id_renting
    }
    const json_datas = await saveFavRenting('/user-favorite-rentings', {method: 'POST'}, parameters)
    setIsFormLoading(false)
    if ( json_datas.error ) { // Si erreur
      setErrors({
        message: json_datas.message,
        fields: json_datas.errors
      })
    }
    else {
      const rentingIndex = rentings.findIndex((renting) => renting.id === id_renting)
      const renting = rentings[rentingIndex]
      favRentings.push({
        id: json_datas.id,
        renting: id_renting,
        owner: renting.owner,
        rentingName: renting.rentingName,
        rentingNameAlt: renting.rentingNameAlt
      })
      setFavRentings([...favRentings])

      rentings[rentingIndex].is_favorite = true
      setRentings([...rentings])

      setSuccess({
        message: "L'équipier a bien été ajouté en favori sur le logement."
      })
    }
  }
  const [deleteFavRentings] = useDeleteFavRenting()
  const deleteFavRenting = async (fav_id) => {
    const delete_favori = await deleteFavRentings('/user-favorite-rentings/'+fav_id, {method: 'DELETE'})
    setIsFormLoading(false)
    if ( delete_favori.error ) { // Si erreur
      setErrors({
        message: delete_favori.message,
        fields: delete_favori.errors
      })
    }
    else {
      const favIndex = favRentings.findIndex((favRenting) => favRenting.id === fav_id)
      const id_renting = favRentings[favIndex].renting
      favRentings.splice(favIndex, 1)
      setFavRentings([...favRentings])

      setSuccess({
        message: "L'équipier a bien été supprimé des favoris du logement."
      })
    }
  }


  // Enregistrement des données
  // ---------------------------------------------------------------------------
  const [saveDatas] = useSaveDatas()
  const formSubmit = async (e) => {
    e.preventDefault()

    // Réinitialise avant traitement
    setIsFormLoading(true)
    setErrors({})

    // Traitement
    const parameters = getFields()
    if ( Object.keys(parameters.errors).length > 0 ) {
      setIsFormLoading(false)
      setErrors({
        message: "Veuillez compléter ou corriger les champs suivants :",
        fields: parameters.errors
      })
    }
    else {
      const json_datas = await saveDatas('/users/'+id, {method: 'PUT'}, parameters.values)
      setIsFormLoading(false)
      if ( json_datas.error ) { // Si erreur
        setErrors({
          message: json_datas.message,
          fields: json_datas.errors
        })
      }
      else {
        setSuccess({
          message: "Les informations de l'équipier ont été modifiées avec succès !"
        })
      }
    }
  }

  return (
    <main className="equipage-edition">
      <div className="header">
        <h1>Modifier cet équipier</h1>
      </div>
      <div className={`content innerSmallWidth hasloader ${isLoading ? 'loadin' : ''}`}>
        {!isLoading &&
          <>
            {isError // Si pas de résultats de la route
            ?
              <NotFound />
            :
              <>
                { showReturnMessage() }

                <fieldset className="bloc_profil">
                  <legend>Profil</legend>
                  <form id="equipForm"
                        className={`form hasloader ${isFormLoading ? 'loadin' : ''}`}
                        onSubmit={formSubmit}>

                    { showFields() }

                    <div className="form-submit">
                      <button type="submit" className="btn btn-icon">
                        <span>
                          Enregistrer
                          <Icon icon="check" />
                        </span>
                      </button>
                    </div>

                    <Loader />
                  </form>
                </fieldset>

                {(equipierRole && isManager)
                ?
                  <fieldset className="bloc_role">
                    <legend>Métier</legend>
                    <div className="center">
                      <span>Actuellement {equipierRole.label}</span>
                      <button className="btn" onClick={toggleShowSelectRole}>
                        {showSelectRole
                        ?
                          <>Je ne veux plus modifier son métier</>
                        :
                          <>Je veux modifier son métier</>
                        }
                      </button>

                      <div className={`select_role ${showSelectRole ? 'active' : ''}`}>
                        <select id="role" name="role"
                                onChange={(e) => changeTempRole(e)}
                                value={(tempRole ? tempRole : '')}>
                          {options_roles.map((option, i) => {
                            return <option key={i} value={option.value}>{option.label}</option>
                          })}
                        </select>
                        <p>Attention, toute modification entraînera l'effacement des logements favoris de cet équipier.</p>

                        <button className="btn" onClick={editRole}>Je confirme, je veux modifier le métier</button>
                      </div>
                    </div>
                  </fieldset>
                :
                  ''
                }

                {(equipierRole && isManager) &&
                  <fieldset className="bloc_favoris">
                    <legend>Logements favoris</legend>
                    <p className="intro">
                      <span>Ajouter en favori aux logements suivants</span>
                      <button className="btn" onClick={openRentingsPopin}>+</button>
                    </p>
                    <div id="listFavoris">
                      {(favRentings)
                      ?
                        favRentings.map((fav, i) => {
                          return <div key={i} className={`renting`}>
                                    <span>{fav.owner+' | '+(fav.rentingNameAlt ? fav.rentingNameAlt+' | ' : '')+fav.rentingName.toUpperCase()}</span>
                                    <button className="btn" onClick={(e) => deleteFavRenting(fav.id)}>-</button>
                                 </div>
                        })
                      :
                        <em>Aucun favori sélectionné.</em>
                      }
                    </div>
                  </fieldset>
                }

                <fieldset className="bloc_agenda">
                  <legend>Agenda</legend>
                  <div className="center">
                    {agendaId
                    ?
                      <>
                        <a href={`https://calendar.google.com/calendar/embed?src=${agendaId}&ctz=Europe%2FParis`} target='_blank' rel="noreferrer" className="btn">Voir son agenda complet</a>
                      </>
                    :
                      <p>Son agenda n'est pas disponible.</p>
                    }
                  </div>
                </fieldset>
              </>
            }
          </>
        }
        <Loader />

        <div id="popinRentings" className={`popin popin-rentings ${rentingsPopin ? 'active' : ''}`}>
          <div className="popin-container">
            <p className="intro">
              <strong>Sélectionnez les logements sur lesquels vous souhaitez que cet équipier soit ajouté en favori.</strong>
            </p>
            <div className='listRentings'>
              {rentings
              ?
                rentings.map((renting, i) => {
                  return <div key={i} className={`renting ${renting.is_favorite ? 'added' : ''}`}>
                            <span>{renting.owner+' | '+(renting.rentingNameAlt ? renting.rentingNameAlt+' | ' : '')+renting.rentingName.toUpperCase()}</span>
                            {renting.is_favorite
                            ?
                              <em>Ajouté aux favoris</em>
                            :
                              <button className="btn" onClick={(e) => submitFavRenting(renting.id)}>Ajouter</button>
                            }
                         </div>
                })
              :
                ''
              }
            </div>
            <button className="btn" type="button" onClick={() => setRentingsPopin(null)}>Fermer la popin</button>
          </div>
          <div className="popin-overlay"></div>
        </div>

      </div>
    </main>
  )
}
