import React, { useState, useEffect } from 'react'
import useLoadDatas from '../../hooks/useLoadDatas'
import ReservationContent from '../ReservationContent'
import TaskWithoutReservationContent from '../TaskWithoutReservationContent'
import TicketContent from '../TicketContent'
import TaskPlanning from '../TaskPlanning'
import useBlockHistory from '../../hooks/useBlockHistory'

export default function DayContainer(props) {
  return (
    <div id='day-container'>
      {props.datas.map((data, index) => (
        <div key={index}
             className='planningItem'
             style={{...data.styles}}>
          <div className="zones">
            {data.tasks
              ?
              data.tasks.map((task, index) => (
                <TaskPlanning key={index}
                              styles={task.styles}
                              {...task.values}
                              onClick={(e) => props.loadPopinTasks(task.name, [task])} />
              ))
              :
              <>
                <em className="no-result">Aucune tâche à traiter.</em>
              </>
            }
          </div>
        </div>
      ))}
    </div>
  )
}
