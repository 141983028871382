// Librairies React
import React from 'react'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

export default function DatePickerCustom(props) {
  dayjs.locale('fr') // use locale globally

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
      <DatePicker
        // disableToolbar
        // variant="inline"
        // autoOk={true}
        // minDateMessage="" // "La date ne doit pas être antérieure à la date minimale"
        // invalidDateMessage="" // "Le format de la date n'est pas valide"
        className={props.className ? props.className : ""}
        label={props.label ? props.label : "date"}
        format="DD/MM/YYYY"
        value={dayjs(props.date)}
        onChange={(date) => props.onChange(date)}
      />
    </LocalizationProvider>
  )
}
