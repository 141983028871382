import React, { useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import useForm from '../hooks/useForm'
import Icon from '../components/Icon'
import Loader from '../components/Loader'

function NewPassword({ setToken }) {
  const { key } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [isUpdated, setIsUpdated] = useState(false)
  const { setErrors, showFields, getFields,
          setSuccess, showReturnMessage } = useForm([
    {
      name: "pass1",
      type: "password",
      label: "Mot de passe",
      placeholder: "Saisissez votre mot de passe",
      error: "Votre mot de passe",
      required: true,
      // required_format: "password",
      // error_format: "Le mot de passe doit comporter au moins 10 caractères dont au moins 1 lettre et au moins 1 chiffre",
      value: ""
    },
    {
      name: "pass2",
      type: "password",
      label: "Répétition du mot de passe",
      placeholder: "Répétér votre mot de passe",
      error: "Votre mot de passe",
      required: true,
      // required_format: "password",
      // error_format: "Le mot de passe doit comporter au moins 10 caractères dont au moins 1 lettre et au moins 1 chiffre",
      value: ""
    }
  ])

  const handleSubmit = async e => {
    e.preventDefault()

    // Réinitialise avant traitement
    setIsLoading(true)
    setErrors({})

    // Traitement
    const parameters = getFields(e.target)
    if ( Object.keys(parameters.errors).length > 0 ) {
      setIsLoading(false)
      setErrors({
        message: "Veuillez compléter ou corriger les champs suivants :",
        fields: parameters.errors
      })
    }
    else {
      // Si les 2 mots de passe sont différents
      if ( parameters.values.pass1 !== parameters.values.pass2 ) {
        setErrors({
          message: "Veuillez saisir 2 mots de passe identiques."
        })
      }
      else {
        await changePassword(parameters.values.pass1)
      }
      setIsLoading(false)
    }
  }

  async function changePassword(password) {
    const fetchHeaders = new Headers()
    fetchHeaders.append("Content-Type", "application/json")

    const parameters = {
      password: password,
      token: {
        code: key
      }
    }
    return fetch(process.env.REACT_APP_API+'/users/reset-password', {
      method: 'POST',
      headers: fetchHeaders,
      body: JSON.stringify(parameters)
    })
    .then(response => {
      return {
        ok: response.ok,
        api: response.json()
      }
    })
    .then(json_datas => {
      setIsLoading(false)
      return json_datas.api.then(function(result) {
        if ( json_datas.ok ) { // Si succès
          return result
        }
        else { // Si erreur
          let errors = {}
          if ( result.violations ) {
            result.violations.forEach((violation, i) => {
              errors[i] = violation.message
            })
            setErrors({
              message: "Veuillez ajuster les erreurs suivantes :",
              fields: errors
            })
            return;
          }
          else {
            setErrors({
              message: "La modification de votre mot de passe a rencontré un problème."
            })
            return;
          }
        }
      })
    })
    .then(json_datas => {
      if ( json_datas ) {
        setSuccess({
          message: json_datas.message
        })
        setIsUpdated(true)
      }
    })
    .catch(error => {
      setIsLoading(false)
      setErrors({
        message: "Erreur de formulaire"
      })
    })
  }

  return(
    <main>
      <div className="header">
        <h1>Saisissez votre nouveau mot de passe !</h1>
      </div>
      <div className="content innerSmallWidth">
        <form id="newPassForm"
              className={`form hasloader ${isLoading ? 'loadin' : ''}`}
              onSubmit={handleSubmit}>

          { showReturnMessage() }

          {!isUpdated
          ?
          <>
            { showFields() }

            <div className="form-submit">
              <button type="submit" className="btn">Valider votre nouveau mot de passe</button>
            </div>
            <Loader />
          </>
          :
            <div class="back-link">
              <NavLink exact to="/" itemProp="url" className="link-icon">
                <span itemProp="name">
                  <Icon icon="back" />
                  Revenir à la page de connexion
                </span>
              </NavLink>
            </div>
          }

        </form>
      </div>
    </main>
  )
}

export default NewPassword
