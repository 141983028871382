// Librairies React
import React from 'react'
import { NavLink } from 'react-router-dom'
import Icon from './Icon'

export default function TaskPlanning(props) {
  /*
   task type :
   1 = Accueil => icon entree
   2 = Sortie => icon sortie
   3 = Intervention => icon intervention
   */
  let icon_task = ""
  switch (props.type) {
    case 1:
      icon_task = "entree"
    break;
    case 2:
      icon_task = "sortie"
    break;
    case 3:
      icon_task = "intervention"
    break;
    default:
  }

  let link = ""
  if ( props.id ) {
    // Tâche de réservation
    link = `/reservation/${props.id}/${props.id_task ? props.id_task+'/' : ''}`
  }
  else {
    if ( props.id_ticket ) {
      // Intervention sur ticket
      link = `/ticket/${props.id_ticket}/${props.id_task ? props.id_task+'/' : ''}`
    }
    else if ( props.id_renting ) {
      link = `/logement/${props.id_renting}/interventions/${props.id_task ? props.id_task+'/' : ''}`
    }
  }

  return (
    <>
      {props.notAccessible
      ?
        <div style={{background: props.color}}
             className={`task${props.show ? ' active' : ''} ${props.classe} ${props.isOwner ? 'owner' : ''}`}>
          {(icon_task && !props.isOwner) &&
            <div className="icon_puce">
              <Icon icon={icon_task} classe={`icon-type-${icon_task}`} />
            </div>
          }
          {(props.isOwner && !icon_task) &&
            <Icon icon='home' classe={`icon-home`} />
          }
          <span>{props.value}</span>
          {props.value_more &&
            <em>{props.value_more}</em>
          }
          {props.date &&
            <span>{props.date}</span>
          }
          {(props.services === true || props.rotate === true || props.comment === true || props.status === 'closed') &&
            <div className='icons'>
              {props.services === true &&
                <Icon icon='services' title='Services' />
              }
              {props.rotate === true &&
                <>
                <Icon icon='rotate' title='Rotation logement' />
                </>
              }
              {props.comment === true &&
                <Icon icon='comment' title='Commentaire' />
              }
              {props.status === 'closed' &&
                <Icon icon='checked' title='Tâche réalisée' />
              }
            </div>
          }
        </div>
      :
        <>
          {props.onClick
          ?
            <div itemProp="url" style={{background: props.color, ...props.styles}}
                 className={`task${props.show ? ' active' : ''} ${props.classe} ${props.isOwner ? 'owner' : ''}`}
                 onClick={props.onClick}>
              {(icon_task && !props.isOwner) &&
                <div className="icon_puce">
                  <Icon icon={icon_task} classe={`icon-type-${icon_task}`} />
                </div>
              }
              {(props.isOwner && !icon_task) &&
                <Icon icon='home' classe={`icon-home`} />
              }
              <span>{props.value}</span>
              {props.value_more &&
                <em>{props.value_more}</em>
              }
              {props.date &&
                <span>{props.date}</span>
              }
              {(props.services === true || props.rotate === true || props.comment === true || props.status === 'closed') &&
                <div className='icons'>
                  {props.services === true &&
                    <Icon icon='services' title='Services' />
                  }
                  {props.rotate === true &&
                    <Icon icon='rotate' title='Rotation logement' />
                  }
                  {props.comment === true &&
                    <Icon icon='comment' title='Commentaire' />
                  }
                  {props.status === 'closed' &&
                    <Icon icon='checked' title='Tâche réalisée' />
                  }
                </div>
              }
            </div>
          :
            <NavLink exact to={link} itemProp="url" style={{background: props.color, ...props.styles}}
                     className={`task${props.show ? ' active' : ''} ${props.classe} ${props.isOwner ? 'owner' : ''}`}>
              {(icon_task && !props.isOwner) &&
                <div className="icon_puce">
                  <Icon icon={icon_task} classe={`icon-type-${icon_task}`} />
                </div>
              }
              {(props.isOwner && !icon_task) &&
                <Icon icon='home' classe={`icon-home`} />
              }
              <span>{props.value}</span>
              {props.value_more &&
                <em>{props.value_more}</em>
              }
              {props.date &&
                <span>{props.date}</span>
              }
              {(props.services === true || props.rotate === true || props.comment === true || props.status === 'closed') &&
                <div className='icons'>
                  {props.services === true &&
                    <Icon icon='services' title='Services' />
                  }
                  {props.rotate === true &&
                    <Icon icon='rotate' title='Rotation logement' />
                  }
                  {props.comment === true &&
                    <Icon icon='comment' title='Commentaire' />
                  }
                  {props.status === 'closed' &&
                    <Icon icon='checked' title='Tâche réalisée' />
                  }
                </div>
              }
            </NavLink>
          }
        </>
      }
    </>
  )
}
