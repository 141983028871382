import React from 'react'
import { NavLink } from 'react-router-dom'

export default function PageNotFound() {
  return (
    <main>
      <div className="header">
        <h1>Vous vous êtes perdu en route...</h1>
      </div>
      <div className="content error404">
        <div className='innerWidth'>
          <p>{`La contenu ne peut s'afficher correctement, peut-être un problème de réseau.`}</p>
          <NavLink exact to="/" className="btn">{`Retourner à l'accueil`}</NavLink>
        </div>
      </div>
    </main>
  )
}
