import { useState } from 'react'
import jwt_decode from 'jwt-decode'

function useUser() {
  const getUser = () => {
    const userString = localStorage.getItem('user')
    return (userString ? JSON.parse(atob(userString)) : null)
  }

  const [user, setUser] = useState(getUser())

  const adminUser = JSON.parse(localStorage.getItem('admin_user'))

  // Détermine le rôle
  const role = (adminUser ? adminUser.role : (user ? user.role : null))

  const isManager = (role === "ROLE_ADMIN" || role === "ROLE_MANAGER")
  const isConcierge = (role === "ROLE_EQUIPIER")
  const isCleaner = (role === "ROLE_CLEANER")

  // Retourne le token
  const checkUser = async (paramUser) => {
    if ( paramUser ) {
      const now = new Date().getTime()
      // const now = datenow.getTime()
      const decodedToken = jwt_decode(paramUser.token)
      // console.log('checkUser', decodedToken)

      // const date = new Date(decodedToken.exp * 1000)
      // console.log('expire à', date.getHours()+":"+date.getMinutes()+":"+date.getSeconds())

      if ( !decodedToken || now > decodedToken.exp * 1000 ) {
        return await refreshUser(paramUser)
      }
    }
    return (paramUser ? paramUser.token : null)
  }

  const refreshUser = async (dataUser) => {
    // console.log('refreshUser')
    const fetchHeaders = new Headers()
    fetchHeaders.append("Content-Type", "application/json")
    return await fetch(process.env.REACT_APP_API+'/users/refresh-token', {
      method: 'POST',
      headers: fetchHeaders,
      body: JSON.stringify({refreshToken: dataUser.refreshToken})
    })
    .then(response => {
      if ( response.ok ) {
        return response.json()
      }
      else { // Sinon erreur
        removeUser()
        return false
      }
    })
    .then(json_datas => {
      if ( json_datas ) { // Si succès
        dataUser.token = json_datas.token
        dataUser.refreshToken = json_datas.refreshToken
        saveUser({...dataUser})
        return json_datas.token
      }
      else { // Sinon erreur
        removeUser()
        return false
      }
    })
    .catch(error => {
      removeUser()
      return false
    })
  }
  const saveUser = (dataUser) => {
    localStorage.setItem('user', btoa(JSON.stringify(dataUser)))
    setUser(dataUser)
  }
  const removeUser = () => {
    setUser(null)
    localStorage.removeItem('user')
    localStorage.removeItem('admin_user')

    // Assure la fermeture du menu
    document.getElementsByTagName("body")[0].classList.remove("menu-active")
  }

  return {
    user,
    checkUser,
    isManager,
    isConcierge,
    isCleaner,
    setUser: saveUser,
    removeUser
  }
}

export default useUser
