import React from 'react'
import loader from '../assets/images/loader.svg'

export default function Loader() {
  return (
    <div className="loader">
      <img src={loader} width="60" height="60" alt="Chargement..." />
    </div>
  )
}
