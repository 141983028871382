// Librairies React
import React from 'react'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

export default function TimePickerCustom(props) {
  dayjs.locale('fr') // use locale globally
  
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
      <TimePicker
        // disableToolbar
        // variant="inline"
        // autoOk={true}
        label="date"
        disabled={props.disabled}
        value={dayjs(props.time)}
        onChange={props.onChange}
        minDateMessage={props.minDateMessage}
        invalidDateMessage={props.timeInvalidDateMessage}
      />
      {
      // <TimePicker
      //   autoOk={true}
      //   ampm={false}
      //   // variant="inline"
      //   orientation="vertical"
      //   openTo="hours"
      //   disabled={(!isManager && isCoequipier) ? true : false}
      //   value={datas.inputs.accueil_autre}
      //   onChange={changeTimeAccueilAutre}
      //   minDateMessage={timeMinDateMessage}
      //   invalidDateMessage={timeInvalidDateMessage}
      // />
      }
    </LocalizationProvider>
  )
}
