// Librairies React
import React from 'react'

// Sprite SVG
// import sprite from '/images/icons.svg';

export default function Icon({icon, title, classe, onClick}) {
  return (
    <svg className={`icon${classe ? ' '+classe : ''}`} onClick={onClick}>
      {title ? <text>{title}</text> : ''}
      <use xlinkHref={`${process.env.REACT_APP_URI}/images/icons.svg#${icon}`}></use>
    </svg>
  )
}
