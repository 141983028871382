import { useState, useLayoutEffect } from 'react'

function useScrollBottom(element) {
  const [isBottom, setIsBottom] = useState(false)
  const [isStopped, setIsStopped] = useState(false)

  const  handleScroll = async () => {
    const scrolled_element = document.querySelector(element)
    // Si bascule définie, on vérifie la position par rapport à la limite du scroll
    // L'ajout de 300px permet de lancer le chargement avant que l'utilisateur n'arrive en bas de page
    if ( scrolled_element && Math.floor(scrolled_element.getBoundingClientRect().bottom) <= window.innerHeight + 300 ) {
      setIsBottom(true)
    }
  }
  useLayoutEffect(() => {
    if ( !isBottom ) {
      window.addEventListener('scroll', handleScroll)
    }
    // Unmount
    return () => window.removeEventListener('scroll', handleScroll)
  }, [isBottom, isStopped])

  return [isBottom, setIsBottom, handleScroll, isStopped, setIsStopped]
}

export default useScrollBottom
