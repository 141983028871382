import React from 'react'
import { NavLink } from 'react-router-dom'

export default function NotFound() {
  return (
    <div className="error404">
      <h2>{`Erreur...`}</h2>
      <div className='innerWidth'>
        <p>{`Le contenu ne peut s'afficher correctement, peut-être un problème de réseau.`}</p>
        <NavLink exact to="/" className="btn">{`Retourner à l'accueil`}</NavLink>
      </div>
    </div>
  )
}
