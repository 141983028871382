/*
 * Routes nécessaires sur cet écran :
 - Route GET Si ID équipier, Récupération des infos de l'équipier
 - Route POST Enregistrement des infos de l'équipier (si ID équipier défini alors Modification, sinon Création ?)
 */

import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import useUser from '../hooks/useUser'
import useSaveDatas from '../hooks/useSaveDatas'
import Loader from '../components/Loader'
import NotFound from '../components/NotFound'
import Icon from '../components/Icon'
import useForm from '../hooks/useForm'
import useBlockHistory from '../hooks/useBlockHistory'

export default function EquipageNew() {
  // const { id } = useParams()

  // Détermine le statut de l'utilisateur connecté
  const { user, checkUser, isManager, isConcierge, isCleaner } = useUser()

  const {hasChanges, setHasChanges, gotoUrl, saveBeforeContinue, continueWithoutSaving} = useBlockHistory()

  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  // Formulaires
  // ---------------------------------------------------------------------------
  const [isFormLoading, setIsFormLoading] = useState(false)
  const { setErrors, showFields, getFields, setFields, setSuccess, showReturnMessage } = useForm([
    {
      name: "lastName",
      type: "text",
      label: "Nom",
      placeholder: "Saisissez le nom",
      error: "Le nom",
      required: true,
      // required_format: "email",
      // error_format: "L'identifiant doit être un email",
      value: ''
    },
    {
      name: "firstName",
      type: "text",
      label: "Prénom",
      placeholder: "Saisissez le prénom",
      error: "Le prénom",
      required: true,
      // required_format: "email",
      // error_format: "L'identifiant doit être un email",
      value: ''
    },
    {
      name: "phone",
      type: "tel",
      label: "Téléphone",
      placeholder: "Saisissez le téléphone",
      error: "Le téléphone",
      required: true,
      // required_format: "email",
      // error_format: "L'identifiant doit être un email",
      value: ''
    },
    {
      name: "email",
      type: "email",
      label: "Email",
      placeholder: "Saisissez l'email",
      error: "L'email",
      required: true,
      required_format: "email",
      error_format: "L'identifiant doit être un email",
      value: ''
    },
    {
      name: "agendaId",
      type: "text",
      label: "ID de son Google Calendar",
      placeholder: "Saisissez l'id du Google Calendar",
      error: "L'id du Google Calendar",
      required: false,
      value: ''
    },
    {
      name: "role",
      type: "select",
      label: "Sélectionnez son métier",
      placeholder: "Sélectionnez son métier",
      error: "Le métier",
      options: [
        {
          value: 'ROLE_EQUIPIER',
          label: 'Happy Concierge'
        },
        {
          value: 'ROLE_CLEANER',
          label: 'Cleaner'
        }
      ],
      required: true,
      value: ''
    },
    {
      name: "color",
      type: "color",
      label: "Code couleur à utiliser dans le planning",
      placeholder: "Saisissez la couleur",
      error: "La couleur",
      required: false,
      // required_format: "email",
      // error_format: "L'identifiant doit être un email",
      value: '#e5e5e5'
    },
    {
      name: "isActive",
      type: "switch",
      label_true: "Profil actif",
      label_false: "Profil inactif",
      value: true
    }
  ])

  // Enregistrement des données
  // ---------------------------------------------------------------------------
  const [saveDatas] = useSaveDatas()
  const formSubmit = async (e) => {
    e.preventDefault()

    // Réinitialise avant traitement
    setIsFormLoading(true)
    setErrors({})

    // Traitement
    const parameters = getFields()
    if ( Object.keys(parameters.errors).length > 0 ) {
      setIsFormLoading(false)
      setErrors({
        message: "Veuillez compléter ou corriger les champs suivants :",
        fields: parameters.errors
      })
    }
    else {
      const json_datas = await saveDatas('/users', {}, parameters.values)
      setIsFormLoading(false)
      if ( json_datas.error ) { // Si erreur
        setErrors({
          message: json_datas.message,
          fields: json_datas.errors
        })
      }
      else {
        setSuccess({
          message: "Votre équipier a été créé avec succès !"
        })
      }
    }
  }

  return (
    <main>
      <div className="header">
        <h1>Ajouter un nouvel équipier</h1>
      </div>
      <div className={`content innerSmallWidth hasloader ${isLoading ? 'loadin' : ''}`}>
        {!isLoading &&
          <>
            {isError // Si pas de résultats de la route
            ?
              <NotFound />
            :
              <form id="equipForm"
                    className={`form hasloader ${isFormLoading ? 'loadin' : ''}`}
                    onSubmit={formSubmit}>

                { showReturnMessage() }

                { showFields() }

                <div className="form-submit">
                  <button type="submit" className="btn btn-icon">
                    <span>
                      Créer
                      <Icon icon="check" />
                    </span>
                  </button>
                </div>

                <Loader />
              </form>
            }
          </>
        }
        <Loader />
      </div>
    </main>
  )
}
