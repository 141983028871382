import React, {useEffect, Fragment} from 'react';
import { NavLink } from 'react-router-dom'
import type {DraggableSyntheticListeners} from '@dnd-kit/core';
import type {Transform} from '@dnd-kit/utilities';
import Tooltip from '@mui/material/Tooltip';
import Icon from '../../../Icon'

import {Handle, Remove} from './components';

export interface Props {
  dragOverlay?: boolean;
  color?: string;
  disabled?: boolean;
  dragging?: boolean;
  handle?: boolean;
  handleProps?: any;
  height?: number;
  index?: number;
  fadeIn?: boolean;
  transform?: Transform | null;
  listeners?: DraggableSyntheticListeners;
  sorting?: boolean;
  style?: React.CSSProperties;
  transition?: string | null;
  wrapperStyle?: React.CSSProperties;
  value: React.ReactNode;
  renderItem(args: {
    dragOverlay: boolean;
    dragging: boolean;
    sorting: boolean;
    index: number | undefined;
    fadeIn: boolean;
    listeners: DraggableSyntheticListeners;
    ref: React.Ref<HTMLElement>;
    style: React.CSSProperties | undefined;
    transform: Props['transform'];
    transition: Props['transition'];
    value: Props['value'];
  }): React.ReactElement;
}

export const Item = React.memo(
  React.forwardRef(
    (
      {
        color,
        dragOverlay,
        dragging,
        disabled,
        fadeIn,
        handle,
        handleProps,
        height,
        index,
        listeners,
        renderItem,
        sorting,
        style,
        transition,
        transform,
        value,
        data,
        containerId,
        wrapperStyle,
        loadPopinTasks,
        ...props
      },
      ref
    ) => {
      useEffect(() => {
        if (!dragOverlay) {
          return;
        }

        document.body.style.cursor = 'grabbing';

        return () => {
          document.body.style.cursor = '';
        };
      }, [dragOverlay]);

      return renderItem ? (
        renderItem({
          dragOverlay: Boolean(dragOverlay),
          dragging: Boolean(dragging),
          sorting: Boolean(sorting),
          index,
          fadeIn: Boolean(fadeIn),
          listeners,
          ref,
          style,
          transform,
          transition,
          value,
        })
      ) : (
        <li
          className='Wrapper'
          style={
            {
              ...wrapperStyle,
              transition: [transition, wrapperStyle?.transition]
                .filter(Boolean)
                .join(', '),
              '--translate-x': transform
                ? `${Math.round(transform.x)}px`
                : undefined,
              '--translate-y': transform
                ? `${Math.round(transform.y)}px`
                : undefined,
              '--scale-x': transform?.scaleX
                ? `${transform.scaleX}`
                : undefined,
              '--scale-y': transform?.scaleY
                ? `${transform.scaleY}`
                : undefined,
              '--index': index,
              '--color': color,
            }
          }
          ref={ref}
        >
          <div
            className='planningItem'
            style={style}
            data-cypress="draggable-item"
            // {...(!handle ? listeners : undefined)}
            {...props}
            tabIndex={!handle ? 0 : undefined}
          >
            <div className="name">
              <div className="Actions">
                {/* onRemove ? <Remove onClick={onRemove} /> : undefined */}
                {!handle ? <Handle {...listeners} /> : null}
              </div>
              {data.name}
            </div>
            <div className="zones">
              {data.zones &&
                data.zones.map((zone, i) => {
                  if ( zone.tasks ) {
                    // Zone renting task
                    return (
                      <Tooltip key={i} arrow
                               title={<div className='zone-tooltip'>{zone.tasks.map((task, i) => {
                                 return (
                                   <div key={i} className='zone-task'>
                                    <strong>{task.name}</strong> - {task.heures}<br />
                                    {task.equipiers.map((equipier, e) => {
                                      return (
                                        <Fragment key={e}>{e > 0 ? ', ' : ''}{equipier}</Fragment>
                                      )
                                    })}
                                   </div>
                                 )
                               })}</div>}>
                        <div className={`zone ${zone.dates.style} ${zone.hidden ? 'hidden' : ''}`}
                             style={zone.styles}
                             onClick={(e) => loadPopinTasks(data.name, zone.tasks)}>
                           {zone.rotate &&
                             <Icon icon='rotate' title='Rotation logement' />
                           }
                        </div>
                      </Tooltip>
                    )
                  }
                  else {
                    // Zone équipier
                    return (
                      <Tooltip key={i} arrow
                               title={<div className="zone-tooltip">
                                        <strong>{zone.tooltip.title}</strong>
                                        {zone.tooltip.desc && <div>{zone.tooltip.desc}</div>}
                                      </div>}>
                        <div className={`zone ${zone.dates.style}`}
                             style={zone.styles}>
                          {zone.title}
                        </div>
                      </Tooltip>
                    )
                  }
                })
              }
              {data.locations &&
                data.locations.map((location, i) => {
                  // Zone renting location
                  return (
                    <Tooltip key={i} arrow
                             title={<div className="zone-tooltip"><strong>{location.tooltip.title}</strong><br />{location.tooltip.desc}</div>}>
                      <div className={`zone ${location.dates.style}`}
                           style={location.styles}>
                      </div>
                    </Tooltip>
                  )
                })
              }
            </div>
          </div>
        </li>
      );
    }
  )
);
