/*
 * Routes nécessaires sur cet écran :
 - Route GET Si ID équipier, Récupération des infos de l'équipier
 - Route POST Enregistrement des infos de l'équipier (si ID équipier défini alors Modification, sinon Création ?)
 */

import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import useUser from '../hooks/useUser'
import useLoadEquipiers from '../hooks/useLoadDatas'
import useTaskTypes from '../hooks/useLoadDatas'
import useSaveDatas from '../hooks/useSaveDatas'
import useSaveCommentTask from '../hooks/useSaveDatas'
import TaskReservation from '../components/TaskReservation'
import Loader from '../components/Loader'
import NotFound from '../components/NotFound'
import Icon from '../components/Icon'
import useForm from '../hooks/useForm'
import useBlockHistory from '../hooks/useBlockHistory'
import {getFrenchDate, formatDatetime, formatDateString, formatTimeString, formatDuration, getCurrentDate} from '../functions/functions.js'


export default function TaskNew() {
  const { id_location, id_renting } = useParams()

  // Détermine le statut de l'utilisateur connecté
  const { user, checkUser, isManager, isConcierge, isCleaner } = useUser()

  const {hasChanges, setHasChanges, gotoUrl, saveBeforeContinue, continueWithoutSaving} = useBlockHistory()

  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  // Nettoie les classes du planning
  document.getElementsByTagName("body")[0].classList.remove("popin-tasks-active")
  
  // Date par défaut
  const date = new Date()
  const task_date = date.getFullYear()+'-'+("0"+(date.getMonth()+1)).slice(-2)+'-'+("0"+date.getDate()).slice(-2)+"T"+("0"+date.getHours()).slice(-2)+":"+("0"+date.getMinutes()).slice(-2)+":"+("0"+date.getSeconds()).slice(-2)
  const [taskDate, setTaskDate] = useState(task_date)

  // Formulaires
  // ---------------------------------------------------------------------------
  let taskLocation = []
  if ( id_location ) { // Si depuis location
    taskLocation = [
      {
        value: 1,
        label: 'Intervention pour une réservation',
      },
      {
        value: 0,
        label: 'Intervention hors réservation'
      }
    ]
  }
  else { // Si depuis logement
    taskLocation = [
      {
        value: 0,
        label: 'Intervention hors réservation'
      }
    ]
  }
  let init_fields = [
    {
      name: "location",
      type: "select",
      label: "Sélectionner le type d'intervention",
      placeholder: "Sélectionner le type d'intervention",
      error: "Le type d'intervention",
      options: taskLocation,
      required: true,
      // required_format: "email",
      // error_format: "L'identifiant doit être un email",
      value: (id_location ? 1 : 0)
    },
    {
      name: "taskType",
      type: "select",
      label: "Sélectionner le nom de l'intervention",
      placeholder: "Sélectionner le nom de l'intervention",
      error: "Le nom de l'intervention",
      options: [],
      required: true,
      // required_format: "email",
      // error_format: "L'identifiant doit être un email",
      value: '',
    },
    {
      name: "comment",
      type: "textarea",
      label: "Commentaire",
      placeholder: "Saisissez le commentaire",
      error: "Le commentaire",
      required: false,
      // required_format: "email",
      // error_format: "L'identifiant doit être un email",
      value: ''
    }
  ]
  const [isFormLoading, setIsFormLoading] = useState(false)
  const { setErrors, showFields, getFields, setFields, setSuccess, showReturnMessage } = useForm(init_fields)

  // Données pour task réservation
  const [taskDatas, setTaskDatas] = useState({
    id_event: 3,
    id_state: 1,
    date: taskDate,
    equipiers: [{
      user_id: null,
      equipier: null,
      heure: null,
      duree: null,
      duree_string: '',
      coequipier: false
    }]
  })

  // Chargement des équipiers
  // ---------------------------------------------------------------------------
  const [listEquipiers, setEquipiers] = useState([])
  const [routeFavEquipiers, setRouteFavEquipiers] = useState('/users?pagination=false&isActive=1&order[role]=DESC&favoriteRentings.renting.hoomyIds[]='+id_renting+(isCleaner ? '&hoomyIds[]='+user.hoomyId : ''))
  const [routeEquipiers, setRouteEquipiers] = useState()
  const [fetchEquipiers] = useLoadEquipiers()
  useEffect(() => {
    loadFavEquipiers()
    async function loadFavEquipiers() {
      if ( routeFavEquipiers ) {
        const json_datas = await fetchEquipiers(routeFavEquipiers)
        if ( json_datas && json_datas["hydra:member"] ) {
          json_datas["hydra:member"].forEach((equipier) => {
            listEquipiers.push(
              {
                id: equipier.hoomyId,
                name: (equipier.hoomyId === user.hoomyId ? 'Moi' : equipier.firstName+" "+equipier.lastName),
                tel: equipier.phone,
                email: equipier.email,
                status: equipier.isActive,
                color: equipier.color,
                fav: true,
                indispos: []
              }
            )
          })
          setEquipiers([...listEquipiers])

          // Enchaine sur la route de tous les utilisateurs
          setRouteEquipiers('/users?pagination=false&isActive=1'+(isCleaner ? '&hoomyIds[]='+user.hoomyId : ''))
        }
      }
    }
  }, [routeFavEquipiers])

  useEffect(() => {
    loadEquipiers()
    async function loadEquipiers() {
      if ( routeEquipiers ) {
        const json_datas = await fetchEquipiers(routeEquipiers)
        if ( json_datas && json_datas["hydra:member"] ) {
          json_datas["hydra:member"].forEach((equipier) => {
            const equipIndex = listEquipiers.findIndex((equip) => equip.id === equipier.hoomyId)
            listEquipiers.push(
              {
                id: equipier.hoomyId,
                name: (equipier.hoomyId === user.hoomyId ? 'Moi' : equipier.firstName+" "+equipier.lastName),
                tel: equipier.phone,
                email: equipier.email,
                status: equipier.isActive,
                color: equipier.color,
                fav: false,
                indispos: []
              }
            )
          })

          // S'il n'est pas dans la liste, on ajoute l'utilisateur courant
          const equipIndex = listEquipiers.findIndex((equip) => equip.id === user.hoomyId)
          if ( equipIndex < 0 ) { // Si équipier existe déjà dans la liste
            listEquipiers.push(
              {
                id: user.hoomyId,
                name: 'Moi',
                fav: false,
                indispos: []
              }
            )
          }

          setEquipiers([...listEquipiers])
        }
      }
    }
  }, [routeEquipiers])


  useEffect(() => {
    const fields = getFields()
    if ( fields && parseInt(fields.values.location) !== init_fields[0].value ) { // field location
      setRouteTaskTypes('/task-types?isOnLocation='+fields.values.location)
    }
  }, [getFields])


  // Liste pour le sélecteur de durées
  // Propose toutes les 15min de 0 à 3h et toutes 30min de 3h à 8h
  let durees = []
  for (let i=1; i<=32; i++) {
    const value = (i/4)
    const check_quart = (value/0.5)
    if ( check_quart <= 6 || (check_quart > 6 && Number.isInteger(check_quart)) ) {
      durees.push({
        value: value,
        label: formatTimeString(value)
      })
    }
  }

  const [routeTaskTypes, setRouteTaskTypes] = useState('/task-types?isOnLocation='+(id_location ? 1 : 0))
  const [fetchTaskTypes] = useTaskTypes()
  const [taskTypes, setTaskTypes] = useState([])
  useEffect(() => {
    loadTaskTypes()
    async function loadTaskTypes() {
      let items = []
      const task_types = await fetchTaskTypes(routeTaskTypes)
      if ( !task_types.error ) {
        task_types['hydra:member'].forEach((item, i) => {
          items.push({
            value: item.id,
            label: item.label
          })
        })
        setTaskTypes([...items])
        init_fields[1].options = items
        init_fields[1].value = ''

        const fields = getFields()
        init_fields[0].value = fields.values.location

        setFields([...init_fields])
      }
    }
  }, [routeTaskTypes])

  const changeTaskDate = (input_date, index) => {
    if ( input_date.$d != 'Invalid Date' ) {
      const datetime = new Date(input_date)
      const format_date = datetime.getFullYear()+'-'+String(datetime.getMonth()+1).padStart(2, '0')+'-'+String(datetime.getDate()).padStart(2, '0')

      const initialDate = new Date(taskDatas.date)
      const format_time = initialDate.toLocaleTimeString('fr-FR', {hour:'2-digit', minute:'2-digit'})

      const date = format_date+'T'+format_time+':00' // '2021-04-21T18:00'
      taskDatas.date = date
      setTaskDatas({...taskDatas})
    }
  }
  const changeTaskTime = (input_date, index) => {
    if ( input_date.$d != 'Invalid Date' ) {
      const initialDate = new Date(taskDatas.date)
      const format_date = initialDate.getFullYear()+'-'+String(initialDate.getMonth()+1).padStart(2, '0')+'-'+String(initialDate.getDate()).padStart(2, '0')

      const datetime = new Date(input_date)
      const format_time = datetime.toLocaleTimeString('fr-FR', {hour:'2-digit', minute:'2-digit'})

      const date = format_date+'T'+format_time+':00' // '2021-04-21T18:00'
      taskDatas.date = date
      setTaskDatas({...taskDatas})
    }
  }
  const changeTaskInput = (e) => {
    const names = e.target.name.split("_")
    const name = names[0]
    const index = names[1]

    taskDatas[name] = e.target.value
    setTaskDatas({...taskDatas})
  }
  const changeTaskEquipierInput = (e) => {
    const names = e.target.name.split("_")
    const name = names[0]
    const index = names[1]
    const sub_index = names[2]

    // On enregistre la modification
    taskDatas.equipiers[sub_index][name] = e.target.value
    setTaskDatas({...taskDatas})
  }

  // Change l'heure d'un équipier
  const changeTaskEquipierTime = (task_index, equipier_index, input_date) => {
    if ( input_date.$d != 'Invalid Date' ) {
      // Récupère la date de la tâche
      const task_date = new Date(taskDatas.date)
      const format_date = task_date.getFullYear()+'-'+String(task_date.getMonth()+1).padStart(2, '0')+'-'+String(task_date.getDate()).padStart(2, '0')

      // Récupère l'heure de l'équipier
      const equipier_time = new Date(input_date)
      const format_time = equipier_time.toLocaleTimeString('fr-FR', {hour:'2-digit', minute:'2-digit'})

      // Combine la date de la tâche avec l'heure de l'équipier
      const date = format_date+'T'+format_time+':00' // '2021-04-21T18:00:00'

      // On enregistre la modification d'heure équipier
      taskDatas.equipiers[equipier_index].heure = date
      setTaskDatas({...taskDatas})
    }
  }

  // Ajoute un équipier
  const addEquipier = (index) => {
    taskDatas.equipiers.push({
      user_id: null,
      equipier: null,
      heure: null,
      duree: null,
      duree_string: '',
      coequipier: true
    })
    setTaskDatas({...taskDatas})
  }

  // Enregistrement des données
  // ---------------------------------------------------------------------------
  const [saveDatas] = useSaveDatas()
  const [saveCommentTask] = useSaveCommentTask()
  const formSubmit = async (e) => {
    e.preventDefault()

    // Réinitialise avant traitement
    setIsFormLoading(true)
    setErrors({})

    // Traitement
    const parameters = getFields()
    if ( Object.keys(parameters.errors).length > 0 ) {
      setIsFormLoading(false)
      setErrors({
        message: "Veuillez compléter ou corriger les champs suivants :",
        fields: parameters.errors
      })
    }
    else {
      // On ajuste les paramètres à envoyer
      const location = parseInt(parameters.values.location)
      if (location === 1 && id_location ) {
        parameters.values.location = id_location
      }
      else {
        delete parameters.values.location
      }
      if (location === 0 && id_renting ) {
        parameters.values.renting = id_renting
      }
      parameters.values.taskEvent = taskDatas.id_event
      parameters.values.taskState = taskDatas.id_state
      parameters.values.startAt = taskDatas.date+'+00:00'
      parameters.values.endAt = taskDatas.date+'+00:00'

      // Sauve la tâches et ses équipiers
      const create_task = await saveDatas('/task-todos', {method: 'POST'}, parameters.values)
      if ( create_task && create_task.error ) { // Si erreur
        setIsFormLoading(false)
        setErrors({
          message: create_task.message,
          fields: create_task.errors
        })
      }
      else {
        // Crée les équipiers
        if ( taskDatas.equipiers.length ) {
          await Promise.all(
            taskDatas.equipiers.map(async (equipier, j) => {
              if ( equipier.equipier ) { // S'il y a un équipier de défini
                let userDatas = {
                  duration: equipier.duree,
                  isTeammate: equipier.coequipier,
                  taskTodo: create_task.id,
                  user: equipier.equipier
                }
                if ( equipier.heure ) {
                  userDatas.teammateStartAt = equipier.heure+(!equipier.heure.includes("+") ? '+00:00' : '')
                }
                const create_user_task = await saveDatas('/user-task-todos', {method: 'POST'}, userDatas)
                if ( create_user_task && create_user_task.error ) { // Si erreur
                  setErrors({
                    message: create_user_task.message,
                    fields: create_user_task.errors
                  })
                }
              }
            })
          )
        }

        // Commentaires (uniquement sur les tâches interventions)
        if ( parameters.values.comment ) { // Mise à jour des commentaires
          // Edition ou Création
          let comment_params = {
            content: parameters.values.comment,
            taskTodo: create_task.id
          }
          const create_comment = await saveCommentTask('/task-comments', {method: 'POST'}, comment_params)
          if ( create_comment && create_comment.error ) { // Si erreur
            setErrors({
              message: create_comment.message,
              fields: create_comment.errors
            })
          }
        } // comment

        // Message de succès
        if ( create_task && !create_task.error ) {
          setIsFormLoading(false)
          setSuccess({
            message: "Votre tâche a été ouverte !\nDans quelques secondes, vous serez automatiquement redirigé vers l'écran de la tâche..."
          })

          // Et on redirige après 3 secondes
          setTimeout(function(e) {
            if ( create_task.location ) {
              const split = create_task.location.split("/locations/")
              window.location = process.env.REACT_APP_URI+'/reservation/'+split[1]+'/'+create_task.id+'/'
            }
            else {
              const split = create_task.renting.split("/rentings/")
              window.location = process.env.REACT_APP_URI+'/logement/'+split[1]+'/interventions/'+create_task.id+'/'
            }
          }, 3000)
        }
      }
    }
  }

  return (
    <main>
      <div className="header">
        <h1>Ajouter une intervention au planning</h1>
      </div>
      <div className={`content hasloader ${isLoading ? 'loadin' : ''}`}>
        {!isLoading &&
          <>
            {isError // Si pas de résultats de la route
            ?
              <NotFound />
            :
              <form id="taskForm"
                    className={`form hasloader ${isFormLoading ? 'loadin' : ''}`}
                    onSubmit={formSubmit}>

                <div className="innerSmallWidth">
                  { showReturnMessage() }

                  { showFields() }
                </div>

                <div className='tasks-editor'>
                  <div className="innerSmallWidth">
                    <TaskReservation index={0}
                                     datas={taskDatas}
                                     listEquipiers={listEquipiers}
                                     durees={durees}
                                     changeInput={changeTaskInput}
                                     changeEquipierInput={changeTaskEquipierInput}
                                     changeEquipierTime={changeTaskEquipierTime}
                                     changeDate={changeTaskDate}
                                     changeTime={changeTaskTime}
                                     addEquipier={addEquipier} />
                  </div>
                </div>

                <div className="form-submit">
                  <button type="submit" className="btn">
                    Ajouter
                  </button>
                </div>

                <Loader />
              </form>
            }
          </>
        }
        <Loader />
      </div>
    </main>
  )
}
