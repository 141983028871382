import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router } from "react-router-dom"
import { AliveScope } from 'react-activation'
import './assets/css/styles.css'
import Hoomy from './Hoomy'
import reportWebVitals from './reportWebVitals'
// import * as serviceWorker from './serviceWorker'

const root = ReactDOM.createRoot(document.getElementById("container"))
root.render(
  <AliveScope>
    <Router basename={`${process.env.REACT_APP_BASE}`}>
      <Hoomy />
    </Router>
  </AliveScope>
)

reportWebVitals()
