/*
 * Routes nécessaires sur cet écran :
 - Route GET Récupération paginée (par 3 jours) des tâches d'un concierge et, si manager, des tâches de ses équipiers
 - Route GET Récupération paginée filtrable par date
 - Route GET Récupération des statuts de tâche ? Ou sinon, simplement en dur dans l'app...
 - Route GET Si manager, Récupération de la liste de ses équipiers
 */

import React, { useState, useEffect } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import useUser from '../hooks/useUser'
import useLoadRenting from '../hooks/useLoadDatas'
import useLoadLocations from '../hooks/useLoadDatas'
import useScroll from '../hooks/useScroll'
import useScrollBottom from '../hooks/useScrollBottom'
import Loader from '../components/Loader'
import Icon from '../components/Icon'
import TaskPlanning from '../components/TaskPlanning'
import NotFound from '../components/NotFound'
import DatePickerCustom from '../components/DatePickerCustom'
import {formatDate, formatReservationDate} from '../functions/functions.js'

export default function Reservations() {
  const { id_renting } = useParams()

  // Détermine le statut de l'utilisateur connecté
  const { user, checkUser, isManager, isConcierge, isCleaner } = useUser()

  const notAccessible = ( !isManager ? true : false )

  const isFixed = useScroll('filters', 'header')

  // Nettoie les classes du planning
  document.getElementsByTagName("body")[0].classList.remove("popin-tasks-active")

  const queryParams = new URLSearchParams(window.location.search)
  let init_date = queryParams.get('date')
  if ( !init_date ) { // Si pas de date en paramètre, on passe la date du jour
    const now_date = formatDate(new Date())
    init_date = now_date.an+"-"+now_date.nmois+"-"+now_date.date
  }
  let init_search = (queryParams.get('rech') ? queryParams.get('rech') : '')
  const [selectedDate, setSelectedDate] = useState(init_date)
  const [search, setSearch] = useState(init_search)

  // Structure la route de requêtes des locations
  const updateRouteLocations = (page, perPage, date, search) => {
    let params = '?order[startAt]=asc&order[hoomyId]=asc'
    if ( id_renting ) {
      params += '&endAt[after]='+date+'&renting='+id_renting
    }
    else {
      params += '&startAt[after]='+date+'&exists[taskTodos]=false'
    }
    if ( perPage ) {
      params += '&itemsPerPage='+perPage
    }
    if ( page ) {
      params += '&page='+page
    }
    if ( search ) {
      params += (params ? '&' : '')+'search='+search
    }
    return '/locations'+params
  }

  // Filtre par date => recharge les données
  const dateChange = (newdate) => {
    const format_date = formatDate(new Date(newdate))
    const new_date = format_date.an+'-'+format_date.nmois+'-'+format_date.date
    setSelectedDate(new_date)

    // Ajuste les routes
    setPage(1)
    setLocations({})
    setIsBottom(false)
    setIsStopped(false)
    setIsLoading(true)

    const url = new URL(window.location)
    url.searchParams.set('date', new_date)
    window.history.pushState({}, '', url)

    setRouteLocations( updateRouteLocations(1, perPage, new_date, search) )
  }

  // Chargement des infos du renting si on est sur l'écran d'un logement spécifique
  // ---------------------------------------------------------------------------
  const [titleRenting, setTitleRenting] = useState('')
  const [bookRenting, setBookRenting] = useState(null)
  const [coordOwnerRenting, setCoordOwnerRenting] = useState(null)
  const routeRenting = '/rentings/'+id_renting
  const [fetchRenting] = useLoadRenting()
  useEffect(() => {
    loadReservations()
    async function loadReservations() {
      if ( id_renting ) {
        const renting_datas = await fetchRenting(routeRenting)
        if ( !renting_datas.error ) {
          // On construit le titre
          setTitleRenting(
            renting_datas.owner.lastName.toUpperCase().trim()
            +(renting_datas.rentingNameAlt ? ' | '+renting_datas.rentingNameAlt.trim() : '')
            +' | '+renting_datas.rentingName.toUpperCase()
          )

          if ( renting_datas.book ) {
            setBookRenting(renting_datas.book)
          }
          setCoordOwnerRenting({
            name: (renting_datas.owner.firstName+" "+renting_datas.owner.lastName).trim(),
            phone: renting_datas.owner.phone,
            email: renting_datas.owner.email
          })
        }
      }
    }
  }, [routeRenting])


  // Chargement des tâches / réservations
  // ---------------------------------------------------------------------------
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)

  const [totalItems, setTotalItems] = useState()
  const [page, setPage] = useState(1)
  const perPage = 20

  // Route locations
  const [routeLocations, setRouteLocations] = useState( updateRouteLocations(page, perPage, selectedDate, search) )
  const [fetchLocations] = useLoadLocations()

  const [locations, setLocations] = useState({})
  const [isBottom, setIsBottom, handleScroll, isStopped, setIsStopped] = useScrollBottom('.content.hasElements') // Scroll infini

  useEffect(() => {
    loadLocations()
    async function loadLocations() {
      const locations_datas = await fetchLocations(routeLocations)
      if ( locations_datas.error ) {
        setIsError(true)
      }
      else {
        // S'il y a des éléments
        if ( locations_datas['hydra:member'] ) {
          locations_datas['hydra:member'].forEach((location, i) => {
            const date = formatDate(new Date(location.startAt))
            const date_split = location.startAt.split("T")
            const date_index = date_split[0].replaceAll('-', '')

            // Si la date n'existe pas dans l'objet, on crée la date
            if ( !locations[date_index] ) {
              locations[date_index] = {
                date: date,
                show_day: false,
                values: []
              }
            }

            // On ajoute la location à la date
            let title = ''
            if ( location.ownerNoCheckIn ) {
              title = 'Occupation propriétaire'
              title += (location.renting.owner.lastName.trim() ? ' '+location.renting.owner.lastName.toUpperCase().trim() : '')
            }
            else {
              title = 'Réservation'
              title += (location.tenant ? ' '+location.tenant.lastName.toUpperCase().trim() : '')
              title += (location.renting.owner.lastName.trim() ? ' | '+location.renting.owner.lastName.toUpperCase().trim() : '')
            }
            if ( !id_renting ) { // Si on est pas sur l'écran d'un logement spécifique
              title += (location.renting.rentingNameAlt ? ' | '+location.renting.rentingNameAlt : '')
              title += (location.renting.rentingName ? ' | '+location.renting.rentingName.toUpperCase() : '')
            }
            locations[date_index].values.push({
              id: location.hoomyId,
              value: title,
              equipier: null,
              status: null,
              comment: null,
              notAccessible: notAccessible,
              services: location.hasOrders,
              rotate: location.isRotate,
              comment: location.hasComments,
              isOwner: location.ownerNoCheckIn,
              date: formatReservationDate(location.startAt, location.endAt),
              classe: "reservation",
              show: true
            })
          })
          setLocations({...locations})
        }

        // MAJ le nombre total d'items
        if ( !totalItems ) {
          setTotalItems(locations_datas['hydra:totalItems'])
        }
      }

      setIsBottom(false)
      setIsLoading(false)
    }
  }, [routeLocations])

  // Gestion du scroll et du chargement de la pagination
  useEffect(() => {
    // console.log(totalItems, '>', perPage * page, page)
    // console.log(isBottom, isStopped, isLoading)
    if ( totalItems ) {
      if ( totalItems > perPage * page ) {
        handleScroll()
        if ( isBottom ) {
          const next_page = page+1
          setPage(next_page)
          setRouteLocations( updateRouteLocations(next_page, perPage, selectedDate, search) )
        }
      }
      else {
        setIsStopped(true)
      }
    }
  }, [isBottom, locations])

  // Soumission de la recherche par la touche Entrée
  const searchKeyPress = (e) => {
    if ( e.key === "Enter" ) {
      submitSearch(e)
    }
  }

  // Execute la requete avec recherche
  const submitSearch = async (e) => {
    e.preventDefault()

    // Seulement si la recherche est différente
    const prev_url = new URL(window.location)
    const prev_search = (prev_url.searchParams.get('rech') ? prev_url.searchParams.get('rech') : '')
    if ( prev_search !== search ) {
      // Ajuste les routes
      setPage(1)
      setLocations({})
      setIsBottom(false)
      setIsStopped(false)
      setIsLoading(true)

      const url = new URL(window.location)
      if ( search ) {
        url.searchParams.set('rech', search)
      }
      else {
        url.searchParams.delete('rech')
      }
      window.history.pushState({}, '', url)

      setRouteLocations( updateRouteLocations(1, perPage, selectedDate, search) )
    }
  }


  return (
    <main className={`planning ${(id_renting ? "logements" : "reservations")}`}>
      <div className="header">
        <h1>{titleRenting ? titleRenting : 'Réservations'}</h1>
        <div id="filters" className={isFixed ? 'fixed' : ''}>
          <div className="innerMediumWidth">
            <DatePickerCustom
              date={selectedDate}
              onChange={(date) => dateChange(date)}
            />

            {!id_renting &&
              <div className="search">
                <div className="input-search">
                  <input id="search" name="search" type="search"
                          onChange={(e) => setSearch(e.target.value)}
                          onKeyPress={(e) => searchKeyPress(e)}
                          placeholder="Recherche..."
                          value={(search ? search : '')} />
                  <button type="button" className="btn-search" onClick={submitSearch}>
                    <Icon icon='loupe' />
                  </button>
                </div>
              </div>
            }
          </div>
        </div>
      </div>

      {id_renting &&
        <div className="admin_links">
          <NavLink exact to={`/logement/${id_renting}/ticket/`} className="admin_link">
            Ouvrir un ticket
          </NavLink>

          <NavLink exact to={`/logement/${id_renting}/intervention/`} className="admin_link">
            Ouvrir une intervention
          </NavLink>

          {(bookRenting && bookRenting.url) &&
            <a href={bookRenting.url} target="_blank" rel="noreferrer" className="admin_link">
              Voir le book
            </a>
          }

          {(bookRenting && bookRenting.advertisementLink) &&
            <a href={bookRenting.advertisementLink} target="_blank" rel="noreferrer" className="admin_link">
              Voir l'annonce en ligne
            </a>
          }
        </div>
      }

      <div className={`content innerMediumWidth ${Object.keys(locations).length > 0 ? 'hasElements' : ''}`}>
        {(id_renting && coordOwnerRenting && isManager) &&
          <div className="coordowner">
            <strong>Coordonnées du propriétaire :</strong>
            {coordOwnerRenting.name &&
              <><br />{coordOwnerRenting.name}</>
            }
            {coordOwnerRenting.phone &&
              <><br />Tél. : <a href={`tel:${coordOwnerRenting.phone}`}>{coordOwnerRenting.phone}</a></>
            }
            {coordOwnerRenting.email &&
              <><br />Email : <a href={`mailto:${coordOwnerRenting.email}`}>{coordOwnerRenting.email}</a></>
            }
          </div>
        }

        <div className="days">
          {Object.keys(locations).length > 0
            ?
            Object.keys(locations).map((key, index) => (
              <div key={index} className='day active'>
                <div className="day-content">
                  <span className="date">{locations[key].date.jour} <strong>{locations[key].date.date}</strong> {locations[key].date.mois}</span>
                  {locations[key].values &&
                    <div className="tasks">
                      {locations[key].values.map((value, index) => (
                        <TaskPlanning key={index} {...value} />
                      ))}
                    </div>
                  }
                </div>
              </div>
            ))
            :
            <>
              {!isLoading &&
                <em className="no-result">Aucune réservation à traiter.</em>
              }
            </>
          }
        </div>
        <div className={`more-tasks hasloader ${(isStopped ? 'hide' : ((isBottom || isLoading) ? 'loadin' : ''))}`}>
          <Loader />
        </div>
      </div>
    </main>
  )
}
