// Librairies React
import React, { useState, useEffect } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import Icon from '../components/Icon'
import useLoadNotifs from '../hooks/useLoadDatas'
import useSaveNotif from '../hooks/useSaveDatas'
import useLoadTokens from '../hooks/useLoadDatas'
import useLoadEquipiers from '../hooks/useLoadDatas'
import useSaveToken from '../hooks/useSaveDatas'
import useChangeEquipier from '../hooks/useSaveDatas'
import {formatNotifDate} from '../functions/functions.js'
import useSound from 'use-sound'
import soundNotif from '../assets/sounds/notification.mp3'

// Firebase
// Projet Hoomy : https://console.firebase.google.com/project/webapp-hoomy/settings/general/web:NWEzN2E0OWMtNmEyNy00MDk5LWEzNjMtMmRhNmIwNDM3Mzhm
// https://levelup.gitconnected.com/show-push-notifications-in-react-449949e98e01
import {requestToken, onMessageListener} from '../firebase/firebase.js'

export default function Header({user, isManager, isConcierge, isCleaner, removeUser, routeBack}) {
  const history = useHistory()

  // Route notifications
  const routeNotifs = '/notifications?order[id]=desc&isRead=false'
  const [loadNotifs, setLoadNotifs] = useState(false)
  const [notifs, setNotifs] = useState([])
  const [countNotifs, setCountNotifs] = useState(0)
  const [soundNotifs, setSoundNotifs] = useState(localStorage.getItem('playSoundNotif'))
  const [fetchNotifs] = useLoadNotifs()
  let newNotifsTimeout

  // Firebase Token
  const [show, setShow] = useState(false)
  const [notification, setNotification] = useState({title: '', body: ''})
  const [tokenFound, setTokenFound] = useState(null)
  const routeTokens = '/subscriptions'
  const [fetchFirebaseTokens] = useLoadTokens()
  const [saveFirebaseToken] = useSaveToken()
  let popupNotifTimeout

  const [playSoundNotif] = useSound(soundNotif)

  onMessageListener()
    .then(payload => {
      // Affiche la notification sur l'app
      // setShow(true)
      // setNotification({title: payload.data.title, body: payload.data.body})
      document.getElementById("toggle-notifs").classList.add("new")
      newNotifsTimeout = window.setTimeout(removeNewNotifs, 2000)
      if ( localStorage.getItem('playSoundNotif') ) {
        playSoundNotif()
      }

      setLoadNotifs(true)
      // popupNotifTimeout = window.setTimeout(hidePopupNotif, 5000)
    })
    .catch(err => console.log('failed: ', err))

  const hidePopupNotif = () => {
    setShow(false)

    if ( popupNotifTimeout ) {
      window.clearTimeout(popupNotifTimeout)
    }
  }
  const removeNewNotifs = () => {
    document.getElementById("toggle-notifs").classList.remove("new")

    if ( newNotifsTimeout ) {
      window.clearTimeout(newNotifsTimeout)
    }
  }

  // Active le chargement des notifications
  useEffect(() => {
    fetchNotifs()
    async function fetchNotifs() {
      if ( !loadNotifs && user && user.token ) {
        // Autorise le chargement des notifications
        setLoadNotifs(true)
      }
      else {
        setNotifs([])
        setCountNotifs(0)
      }
    }
  }, [user])



  // Charge les notifications
  useEffect( () => {
    // Uniquement si l'utilisateur est connecté
    if ( loadNotifs ) {
      if ( user && user.token ) {
        async function asyncFetchNotifs() {
          const json_datas = await fetchNotifs(routeNotifs, user.token)
          if ( json_datas && json_datas["hydra:member"] ) {
            let json_notifs = []
            let count_notifs = 0
            json_datas["hydra:member"].forEach((notif) => {
              const date = formatNotifDate(notif.createdAt)
              if ( !notif.isRead ) {
                json_notifs.push({
                  id: notif.id,
                  date: date,
                  title: notif.title,
                  body: notif.body,
                  // isRead: notif.isRead,
                  link: notif.link
                })

                // Compte les notifications non lues
                count_notifs += 1
              }
            })
            setNotifs([...json_notifs])
            setCountNotifs(count_notifs)

            if ( count_notifs ) {
              newNotifsTimeout = window.setTimeout(removeNewNotifs, 2000)
            }
          }

          // On stoppe le chargement des notifications
          setLoadNotifs(false)
        }
        asyncFetchNotifs()

        // Firebase token
        if ( !tokenFound ) {
          requestToken(setTokenFound)
        }
      }
    }
  }, [loadNotifs, user])

  // Enregistre le token Firebase sur l'API
  useEffect(() => {
    firebaseToken()
    async function firebaseToken() {
      if ( user && user.token ) {
        if ( tokenFound ) {
          const json_token = await fetchFirebaseTokens(routeTokens+"/"+tokenFound, user.token)

          // S'il y a une erreur, c'est que le token n'existe pas
          if ( json_token.error ) {
            saveFirebaseToken('/subscriptions', {user_token: user.token}, {token: tokenFound})
          }
          // listenPermission(tokenFound)
        }
      }
    }
  }, [tokenFound, user])

  // Son notifications toggle
  const toggleSoundNotifs = (e) => {
    e.preventDefault()
    const toggle = (soundNotifs ? false : true)
    setSoundNotifs(toggle)
    localStorage.setItem('playSoundNotif', toggle)
  }

  // Notifications toggle
  const toggleNotifs = (e) => {
    e.preventDefault()
  	document.getElementsByTagName("body")[0].classList.toggle("notif-active")
  }

  // Met à jour la notification cliquée
  const [saveNotif] = useSaveNotif() // '/locations/'+id_location, {method: 'PUT'}
  async function readNotification(index, id_notif) {
    const save_notif = await saveNotif('/notifications/'+id_notif, {method: 'PUT'}, {'isRead': true})
    if ( save_notif.isRead ) {
      // Met à jour le compteur
      const count_notifs = countNotifs-1
      setCountNotifs(count_notifs)

      // Met à jour la notification dans la liste
      // delete notifs.splice(index, 1)
      // setNotifs([...notifs])
      document.getElementsByClassName("notif-"+index)[0].classList.add("hide")
    }
  }

  // Mise à jour de la notification
  const updateNotif = (e, index, id_notif, is_read) => {
    // Met à jour la notification
    readNotification(index, id_notif)

    // Ferme le bloc de notifications
  	document.getElementsByTagName("body")[0].classList.toggle("notif-active")
  }
  // Supprime la notification
  const deleteNotif = (e, index, id_notif, is_read) => {
    setTimeout( function(e) {
      // Met à jour la notification
      readNotification(index, id_notif)
    }, 400)
  }

  // Menu toggle
  const toggleMenu = (e) => {
    e.preventDefault()
  	document.getElementsByTagName("body")[0].classList.toggle("menu-active")
  }

  // Close menu au clic dans le menu
  const closeMenu = (e) => {
    document.getElementsByTagName("body")[0].classList.remove("menu-active")
  }

  // Récupère la liste des équipiers
  const routeEquipiers = '/users?pagination=false&isActive=1&order[firstName]=ASC'
  const [fetchEquipiers] = useLoadEquipiers()
  const [equipiers, setEquipiers] = useState([])

  useEffect(() => {
    loadEquipiers()
    async function loadEquipiers() {
      if ( user && user.role === "ROLE_ADMIN" ) {
        const json_datas = await fetchEquipiers(routeEquipiers, null, null, true)
        if ( json_datas && json_datas["hydra:member"] ) {
          let json_equipiers = [{
            id: user.hoomyId,
            role: user.role,
            name: 'Moi'
          }]
          json_datas["hydra:member"].forEach((equipier) => {
            if ( equipier.role !== "ROLE_ADMIN" ) {
              json_equipiers.push(
                {
                  id: equipier.hoomyId,
                  role: equipier.role,
                  name: equipier.firstName+" "+equipier.lastName+" - "+equipier.email
                }
              )
            }
          })
          setEquipiers([...json_equipiers])
        }
      }
    }
  }, [routeEquipiers])

  const adminUser = JSON.parse(localStorage.getItem('admin_user'))
  const [changeTokenEquipier] = useChangeEquipier()
  const changeEquipier = async (e) => {
    const user_id = parseInt(e.target.value)

    // Si utilisateur est l'admin
    if ( user_id === user.hoomyId ) {
      localStorage.removeItem('admin_user')

      // Recharge l'écran pour regénérer les requêtes
      window.location.reload()
    }

    // Si changement d'utilisateur
    else {
      const save_equipier = await changeTokenEquipier('/users/admin/get-token', {method: 'POST'}, {hoomyId: user_id}, true)
      if ( !save_equipier.error ) {
        const userIndex = equipiers.findIndex((item) => item.id === user_id)
        localStorage.setItem('admin_user', JSON.stringify({
          user: user_id,
          role: equipiers[userIndex].role,
          token: save_equipier.token
        }))

        // Recharge l'écran pour regénérer les requêtes
        window.location.reload()
      }
    }
  }

  return (
    <>
      <header id="header" className="site-header" role="banner" itemScope itemType="http://schema.org/WPHeader">
        <NavLink exact to="/" id="logo_link" className={!user ? 'disabled' : ''} itemProp="url">
          <img src={`${process.env.REACT_APP_URI}/images/logo-hoomy.svg`}
               width="150" height="64" alt="Hoomy - Conciergerie" />
        </NavLink>

        {user &&
          <>
            <nav id="site-navigation" role="navigation" itemScope itemType="http://schema.org/SiteNavigationElement">
              <ul id="menu-menu-principal" className="main-menu">
                <li className="menu-item menu-bienvenue">
                  <span>Bonjour {user.firstName} !</span>
                </li>
                {user.role === "ROLE_ADMIN" &&
                  <li className="menu-item-admin">
                    <select name="user_select" onChange={changeEquipier} value={(adminUser ? adminUser.user : user.id)}>
                      {equipiers.map((equipier, index) => {
                        return (
                          <option key={index}
                                  value={equipier.id}>
                            {equipier.name}
                          </option>
                        )
                      })}
                    </select>
                  </li>
                }
                {isManager
                ?
                  <>
                    <li className="menu-item">
                      <NavLink exact to="/" itemProp="url" onClick={closeMenu}>
                        <span itemProp="name">Plannings</span>
                      </NavLink>
                    </li>
                    <li className="menu-item">
                      <NavLink exact to="/reservation/" itemProp="url" onClick={closeMenu}>
                        <span itemProp="name">Réservations</span>
                      </NavLink>
                    </li>
                    <li className="menu-item">
                      <NavLink exact to="/logement/" itemProp="url" onClick={closeMenu}>
                        <span itemProp="name">Logements</span>
                      </NavLink>
                    </li>
                    <li className="menu-item">
                      <NavLink exact to="/supra-planning/" itemProp="url" onClick={closeMenu}>
                        <span itemProp="name">Supra planning</span>
                      </NavLink>
                    </li>
                    <li className="menu-item">
                      <NavLink exact to="/interventions-facturables/" itemProp="url" onClick={closeMenu}>
                        <span itemProp="name">Services à refacturer</span>
                      </NavLink>
                    </li>
                    <li className="menu-item">
                      <NavLink exact to="/ticket/" itemProp="url" onClick={closeMenu}>
                        <span itemProp="name">Tickets</span>
                      </NavLink>
                    </li>
                    <li className="menu-item">
                      <NavLink exact to="/equipage/" itemProp="url" onClick={closeMenu}>
                        <span itemProp="name">Équipage</span>
                      </NavLink>
                    </li>
                  </>
                :
                  <>
                    <li className="menu-item">
                      <NavLink exact to="/" itemProp="url" onClick={closeMenu}>
                        <span itemProp="name">Votre planning</span>
                      </NavLink>
                    </li>
                    <li className="menu-item">
                      <NavLink exact to="/logement/" itemProp="url" onClick={closeMenu}>
                        <span itemProp="name">Logements</span>
                      </NavLink>
                    </li>
                    <li className="menu-item">
                      <NavLink exact to="/ticket/" itemProp="url" onClick={closeMenu}>
                        <span itemProp="name">Tickets</span>
                      </NavLink>
                    </li>
                    <li className="menu-item">
                      <NavLink exact to="/equipage/" itemProp="url" onClick={closeMenu}>
                        <span itemProp="name">Équipage</span>
                      </NavLink>
                    </li>
                  </>
                }
                <li className="menu-item">
                  <button className="menu-close" onClick={() => removeUser()}>
                    <span>Déconnexion</span>
                  </button>
                </li>
              </ul>
            </nav>

            <div id="notifications">
              <div className="content">
                <div id="notif-header">
                  <strong>Vos notifications</strong>
                  <div className="buttons">
                    <button id="notif-sound" className={!soundNotifs ? 'disabled' : ''} onClick={toggleSoundNotifs}>
                      {soundNotifs
                      ?
                        <Icon icon='sound-on' />
                      :
                        <Icon icon='sound-off' />
                      }
              			</button>
              			<button id="notif-close" onClick={toggleNotifs}>
                      <Icon icon='close' />
              			</button>
            			</div>
                </div>

                <div className="notifs">
                  {notifs.length > 0
                  ?
                    notifs.map((notif, index) => {
                      return (
                        <div className={`notif notif-${index}`} key={index}>
                          <NavLink exact to={notif.link} itemProp="url"
                                   onClick={(e) => updateNotif(e, index, notif.id)}>
                            <strong className="title">{notif.title}</strong>
                            <em className='date'>{notif.date}</em>
                            {notif.body &&
                              React.createElement('span', {style:{whiteSpace: "pre-wrap"}}, notif.body)
                            }
                          </NavLink>
                          <button onClick={(e) => deleteNotif(e, index, notif.id)}>
                            <Icon icon='check' />
                          </button>
                        </div>
                      )
                    })
                  :
                    <em className="error">Vous n'avez pas de notification.</em>
                  }
                </div>
              </div>
            </div>

            <div id="header-right">
        			<button id="toggle-notifs" className={countNotifs > 0 ? 'new' : ''} onClick={toggleNotifs}>
        				<Icon icon='notification' />
                {countNotifs > 0 &&
                  <span className='counter'>{countNotifs}</span>
                }
        			</button>

        			<button id="toggle-menu" onClick={toggleMenu}>
        				<span>Menu</span>
                <strong>
                  <span></span>
        			  </strong>
        			</button>
            </div>

            <div id="back" className={`back ${routeBack ? 'active' : ''}`}
                 onClick={(e) => { (routeBack === 'goback' ? history.goBack() : history.push(routeBack)) }}>
              <Icon icon='back' />
            </div>
          </>
        }
      </header>

    </>
  )
}
