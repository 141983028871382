/*
 * Routes nécessaires sur cet écran :
 - Route GET Récupération paginée (par 3 jours) des tâches d'un concierge et, si manager, des tâches de ses équipiers
 - Route GET Récupération paginée filtrable par date
 - Route GET Récupération des statuts de tâche ? Ou sinon, simplement en dur dans l'app...
 - Route GET Si manager, Récupération de la liste de ses équipiers
 */

import React, { useState, useEffect } from 'react'
import useUser from '../hooks/useUser'
import useLoadTasks from '../hooks/useLoadDatas'
import useLoadTypes from '../hooks/useLoadDatas'
import useScroll from '../hooks/useScroll'
import Loader from '../components/Loader'
import Icon from '../components/Icon'
import TaskPlanning from '../components/TaskPlanning'
import DatePickerCustom from '../components/DatePickerCustom'
import {formatDate, formatTime} from '../functions/functions.js'

export default function TasksBillable() {

  const savedDatas = localStorage.getItem('savedatas')
  const savedTasks = (savedDatas ? JSON.parse(atob(savedDatas)) : null)

  // Détermine le statut de l'utilisateur connecté
  const { user, checkUser, isManager, isConcierge, isCleaner } = useUser()

  const isFixed = useScroll('filters', 'header')

  const queryParams = new URLSearchParams(window.location.search)
  let init_date = queryParams.get('date')
  // if ( !init_date ) { // Si pas de date en paramètre, on passe la date du jour
  //   const now_date = formatDate(new Date())
  //   init_date = now_date.an+"-"+now_date.nmois+"-"+now_date.date
  // }
  let init_statut = (queryParams.get('statut') !== null ? queryParams.get('statut') : 'closed')
  let init_type = (queryParams.get('type') !== null ? queryParams.get('type') : '1')
  let init_search = (queryParams.get('rech') ? queryParams.get('rech') : '')
  let init_page = (queryParams.get('page') ? queryParams.get('page') : '')

  const [selectedDate, setSelectedDate] = useState(init_date)
  const [selectedStatus, setSelectedStatus] = useState(init_statut)
  const [selectedType, setSelectedType] = useState(init_type) // Type de facturation
  const [search, setSearch] = useState(init_search)
  const [page, setPage] = useState((init_page ? init_page : 1))
  const perPage = 20

  // Structure la route de requêtes des tâches facturables
  const updateRouteTasks = (page, perPage, date, search) => {
    let params = '?pagination=false&taskType.isBillable=true&startAt[after]='+date+'&order[startAt]=asc&order[id]=asc&order[taskEvent.id]=desc'
    // if ( perPage ) {
    //   params += (params ? '&' : '')+'itemsPerPage='+perPage
    // }
    // if ( page ) {
    //   params += (params ? '&' : '')+'page='+page
    // }
    if ( search ) {
      params += (params ? '&' : '')+'search='+search
    }
    return '/task-todos'+params
  }

  // Filtre par date => recharge les données
  const dateChange = (newdate) => {
    const format_date = formatDate(new Date(newdate))
    const new_date = format_date.an+'-'+format_date.nmois+'-'+format_date.date
    setSelectedDate(new_date)

    // Ajuste les routes
    setPage(1)
    setTasks([])
    setIsLoading(true)

    const url = new URL(window.location)
    url.searchParams.set('date', new_date)
    window.history.pushState({}, '', url)

    setRouteTasks( updateRouteTasks(1, perPage, new_date, search) )
  }

  // Filtre par statut => filtre d'interface
  const filterByStatus = (e) => {
    // Met à jour le statut
    setSelectedStatus(e.target.value)

    // Met à jour la visibilité des tickets
    refreshTasks(e.target.value, selectedType)

    const url = new URL(window.location)
    url.searchParams.set('statut', e.target.value)
    window.history.pushState({}, '', url)
  }

  // Filtre par statut => filtre d'interface
  const filterByType = (e) => {
    // Met à jour le type de facturation
    setSelectedType(e.target.value)

    // Met à jour la visibilité des tickets
    refreshTasks(selectedStatus, e.target.value)

    const url = new URL(window.location)
    url.searchParams.set('type', e.target.value)
    window.history.pushState({}, '', url)
  }

  const refreshTasks = (selectedStatus, selectedType) => {
    // Met à jour les tickets
    tasks.forEach((day, i) => {
      let show_day = false
      day.values.forEach((task, i) => {
        if ( (!selectedStatus && !selectedType)
            || (selectedStatus === task.status && !selectedType)
            || (task.billing === parseInt(selectedType) && !selectedStatus)
            || (selectedStatus === task.status && task.billing === parseInt(selectedType))
          ) {
          task.show = true
          show_day = true
        }
        else {
          task.show = false
        }
      })
      day.show_day = show_day
    })
    setTasks([...tasks])
  }

  // Soumission de la recherche par la touche Entrée
  const searchKeyPress = (e) => {
    if ( e.key === "Enter" ) {
      submitSearch(e)
    }
  }

  // Execute la requete avec recherche
  const submitSearch = async (e) => {
    e.preventDefault()

    // Seulement si la recherche est différente
    const prev_url = new URL(window.location)
    const prev_search = (prev_url.searchParams.get('rech') ? prev_url.searchParams.get('rech') : '')
    if ( prev_search !== search ) {
      // Ajuste les routes
      setPage(1)
      setTasks([])
      setIsLoading(true)

      const url = new URL(window.location)
      url.searchParams.set('rech', search)
      window.history.pushState({}, '', url)

      setRouteTasks( updateRouteTasks(1, perPage, selectedDate, search) )
    }
  }


  // Chargement des équipiers
  // ---------------------------------------------------------------------------
  const [routeTypes, setRouteTypes] = useState('/task-billings')
  const [types, setTypes] = useState([])
  const [fetchTypes] = useLoadTypes()
  useEffect(() => {
    loadTypes()
    async function loadTypes() {
      const json_datas = await fetchTypes(routeTypes)
      if ( json_datas && json_datas["hydra:member"] ) {
        let json_types = []
        json_datas["hydra:member"].forEach((type) => {
          json_types.push(
            {
              id: type.id,
              label: type.label
            }
          )
        })
        setTypes([...json_types])
      }
    }
  }, [routeTypes])


  // Chargement des tâches / réservations
  // ---------------------------------------------------------------------------
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)

  // Route tasks
  const [routeTasks, setRouteTasks] = useState( updateRouteTasks(page, perPage, selectedDate, search) )
  const [fetchTasks] = useLoadTasks()

  const [tasks, setTasks] = useState([])

  useEffect(() => {
    loadTasks()
    async function loadTasks() {
      const tasks_datas = await fetchTasks(routeTasks)

      if ( tasks_datas.error ) { // || rentings_datas.error ) {
        setIsError(true)
      }
      else {
        // S'il y a des éléments
        if ( tasks_datas['hydra:member'] ) {
          tasks_datas['hydra:member'].forEach((task, i) => {
            const startDate = task.startAt
            const date = formatDate(new Date(startDate))
            const date_split = startDate.split("T")
            const date_index = date_split[0].replaceAll('-', '')

            // Si la date n'existe pas dans l'objet, on crée la date
            let index = tasks.findIndex((task) => task.date_index === date_index)
            if ( index < 0 ) {
              index = tasks.length
              tasks.push({
                date_index: date_index,
                date: date,
                show_day: false,
                values: []
              })
            }

            // On ajoute la location à la date
            let title = task.taskEvent.label
            if ( task.location ) {
              title += (task.location.tenant ? ' '+task.location.tenant.lastName.toUpperCase().trim() : '')
              title += (task.location.renting.owner.lastName.trim() ? ' | '+task.location.renting.owner.lastName.toUpperCase().trim() : '')
              title += (task.location.renting.rentingNameAlt ? ' | '+task.location.renting.rentingNameAlt : '')
              title += (task.location.renting.rentingName ? ' | '+task.location.renting.rentingName.toUpperCase() : '')
              if ( task.taskType && task.taskEvent.id === 3 ) {
                title += ' | '+task.taskType.label
              }
              else {
                title += (task.name ? ' | '+task.name.trim() : '')
              }
            }
            else if ( task.renting ) {
              title += (task.renting.owner.lastName.trim() ? ' | '+task.renting.owner.lastName.toUpperCase().trim() : '')
              title += (task.renting.rentingNameAlt ? ' | '+task.renting.rentingNameAlt : '')
              title += (task.renting.rentingName ? ' | '+task.renting.rentingName.toUpperCase() : '')
              if ( task.taskType && task.taskEvent.id === 3 ) {
                title += ' | '+task.taskType.label
              }
              else {
                title += (task.name ? ' | '+task.name.trim() : '')
              }
            }
            else if ( task.ticket ) {
              title += (task.ticket.renting.owner.lastName.trim() ? ' | '+task.ticket.renting.owner.lastName.toUpperCase().trim() : '')
              title += (task.ticket.renting.rentingNameAlt ? ' | '+task.ticket.renting.rentingNameAlt : '')
              title += (task.ticket.renting.rentingName ? ' | '+task.ticket.renting.rentingName.toUpperCase() : '')
              title += (task.name ? ' | '+task.name.trim() : '')
            }

            let title_more = (task.userTaskTodos.length === 0 ? 'Non attribué' : '')

            let equipiers = []
            let color = ''
            let duree = 0
            task.userTaskTodos.forEach((userTaskTodo, i) => {
              // Si on est le user, on récupère la durée de la tâche
              if ( userTaskTodo.user.hoomyId === user.hoomyId ) {
                duree = parseFloat(userTaskTodo.duration)
              }
              // if ( !color ) {
              //   color = userTaskTodo.user.color
              // }
              if ( !userTaskTodo.isTeammate ) {
                if ( isManager ) {
                  duree = parseFloat(userTaskTodo.duration)
                }
                color = userTaskTodo.user.color
              }
              equipiers.push(userTaskTodo.user.hoomyId)
            })

            // On ajoute l'heure de début et fin selon la durée de la tâche pour l'équipier
            if ( startDate ) {
              const time = formatTime(new Date(startDate), duree)
              title += (time ? ' | '+time.debut + (time.fin ? ' - '+time.fin : '') : '')
            }

            // Ajoute la tâche à la liste
            tasks[index].values.push({
              id: (task.location ? task.location.hoomyId : null),
              id_renting: (task.renting ? task.renting.hoomyId : null),
              id_ticket: (task.ticket ? task.ticket.id : null),
              id_task: task.id,
              type: task.taskEvent.id,
              billing: (task.taskBilling ? task.taskBilling.id : null),
              equipiers: equipiers,
              color: color,
              value: title,
              value_more: title_more,
              status: (task.checkedEvent ? 'closed' : 'in_progress'),
              services: task.hasOrders,
              rotate: task.isRotate,
              comment: (task.location && task.location.commentUser ? true : false),
              classe: "planning"+(task.userTaskTodos.length === 0 ? " none" : ""),
              show: false
            })
          })

          // Met à jour les tâches selon les filtres actifs au chargement
          refreshTasks(selectedStatus, selectedType)
        }
      }

      setIsLoading(false)
    }
  }, [routeTasks])

  return (
    <main className="planning">
      <div className="header">
        <h1>Interventions facturables</h1>
        <div id="filters" className={isFixed ? 'fixed' : ''}>
          <div className="innerMediumWidth">
            <DatePickerCustom
              date={selectedDate}
              onChange={(date) => dateChange(date)}
            />

            <div className="status">
              <label htmlFor="status">statut</label>
              <select id="status" name="status"
                      onChange={(e) => filterByStatus(e)}
                      value={(selectedStatus ? selectedStatus : '')}>
                <option value="">Toutes</option>
                <option value="in_progress">À réaliser</option>
                <option value="closed">Clôturées</option>
              </select>
            </div>

            <div className="type">
              <label htmlFor="type">type de facturation</label>
              <select id="type" name="type"
                      onChange={(e) => filterByType(e)}
                      value={(selectedType ? selectedType : '')}>
                <option value="">Tous</option>
                {types.length &&
                  types.map((type, index) => {
                    return (
                      <option key={index} value={type.id}>
                        {type.label}
                      </option>
                    )
                  })
                }
              </select>
            </div>

            <div className="search">
              <div className="input-search">
                <input id="search" name="search" type="search"
                        onChange={(e) => setSearch(e.target.value)}
                        onKeyPress={(e) => searchKeyPress(e)}
                        placeholder="Recherche..."
                        value={(search ? search : '')} />
                <button type="button" className="btn-search" onClick={submitSearch}>
                  <Icon icon='loupe' />
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className={`content innerMediumWidth ${tasks.length > 0 ? 'hasElements' : ''}`}>
        <div className="days">
          {tasks.length > 0
            ?
            tasks.map((task, index) => (
              <div key={index} className={`day${task.show_day ? ' active' : ''}`}>
                <div className="day-content">
                  <span className="date">{task.date.jour} <strong>{task.date.date}</strong> {task.date.mois}</span>
                  {task &&
                    <div className="tasks">
                      {task.values.map((value, index) => (
                        <TaskPlanning key={index} {...value} />
                      ))}
                    </div>
                  }
                </div>
              </div>
            ))
            :
            <>
              {!isLoading &&
                <em className="no-result">Aucune tâche à traiter.</em>
              }
            </>
          }
        </div>
        <div className={`more-tasks hasloader ${(isLoading ? 'loadin' : 'hide')}`}>
          <Loader />
        </div>
      </div>
    </main>
  )
}
