import React, { useState, useEffect } from 'react'
import useLoadDatas from '../../hooks/useLoadDatas'
import ReservationContent from '../ReservationContent'
import TaskWithoutReservationContent from '../TaskWithoutReservationContent'
import TicketContent from '../TicketContent'
import Icon from '../Icon'
import useBlockHistory from '../../hooks/useBlockHistory'

export default function PopinTasks(props) {
  const [title, setTitle] = useState('') // Titre de la popin
  const [tabs, setTabs] = useState([]) // Tableau des onglets
  const [currentTab, setCurrentTab] = useState(null) // Onglet courant
  const [currentTask, setCurrentTask] = useState(null) // Tâche courante
  const {hasChanges, setHasChanges, gotoUrl, setGotoUrl, saveBeforeContinue, continueWithoutSaving} = useBlockHistory()

  useEffect(() => {
    loadData()
    async function loadData() {
      if ( props.datas ) {
        if ( props.datas.title ) {
          setTitle(props.datas.title)
        }
        if ( props.datas.tasks ) {
          setTabs([...props.datas.tasks])
          setCurrentTask({...props.datas.tasks[0]})
          setCurrentTab(0)
        }
      }
    }
  }, [props.datas])

  const loadTab = (task, i) => {
    setCurrentTask(null)
    setCurrentTab(i)
  }

  useEffect(() => {
    changeTask()
    async function changeTask() {
      // Si on est dans le cas d'un changement d'onglet
      if ( props.datas && !currentTask ) {
        setCurrentTask({...props.datas.tasks[currentTab]})
      }
    }
  }, [currentTab])

  // Close popin
  const closePopinTasks = (e) => {
    if ( e ) {
      e.preventDefault()
    }

  	document.getElementsByTagName("body")[0].classList.remove("popin-tasks-active")
    setCurrentTask(null)
  }

  const nothing = () => {
    return // On ne gère pas le titre sur la popin
  }

  return (
    <div id='popin-tasks' className={props.datas ? 'open' : ''}>
      <div id="popin-content" className="content">
        <div id="popin-tasks-header">
          <strong>{title}</strong>
          <div className="buttons">
            <button id="notif-close" onClick={closePopinTasks}>
              <Icon icon='close' />
            </button>
          </div>
        </div>

        <div className="locations">
          {(tabs && tabs.length > 1) &&
            <div className="tabs">
              {tabs.map( (tab, i) => {
                  return (
                    <div key={i} className={`tab ${(i === currentTab ? 'active' : '')}`} onClick={(e) => loadTab(tab, i)}>
                      {tab.name}
                    </div>
                  )
                })
              }
            </div>
          }

          {currentTask &&
            <>
              {currentTask.location &&
                <ReservationContent
                  currentTab={currentTab}
                  id_location={currentTask.location}
                  id_task={currentTask.id}
                  setTitle={nothing}
                  gotoUrl={gotoUrl}
                  setGotoUrl={setGotoUrl}
                  hasChanges={hasChanges}
                  saveBeforeContinue={saveBeforeContinue}
                  continueWithoutSaving={continueWithoutSaving}
                  setHasChanges={setHasChanges}
                  fromPlanningBoard={true}
                  setIsSaved={props.updateDatas}
                  closePopinTasks={closePopinTasks} />
              }
              {currentTask.renting &&
                <TaskWithoutReservationContent
                  currentTab={currentTab}
                  id_renting={currentTask.renting}
                  id_task={currentTask.id}
                  setTitle={nothing}
                  fromPlanningBoard={true}
                  setIsSaved={props.updateDatas}
                  closePopinTasks={closePopinTasks} />
              }
              {currentTask.ticket &&
                <TicketContent
                  currentTab={currentTab}
                  id_ticket={currentTask.ticket}
                  id_task={currentTask.id}
                  setTitle={nothing}
                  setObjet={nothing}
                  fromPlanningBoard={true}
                  setIsSaved={props.updateDatas}
                  closePopinTasks={closePopinTasks} />
              }
            </>
          }
        </div>
      </div>
    </div>
  )
}
