import React from 'react'
import Icon from './Icon'

export default function GPSUrl({adresse, lat, lng, label}) {
  const getGPSForOS = (lat, lng) => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera
    const adr = encodeURIComponent(adresse).replaceAll('%20', '+')

    if (/android/i.test(userAgent)) {
      // https://developers.google.com/maps/documentation/ios-sdk/urlscheme#display_directions
      // return "comgooglemaps://?center="+lat+","+lng+"&zoom=14&views=traffic"
      // return "geo:0,0?q="+lat+","+lng
      return "geo:0,0?q="+adr
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      // return "maps://?q="+lat+","+lng
      return "maps://?q="+adr
    }

    // Sinon envoie sur google maps
    return "https://www.google.fr/maps/?q="+adr
    // return "https://www.google.fr/maps/place/"+adr // +"/@"+lat+","+lng+",14z"
  }

  return (
    <a href={getGPSForOS(lat, lng)} target="_blank" rel="noreferrer" className="btn btn-icon">
      <span>
        {label}
        <Icon icon='gps' />
      </span>
    </a>
  )
}
