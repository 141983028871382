import React, { useState } from 'react'
import imageCompression from 'browser-image-compression'
import Loader from './Loader'
import Icon from './Icon'

export default function InputFiles(props) {
  const [isLoading, setIsLoading] = useState(false)
  const [bigFile, setBigFile] = useState(null)

  const addFiles = async (event) => {
    setIsLoading(true)
    // S'il y a des fichiers à importer
    if ( event.target.files ) {
      let new_files = []
      let new_blobs = JSON.parse(localStorage.getItem('files'))
      const options = {
        maxSizeMB: .25,          // (default: Number.POSITIVE_INFINITY)
        maxWidthOrHeight: 1280,   // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
        useWebWorker: true,      // optional, use multi-thread web worker, fallback to run in main-thread (default: true)
        // onProgress: Function,       // optional, a function takes one progress argument (percentage from 0 to 100)
        // maxIteration: number,       // optional, max number of iteration to compress the image (default: 10)
        // exifOrientation: number,    // optional, see https://stackoverflow.com/a/32490603/10395024
        // fileType: string,           // optional, fileType override
        initialQuality: .7      // optional, initial quality value between 0 and 1 (default: 1)
      }
      // const files_size = files.length
      await Promise.all( Array.from(event.target.files).map(async (file, index) => {
        options.fileType = file.type
        let fileObject = await addFile(file, options)
        new_files.push({
          id: null,
          src: fileObject.file,
          // blob: fileObject.blob
        })
        new_blobs.push(fileObject.blob)
      }))

      localStorage.setItem('files', JSON.stringify(new_blobs))

      props.onChangeFiles(new_files) // Met à jour les fichiers sur le parent

      // setFiles([...files, ...new_files])
      setIsLoading(false)

      // Nettoie l'input des nouveaux fichiers
      event.target.value = ""
    }
  }

  const addFile = async (file, options) => {
    try {
      const compFile = await imageCompression(file, options)
      // console.log("Compress:", Math.round(file.size/1024/1024*100)/100+"mb",
      //             ">>>", Math.round(compFile.size/1024/1024*100)/100+"mb")
      const blob = new Blob([compFile], {type: compFile.type})
      return new Promise((resolve, reject) => {
        let fileReader = new FileReader()
        fileReader.onload = function(e) {
          resolve({
            file: new File([compFile], compFile.name, {type: compFile.type}),
            blob: e.target.result
          })
        }
        fileReader.onerror = reject
        fileReader.readAsDataURL(blob)
      })

    }
    catch (error) {
      console.log(error)
    }
  }

  const popupFile = (index, file) => {
    var img = new Image()
    img.src = JSON.parse(localStorage.getItem('files'))[index] // file.blob
    img.onload = function() {
      const window_width = window.innerWidth-60 // marges: -60
      const window_height = window.innerHeight-120 // header: -60 marges: -60

      let width = 0
      let height = 0

      // Si largeur > hauteur, on se base sur la largeur
      if ( img.width > img.height ) {
        width = window_width
        height = parseInt(img.height * width / img.width)

        if ( height > window_height ) {
          width = width * window_height / height
          height = window_height
        }
      }
      // Sinon, hauteur > largeur, on se base sur la hauteur
      else {
        height = window_height
        width = parseInt(img.width * height / img.height)

        // Si l'image est trop grande par rapport à l'écran
        if ( width > window_width ) {
          height = height * window_width / width
          width = window_width
        }
      }

      setBigFile({
        src: img.src,
        width: width,
        height: height
      })
    }
  }
  const closePopup = () => {
    setBigFile("")
  }

  return (
    <>
      <input id={props.id} name={props.name}
             type="file" multiple accept="image/*"
             disabled={props.disabled}
             onChange={(e) => addFiles(e)} />

      <button type="button" className="btn"
              onClick={() => document.getElementById(props.id).click()}
              disabled={props.files.length < 10 ? false : true}>
        {props.label}
      </button>

      <div className={`temp_files hasloader ${isLoading ? 'loadin active' : ''} ${props.files.length ? 'active' : ''}`}>
        {props.files &&
          props.files.map((file, index) => {
            return (
              <div key={index} className="temp_file">
                <img src={JSON.parse(localStorage.getItem('files'))[index]} width="100" height="100" alt="" onClick={() => popupFile(index, file)} />
                {!file.id &&
                  <button type="button" className="del_file" onClick={() => props.onRemoveFile(index)}>
                    <Icon icon="close" />
                  </button>
                }
              </div>
            )
          })
        }
        <Loader />
      </div>

      <div className={`popup_file ${bigFile ? "show" : ""}`}>
        <div className="popin_file">
          {bigFile &&
            <img src={bigFile.src} width={bigFile.width} height={bigFile.height} alt="" />
          }

          <button className="close" onClick={closePopup}>
            <Icon icon="close" />
          </button>
        </div>
        <div className="popout" onClick={closePopup}></div>
      </div>
    </>
  )
}
