import React, { useState, useEffect } from 'react'
import { NavLink, useParams, useHistory } from 'react-router-dom'
import useUser from '../hooks/useUser'
import useScroll from '../hooks/useScroll'
import useLoadDatas from '../hooks/useLoadDatas'
import DatePickerCustom from '../components/DatePickerCustom'
import {formatDate, formatTime} from '../functions/functions.js'
import {MultipleContainers} from '../components/PlanningBoard/MultipleContainers'
import DayContainer from '../components/PlanningBoard/DayContainer'
import {rectSortingStrategy} from '@dnd-kit/sortable'
import { MultiSelect } from 'react-multi-select-component'
import PopinTasks from '../components/PlanningBoard/PopinTasks'
import Icon from '../components/Icon'
import Loader from '../components/Loader'

export default function PlanningBoard() {

  // Détermine le statut de l'utilisateur connecté
  const { user, checkUser, isManager, isConcierge, isCleaner } = useUser()

  const isFixed = useScroll('filters', 'header')

  // Relation entre la vue sélectionnée et le nombre de jours à afficher
  const nb_days = {
    'jour': 1,
    'semaine': 14,
    'mois': 30
  }
  const one_day = 86400000 // One day Time in ms (milliseconds)

  const cpl_datetime = "T00:00:00%2B02:00"
  const cpl_datetime_max = "T23:59:59%2B02:00"

  // Liste des types de tâches
  const list_types = [
    {
      value: '1',
      label: 'Accueil'
    },
    {
      value: '2',
      label: 'Départ'
    },
    {
      value: '7',
      label: 'Ménage de fin de séjour'
    },
    {
      value: 'location',
      label: 'Intervention réservation'
    },
    {
      value: 'renting',
      label: 'Intervention hors réservation'
    },
    {
      value: 'ticket',
      label: 'Intervention ticket'
    }
  ]

  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)

  const queryParams = new URLSearchParams(window.location.search)
  let init_vue = (queryParams.get('vue') ? queryParams.get('vue') : 'mois')
  let init_equipiers = (queryParams.get('equipiers') ? queryParams.get('equipiers').split(',') : [])
  let init_type_task = (queryParams.get('type') ? queryParams.get('type') : null)
  let init_date = queryParams.get('date')
  let init_last_date = null
  if ( init_date ) { // Si date définie
    const start_date = new Date(init_date)
    const lastDate = (start_date.getTime() + one_day * nb_days[init_vue])
    const format_last_date = formatDate(new Date(lastDate))
    init_last_date = format_last_date.an+"-"+format_last_date.nmois+"-"+format_last_date.date
  }
  else { // Si pas de date en paramètre, on passe la date du jour
    const now_date = new Date()
    const format_now_date = formatDate(new Date())
    init_date = format_now_date.an+"-"+format_now_date.nmois+"-"+format_now_date.date

    const lastDate = (now_date.getTime() + one_day * nb_days[init_vue])
    const format_last_date = formatDate(new Date(lastDate))
    init_last_date = format_last_date.an+"-"+format_last_date.nmois+"-"+format_last_date.date
  }

  const [selectedVue, setSelectedVue] = useState(init_vue)
  const [selectedEquipiers, setSelectedEquipiers] = useState([])
  const [selectedTypeTask, setSelectedTypeTask] = useState(init_type_task)
  const [selectedDate, setSelectedDate] = useState(init_date)
  const [lastDate, setLastDate] = useState((init_vue === 'jour' ? init_date : init_last_date))
  const [zoom, setZoom] = useState(1)
  const [refresh, setRefresh] = useState(false)
  const [dayRefresh, setDayRefresh] = useState(false)

  const abortController = new AbortController();

  // Filtre par équipier/planning
  const filterEquipiers = (select_equipiers) => {
    if ( selectedVue === 'mois' || selectedVue === 'semaine' ) {
      // On détecte s'il y a eu suppression de la liste d'équipiers sélectionnés
      // Compare avec les équipiers sélectionnés avant modification
      let difference = selectedEquipiers.filter(x => !select_equipiers.includes(x));
      if ( difference.length ) {
        // Si suppression d'équipier, on supprime l'équipier du calendrier
        let newCalendarEquipiers = calendarEquipiers
        let eqIndex = newCalendarEquipiers.users.items.findIndex((equipier) => equipier.id === difference[0].value)
        newCalendarEquipiers.users.items.splice(eqIndex, 1)

        // MAJ le calendrier
        setCalendarEquipiers({...newCalendarEquipiers})
      }
      else {
        // Sinon, on ajoute l'équipier
        const new_equipier = select_equipiers.at(-1)

        // Modification de la requête pour récupérer les données équipier
        const minDate = selectedDate+cpl_datetime
        const maxDate = lastDate+cpl_datetime_max
        setRouteCalendarEquipiers('/users?pagination=false&isActive=1&withMe=1&hoomyIds[]='+new_equipier.value+'&withEvents=true&events.timeMin='+minDate+'&events.timeMax='+maxDate)
      }

      let param_equipiers = ''
      select_equipiers.forEach((equipier, i) => {
        param_equipiers += (param_equipiers ? ',' : '')+equipier.value
      });
      const url = new URL(window.location)
      url.searchParams.set('equipiers', param_equipiers)
      window.history.pushState({}, '', url)

      // On met à jour la liste d'équipiers
      setSelectedEquipiers([...select_equipiers])
    }
  }

  // Filtre par type de tâche
  const filterByTypeTask = (e) => {
    // Met à jour le type
    setSelectedTypeTask(e.target.value)

    const url = new URL(window.location)
    url.searchParams.set('type', e.target.value)
    window.history.pushState({}, '', url)
  }


  // Filtre par date => recharge les données
  const filterByDate = async (newdate) => {
    setIsLoading(true)

    const date = new Date(newdate)
    const format_date = formatDate(date)
    const new_date = format_date.an+'-'+format_date.nmois+'-'+format_date.date
    setSelectedDate(new_date)

    const last_date = (date.getTime() + one_day * nb_days[init_vue])
    const format_last_date = formatDate(new Date(last_date))
    const new_last_date = format_last_date.an+"-"+format_last_date.nmois+"-"+format_last_date.date
    setLastDate(new_last_date)

    const url = new URL(window.location)
    url.searchParams.set('date', new_date)
    window.history.pushState({}, '', url)
  }

  // Filtre par vue de planning
  const filterByVue = (e) => {
    const new_vue = e.target.value

    // Met à jour la date de fin
    const date = new Date(selectedDate)
    const last_date = (date.getTime() + one_day * nb_days[new_vue])
    const format_last_date = formatDate(new Date(last_date))
    const new_last_date = format_last_date.an+"-"+format_last_date.nmois+"-"+format_last_date.date
    setLastDate(new_last_date)

    // Met à jour la vue
    setSelectedVue(new_vue)

    const url = new URL(window.location)
    url.searchParams.set('vue', new_vue)
    window.history.pushState({}, '', url)
  }

  // Rafraichissement des tâches et les occupations équipiers au changement des filtres
  useEffect(() => {
    refreshDatas()
    async function refreshDatas() {
      if ( Object.keys(datas).length > 0 && ( selectedVue === 'mois' || selectedVue === 'semaine' ) ) {
        // Rafraichissement des tâches
        let new_datas = JSON.parse(JSON.stringify((ordered_datas ? ordered_datas : datas)))
        Object.values(new_datas).forEach((data, i) => {
          data.items.forEach((item, i) => {
            item.zones = []
            item.locations = []
          });
        });
        new_datas = await getTasks(new_datas)
        new_datas = await getLocations(new_datas)
        setDatas({...new_datas})

        // Rafraichissement des équipiers
        if ( calendarEquipiers && calendarEquipiers.users ) {
          let query_hoomyIds = ''
          calendarEquipiers.users.items.forEach((item, i) => {
            query_hoomyIds += 'hoomyIds[]='+item.id+'&'
          });

          // Modification de la requête pour récupérer les données équipier
          const minDate = selectedDate+cpl_datetime
          const maxDate = lastDate+cpl_datetime_max
          setRouteCalendarEquipiers('/users?pagination=false&isActive=1&withMe=1&'+query_hoomyIds+'withEvents=true&events.timeMin='+minDate+'&events.timeMax='+maxDate)
        }
        setRefresh(false)
        setIsLoading(false)
      }
    }
    return () => {
      abortController.abort(); // Cancel the request if component unmounts
    }
  }, [selectedDate, selectedVue, refresh])


  // Rafraichissement des tâches et les occupations équipiers au changement des filtres
  useEffect(() => {
    // Si vue mois et semaine
    if ( selectedVue === 'mois' || selectedVue === 'semaine' ) {
      let new_datas = toggleHiddenZones(datas)
      setDatas({...new_datas})
    }
    // Sinon, vue jour
    else {
      let new_datas = toggleHiddenZones(dayDatas)
      setDayDatas([...new_datas])
    }
  }, [selectedTypeTask])


  // Fonction de gestion d'affichage ou non des zones de tâches
  const toggleHiddenZones = (new_datas) => {
    Object.values(new_datas).forEach((data, i) => {
      if ( selectedVue === 'mois' || selectedVue === 'semaine' ) {
        data.items.forEach((item, i) => {
          item.zones.forEach((zone, i) => {
            // Si un type est sélectionné
            if ( selectedTypeTask ) {
              // Si au moins une des tâches est du type sélectionné, on l'affiche
              // Sinon, on la cache
              zone.hidden = (zone.tasks.findIndex((task) => task.type === selectedTypeTask) >= 0 ? false : true)
            }
            // Sinon, on affiche toutes les tâches par défaut
            else {
              zone.hidden = false
            }
          })
        });
      }
      // Sinon, vue jour
      else {
        data.tasks.forEach((task, i) => {
          // Si un type est sélectionné
          if ( selectedTypeTask ) {
            task.values.show = (task.type === selectedTypeTask ? true : false)
          }
          // Sinon, on affiche toutes les tâches par défaut
          else {
            task.values.show = true
          }
        })
      }
    });
    return new_datas
  }

  const [fetchData] = useLoadDatas()
  const [datas, setDatas] = useState({})
  const [ordered_datas, setOrderedDatas] = useState({})
  const routeRentingGroups = '/renting-groups?pagination=false&order[position]=ASC&rentings.isActive=1&order[rentings.position]=ASC&order[rentings.owner.lastName]=ASC'

  let renting_by_user_id = ''
  switch (user.role) {
    case 'ROLE_MANAGER':
      renting_by_user_id = '&user='+user.hoomyId
    break;
    case 'ROLE_EQUIPIER':
    case 'ROLE_CLEANER':
      renting_by_user_id = (user.parent ? '&user='+user.parent.hoomyId : '')
    break;
  }
  const routeRentings = '/rentings?pagination=false&isActive=1'+renting_by_user_id+'&order[position]=ASC&order[owner.lastName]=ASC'
  useEffect(() => {
    loadData()
    async function loadData() {
      if ( selectedVue === 'mois' || selectedVue === 'semaine' ) {
        setIsLoading(true)

        let new_items = {}

        const rentingroup_datas = await fetchData(routeRentingGroups)

        // Si erreur
        if ( rentingroup_datas.error ) {
          setIsError(true)
        }
        // Si succès
        else {
          let loaded_rentings = []
          rentingroup_datas["hydra:member"].forEach((rentingroup, i) => {
            // S'il y a des rentings dans le groupe,
            // On les ajoute
            let renting_items = []
            if ( rentingroup.rentings.length ) {
              rentingroup.rentings.forEach((renting, i) => {
                renting_items.push({
                  id: renting.hoomyId,
                  name: renting.owner.lastName+(renting.rentingNameAlt ? " | "+renting.rentingNameAlt : "")+" | "+renting.rentingName,
                  position: renting.position,
                  zones: [],
                  locations: []
                })
                loaded_rentings.push(renting.hoomyId)
              });
            }
            new_items['c-'+rentingroup.id] = {
              id: 'c-'+rentingroup.id,
              name: rentingroup.name,
              position: rentingroup.position,
              classes: '',
              items: renting_items
            }
          })

          // Ajoute le conteneur non classés par défaut
          new_items['non-classes'] = {
            id: null,
            name: 'Non classés',
            classes: '',
            items: []
          }

          // Ensuite, on récupère les rentings non classés
          const renting_datas = await fetchData(routeRentings)

          // Si erreur
          if ( renting_datas.error ) {
            setIsError(true)
          }
          // Si succès
          else {
            renting_datas["hydra:member"].forEach((renting, i) => {
              if ( !loaded_rentings.includes(renting.hoomyId) ) {
                new_items['non-classes'].items.push({
                  id: renting.hoomyId,
                  name: renting.owner.lastName+(renting.rentingNameAlt ? " | "+renting.rentingNameAlt : "")+" | "+renting.rentingName,
                  zones: [],
                  locations: []
                })
              }
            })

            new_items = await getTasks(new_items)
            new_items = await getLocations(new_items)
          }
        }
        setDatas({...new_items})
        setIsLoading(false)
      }
    }
  }, [routeRentingGroups, selectedVue])


  // Fonction de chargement des tâches par rapport à la date sélectionnée dans les filtres
  const getTasks = async (items) => {
    const task_datas = await fetchData('/task-todos?pagination=false&startAt[before]='+lastDate+'&endAt[after]='+selectedDate)

    // Pour chaque tâche
    task_datas["hydra:member"].forEach((task, i) => {
      const task_renting_id = (task.renting ? task.renting.hoomyId :
        (task.location ? task.location.renting.hoomyId :
          (task.ticket ? task.ticket.renting.hoomyId : null)
        )
      )
      const taskStartAt = task.startAt.split('T')
      const taskStartAtDate = taskStartAt[0]
      const taskStartAtTime = taskStartAt[1].split(':00+')[0]

      const taskEndAt = task.endAt.split('T')
      const taskEndAtDate = taskEndAt[0]
      const taskEndAtTime = taskEndAt[1].split(':00+')[0]

      // Définit le task type
      let type_task = task.taskEvent.id.toString()
      if ( task.taskEvent.id === 3 ) {
        // Ménage de fin de séjour
        if ( task.taskType && task.taskType.id === 7 ) {
          type_task = '7'
        }
        else {
          if ( task.renting ) {
            type_task = 'renting'
          }
          else if ( task.location ) {
            type_task = 'location'
          }
          else if ( task.ticket ) {
            type_task = 'ticket'
          }
        }
      }

      let task_name = task.taskEvent.label
      // Si Intervention
      if ( task.taskEvent.id === 3 ) {
        const type_index = list_types.findIndex((type) => type.value === type_task)
        if ( type_index > 0 ) {
          task_name = list_types[type_index].label
        }
      }
      // Sinon, Accueil ou Départ
      else {
        task_name += (task.location ? " ("+task.location.tenant.lastName.trim()+")" : "")
      }

      // Liste équipiers
      let task_equipiers = []
      task.userTaskTodos.forEach((userTaskTodo, i) => {
        task_equipiers.push(userTaskTodo.user.firstName+' '+userTaskTodo.user.lastName)
      });

      const new_task = {
        id: task.id,
        name: task_name,
        type: type_task,
        location: (task.location ? task.location.hoomyId : null),
        renting: (task.renting ? task.renting.hoomyId : null),
        ticket: (task.ticket ? task.ticket.id : null),
        heures: taskStartAtTime+' - '+taskEndAtTime,
        equipiers: task_equipiers
      }

      // Identifie l'index du renting
      let groupIndex = null
      let itemIndex = null
      Object.values(items).forEach((item, i) => {
        if ( !groupIndex )  {
          const index = item.items.findIndex((data) => data.id === task_renting_id)
          if ( index >= 0 )  {
            groupIndex = item.id
            itemIndex = index
          }
        }
      });

      // Identifie l'index de la zone
      groupIndex = (groupIndex ? groupIndex : "non-classes")
      let zoneIndex = items[groupIndex].items[itemIndex].zones.findIndex((item) => item.dates.startAt === taskStartAtDate)
      if ( zoneIndex >= 0 ) {
        items[groupIndex].items[itemIndex].zones[zoneIndex].tasks.push(new_task)
      }
      else {
        zoneIndex = items[groupIndex].items[itemIndex].zones.length // Last Index
        items[groupIndex].items[itemIndex].zones.push({
          tasks: [new_task],
          rotate: false,
          dates: {
            style: 'full',
            startAt: taskStartAtDate,
            endAt: taskEndAtDate
          },
          styles: calculateStyles({
            startAt: taskStartAtDate,
            endAt: taskEndAtDate
          }),
          hidden: false
        })
      }

      // On ajuste la classe
      let classe = ''
      // Si rotation
      if ( task.isRotate ) {
        classe = 'task_rotation'
        items[groupIndex].items[itemIndex].zones[zoneIndex].rotate = true
        items[groupIndex].items[itemIndex].zones[zoneIndex].dates.style = 'full '+classe
      }
      // Sinon, on adapte selon le type de tâche
      else {
        switch ( task.taskEvent.id ) {
          case 1:
            classe = 'task_accueil'
          break;
          case 2:
            classe = 'task_depart'
          break;
          default:
            classe = 'task_intervention'
        }
        items[groupIndex].items[itemIndex].zones[zoneIndex].dates.style += ' '+classe
      }

      // Et on ajoute la location liée s'il y a
      if ( task.location ) {
        const location_id = task.location.hoomyId
        // Si la location n'existe pas dans la liste du renting
        if ( items[groupIndex].items[itemIndex].locations.findIndex((location) => location.id === location_id) < 0 ) {
          const taskStartAt = task.location.startAtUpdated.split('T')
          const format_date = formatDate(new Date(taskStartAt[0]))
          let desc = "Du "+format_date.date+"/"+format_date.nmois+"/"+format_date.an
          const taskStartAtDate = (taskStartAt[0] >= selectedDate ? taskStartAt[0] : selectedDate)
          const taskEndAt = task.location.endAtUpdated.split('T')
          const format_last_date = formatDate(new Date(taskEndAt[0]))
          desc += " au "+format_last_date.date+"/"+format_last_date.nmois+"/"+format_last_date.an
          const taskEndAtDate = (taskEndAt[0] <= lastDate ? taskEndAt[0] : lastDate)

          let title = ''
          let classe = ''
          // Si occupation propriétaire
          if ( task.location.ownerNoCheckIn ) {
            title = 'Occupation propriétaire '+task.location.renting.owner.lastName
            classe = 'proprio'
          }
          // Sinon location
          else {
            title = 'Location '+task.location.tenant.lastName
            classe = 'location'
          }
          items[groupIndex].items[itemIndex].locations.push({
            id: location_id,
            tooltip: {
              title: title,
              desc: desc
            },
            dates: {
              style: 'full '+classe,
              startAt: taskStartAtDate,
              endAt: taskEndAtDate
            },
            styles: calculateStyles({
              startAt: taskStartAtDate,
              endAt: taskEndAtDate
            })
          })
        }
      }

      // Rafraichi les zones cachées ou non
      if ( selectedTypeTask ) {
        items = toggleHiddenZones(items)
      }
    })
    return items
  }


  // Fonction de chargement des locations sans tâches par rapport à la date sélectionnée dans les filtres
  const getLocations = async (items) => {
    const location_datas = await fetchData('/locations?pagination=false&exists[taskTodos]=false&startAt[before]='+lastDate+'&endAt[after]='+selectedDate)

    // Pour chaque tâche
    location_datas["hydra:member"].forEach((location, i) => {
      // On ajoute la location
      if ( location ) {
        const location_renting_id = (location.renting ? location.renting.hoomyId : null)
        const location_id = location.hoomyId

        // Identifie l'index du renting
        let groupIndex = null
        let itemIndex = null
        Object.values(items).forEach((item, i) => {
          if ( !groupIndex )  {
            const index = item.items.findIndex((data) => data.id === location_renting_id)
            if ( index >= 0 )  {
              groupIndex = item.id
              itemIndex = index
            }
          }
        });

        // Identifie l'index de la zone
        groupIndex = (groupIndex ? groupIndex : "non-classes")

        // Si la location n'existe pas dans la liste du renting
        if ( items[groupIndex].items[itemIndex] && items[groupIndex].items[itemIndex].locations.findIndex((location) => location.id === location_id) < 0 ) {
          const taskStartAt = location.startAtUpdated.split('T')
          const format_date = formatDate(new Date(taskStartAt[0]))
          let desc = "Du "+format_date.date+"/"+format_date.nmois+"/"+format_date.an
          const taskStartAtDate = (taskStartAt[0] >= selectedDate ? taskStartAt[0] : selectedDate)
          const taskEndAt = location.endAtUpdated.split('T')
          const format_last_date = formatDate(new Date(taskEndAt[0]))
          desc += " au "+format_last_date.date+"/"+format_last_date.nmois+"/"+format_last_date.an
          const taskEndAtDate = (taskEndAt[0] <= lastDate ? taskEndAt[0] : lastDate)

          let title = ''
          let classe = ''
          // Si occupation propriétaire
          if ( location.ownerNoCheckIn ) {
            title = 'Occupation propriétaire '+location.renting.owner.lastName
            classe = 'proprio'
          }
          // Sinon location
          else {
            title = 'Location '+location.tenant.lastName
            classe = 'location'
          }
          items[groupIndex].items[itemIndex].locations.push({
            id: location_id,
            tooltip: {
              title: title,
              desc: desc
            },
            dates: {
              style: 'full '+classe,
              startAt: taskStartAtDate,
              endAt: taskEndAtDate
            },
            styles: calculateStyles({
              startAt: taskStartAtDate,
              endAt: taskEndAtDate
            })
          })
        }
      }
    })
    return items
  }




  // Fonction de chargement des tâches par rapport à la date sélectionnée dans les filtres
  const getDayTasks = async (items) => {
    const task_datas = await fetchData('/task-todos?pagination=false&startAt[after]='+selectedDate+'T00:00&endAt[before]='+selectedDate+'T23:59', user.token, abortController)
    const nbTasks = {}

    // Pour chaque tâche
    if ( !task_datas.error ) {
      task_datas["hydra:member"].forEach((task, i) => {
        const task_renting_id = (task.renting ? task.renting.hoomyId :
          (task.location ? task.location.renting.hoomyId :
            (task.ticket ? task.ticket.renting.hoomyId : null)
          )
        )
        let taskStartAt = task.startAt.split('T')[1].split(':00+')[0].split(':')
        let taskStartAtTime = parseInt(taskStartAt[0])+parseInt(taskStartAt[1])/60
        let taskEndAt = task.startAt.split('T')[1].split(':00+')[0].split(':')
        let taskEndAtTime = parseInt(taskEndAt[0])+parseInt(taskEndAt[1])/60
        let width = (taskEndAtTime - taskStartAtTime)
        if ( width === 0 ) {
          width = 1
        }

        let task_name = task.taskEvent.label
        // Si Intervention
        if ( task.taskEvent.id === 3 ) {
          task_name += (task.taskType ? " "+task.taskType.label : "")
        }
        // Sinon, Accueil ou Départ
        else {
          task_name += (task.location ? " ("+task.location.tenant.lastName.trim()+")" : "")
        }

        // Liste équipiers
        // let task_equipiers = []
        // task.userTaskTodos.forEach((userTaskTodo, i) => {
        //   task_equipiers.push(userTaskTodo.user.firstName+' '+userTaskTodo.user.lastName)
        // });

        // Définit le task type
        let type_task = task.taskEvent.id.toString()
        if ( task.taskEvent.id === 3 ) {
          // Ménage de fin de séjour
          if ( task.taskType && task.taskType.id === 7 ) {
            type_task = '7'
          }
          else {
            if ( task.renting ) {
              type_task = 'renting'
            }
            else if ( task.location ) {
              type_task = 'location'
            }
            else if ( task.ticket ) {
              type_task = 'ticket'
            }
          }
        }

        // On ajoute la location à la date
        let title = task.taskEvent.label
        if ( task.location ) {
          title += (task.location.tenant ? ' '+task.location.tenant.lastName.toUpperCase().trim() : '')
          title += (task.location.renting.owner.lastName.trim() ? ' | '+task.location.renting.owner.lastName.toUpperCase().trim() : '')
          title += (task.location.renting.rentingNameAlt ? ' | '+task.location.renting.rentingNameAlt : '')
          title += (task.location.renting.rentingName ? ' | '+task.location.renting.rentingName.toUpperCase() : '')
          if ( task.taskType && task.taskEvent.id === 3 ) {
            title += ' | '+task.taskType.label
          }
          else {
            title += (task.name ? ' | '+task.name.trim() : '')
          }
        }
        else if ( task.renting ) {
          title += (task.renting.owner.lastName.trim() ? ' | '+task.renting.owner.lastName.toUpperCase().trim() : '')
          title += (task.renting.rentingNameAlt ? ' | '+task.renting.rentingNameAlt : '')
          title += (task.renting.rentingName ? ' | '+task.renting.rentingName.toUpperCase() : '')
          if ( task.taskType && task.taskEvent.id === 3 ) {
            title += ' | '+task.taskType.label
          }
          else {
            title += (task.name ? ' | '+task.name.trim() : '')
          }
        }
        else if ( task.ticket ) {
          title += (task.ticket.renting.owner.lastName.trim() ? ' | '+task.ticket.renting.owner.lastName.toUpperCase().trim() : '')
          title += (task.ticket.renting.rentingNameAlt ? ' | '+task.ticket.renting.rentingNameAlt : '')
          title += (task.ticket.renting.rentingName ? ' | '+task.ticket.renting.rentingName.toUpperCase() : '')
          title += (task.name ? ' | '+task.name.trim() : '')
        }

        let title_more = (task.userTaskTodos.length === 0 ? 'Non attribué' : '')

        // Par défaut, la date de début de la tâche est celle définie sur la location
        let startDate = task.startAt

        // On ajoute l'heure de début et fin selon la durée de la tâche pour l'équipier
        if ( startDate ) {
          // const time = formatTime(new Date(startDate), duree)
          // title += (time ? ' | '+time.debut + (time.fin ? ' - '+time.fin : '') : '')
        }

        const new_task = {
          id: task.id,
          name: task_name,
          type: type_task,
          location: (task.location ? task.location.hoomyId : null),
          renting: (task.renting ? task.renting.hoomyId : null),
          ticket: (task.ticket ? task.ticket.id : null),
          values: {
            id: (task.location ? task.location.hoomyId : null),
            id_renting: (task.renting ? task.renting.hoomyId : null),
            id_ticket: (task.ticket ? task.ticket.id : null),
            id_task: task.id,
            type: task.taskEvent.id,
            color: '',
            value: title,
            value_more: title_more,
            status: (task.checkedEvent ? 'closed' : 'in_progress'),
            services: task.hasOrders,
            rotate: task.isRotate,
            comment: (task.location && task.location.commentUser ? true : false),
            classe: "planning",
            show: true
          },
          // heures: taskStartAtTime+' - '+taskEndAtTime,
          // dates: {
          //   style: 'full',
          //   startAt: taskStartAtTime,
          //   endAt: taskEndAtTime
          // },
          styles: {
            left: (100 / 24 * taskStartAtTime)+'%',
            width: (100 / 24 * width)+'%'
          }
        }

        // Attribution de la tâche
        if ( task.userTaskTodos.length ) {
          // Pour chaque équipier concerné, on ajoute la tâche
          task.userTaskTodos.forEach((userTaskTodo, i) => {
            const new_user_task = JSON.parse(JSON.stringify(new_task))

            // Identifie l'index du renting
            const index = items.findIndex((item) => item.id === userTaskTodo.user.hoomyId)
            const itemIndex = (index >= 0 ? index : 0)

            // Si l'équipier a une couleur définie
            if ( userTaskTodo.user.color ) {
              new_user_task.values.color = userTaskTodo.user.color
            }

            // Si l'équipier a une heure spécifique, on la remonte
            if ( userTaskTodo.teammateStartAt ) {
              taskStartAt = userTaskTodo.teammateStartAt.split('T')[1].split(':00+')[0].split(':')
              taskStartAtTime = parseInt(taskStartAt[0])+parseInt(taskStartAt[1])/60
            }
            // Duration
            if ( userTaskTodo.duration ) {
              taskEndAtTime = taskStartAtTime+parseFloat(userTaskTodo.duration)
            }
            else {
              taskEndAtTime = taskStartAtTime+1
            }
            width = (taskEndAtTime - taskStartAtTime)

            // On incrémente le nombre de tâche pour cet équipier à cette heure
            if ( !nbTasks[itemIndex] ) {
              nbTasks[itemIndex] = {}
            }
            if ( nbTasks[itemIndex][taskStartAtTime] === undefined ) {
              nbTasks[itemIndex][taskStartAtTime] = 0
            }
            else {
              nbTasks[itemIndex][taskStartAtTime] += 1
            }

            new_user_task.styles = {
              '--top': (57 * nbTasks[itemIndex][taskStartAtTime])+'px', // 52 + 5px de margin
              left: (100 / 24 * taskStartAtTime)+'%',
              width: (100 / 24 * width)+'%'
            }

            items[itemIndex].tasks.push({...new_user_task})
          });
        }
        else {
          // On incrémente le nombre de tâche dans les non attribués à cette heure
          if ( !nbTasks[0] ) {
            nbTasks[0] = {}
          }
          if ( nbTasks[0][taskStartAtTime] === undefined ) {
            nbTasks[0][taskStartAtTime] = 0
          }
          else {
            nbTasks[0][taskStartAtTime] += 1
          }
          new_task.styles['--top'] = (57 * nbTasks[0][taskStartAtTime])+'px' // 52 + 5px de margin

          // Sinon, on l'ajoute aux non attribués
          items[0].tasks.push({...new_task})
        }

        // Rafraichi les zones cachées ou non
        if ( selectedTypeTask ) {
          items = toggleHiddenZones(items)
        }
      })
    }

    // Classe les tasks par left pour chaque équipier
    items.forEach((item, i) => {
      // Ajuste la hauteur de la ligne pour l'équipier
      const height = Math.max(...Object.values((nbTasks[i] ? nbTasks[i] : 0)))
      if ( height > 0 ) {
        item.styles = {
          '--height': (57 * (height+1) + 5)+'px' // hauteur 52px + 5px de padding
        }
      }
      else {
        item.styles = {
          '--height': '63px'
        }
      }

      if ( item.tasks.length ) {
        // Classe les tasks par left pour chaque équipier
        item.tasks.sort(function(a, b) {
          // On se base sur le left
          const compare_left = parseFloat(a.styles.left) - parseFloat(b.styles.left)
          if ( compare_left !== 0 ) {
            return compare_left
          }
          else {
            // Sinon, on se base sur la largeur
            return parseFloat(b.styles.width) - parseFloat(a.styles.width)
          }
        })
      }
    })

    return items
  }


  const calculateStyles = (dates) => {
    const startAt = dates.startAt.split('T')[0]
    const endAt = dates.endAt.split('T')[0]

    const debut = (Math.round(new Date(startAt).getTime() - new Date(selectedDate).getTime()) / one_day).toFixed(0)
    const last = (Math.round(new Date(endAt).getTime() - new Date(selectedDate).getTime()) / one_day).toFixed(0)

    // Limiter l'affichage avec des minDate et maxDate ?
    return {
      left: (100 / (nb_days[selectedVue]+1) * debut)+'%',
      width: (100 / (nb_days[selectedVue]+1) * (last - debut > 0 ? last - debut + 1 : 1))+'%'
    }
  }

  const [scrollLeft, setScrollLeft] = useState(20);
  const handleScroll = (event) => {
    setScrollLeft(20-event.currentTarget.scrollLeft)
  }

  const orderedDatas = (modified_datas) => {
    setOrderedDatas({...modified_datas})
  }

  const calendrier = () => {
    switch (selectedVue) {
      case 'jour':
        let heures = []
        for (let h = 0; h <= 23; h++) {
          heures.push(h)
        }
        // Si différentes, on change la date de fin à la date du début
        if ( selectedDate !== lastDate ) {
          setLastDate(selectedDate)
        }
        return <>
          <div className="dayScale" data-zoom={zoom} onScroll={handleScroll}>
            <div id="headates" style={{left: scrollLeft+"px"}}>
              {/*
              <div id="selectDate">
                <button onClick={null}><Icon icon='fleche' /></button>
                <strong>{selectedDate}</strong>
                <button onClick={null}><Icon icon='fleche' /></button>
              </div>
              */}
              <div className="headate name"></div>
              <div className="headates">
                {heures.map((heure, index) => (
                  <div className="headate date" key={index}>
                    {heure}h
                  </div>
                ))}
              </div>
            </div>
            {dayDatas &&
              <div className="names">
                {dayDatas.map((data, index) => (
                  // Ajuste la hauteur de la ligne pour l'équipier
                  <div className="name" key={index} style={{...data.styles}}>
                    {data.name}
                  </div>
                ))}
              </div>
            }
            <DayContainer datas={dayDatas}
                          loadPopinTasks={loadPopinTasks} />
          </div>
        </>
      break;

      case 'semaine':
      case 'mois':
        const dates = generateDates(selectedDate, nb_days[selectedVue])
        return <>
          <div id="headates">
            <div className="headate name"></div>
            <div className="headates">
              {dates.map((date, index) => (
                <div className="headate date" key={index}>
                  {date.jour}
                  <strong>{date.date}</strong>
                </div>
              ))}
            </div>
          </div>
          {calendarEquipiers &&
            <MultipleContainers datas={calendarEquipiers}
                                strategy={rectSortingStrategy}
                                newGroup={false}
                                draggableContainer={false}
                                vertical
                                orderedDatas={() => {}} />
          }
          <MultipleContainers datas={datas}
                              strategy={rectSortingStrategy}
                              newGroup={true}
                              draggableContainer={true}
                              vertical
                              loadPopinTasks={loadPopinTasks}
                              orderedDatas={orderedDatas} />
        </>
      break;
      default:
        // RAS
        // setSelectedVue('mois')
        setSelectedVue( (queryParams.get('vue') ? queryParams.get('vue') : 'mois') )
    }
  }


  const generateDates = (init_date, nb_days) => {
    let date = new Date(init_date)

    // Créer tableau de date
    let dates = [generateDate(date)]

    // Ajoute les dates suivantes
    let last_date = null
    for (var i = 1; i <= nb_days; i++) {
      date.setDate(date.getDate() + 1);
      last_date = generateDate(date)
      dates.push(last_date)
    }

    // Met à jour la date de fin uniquement si différente
    const new_last_date = last_date.an+"-"+last_date.nmois+"-"+last_date.date
    if ( new_last_date !== lastDate ) {
      setLastDate(new_last_date)
    }

    return dates
  }

  const generateDate = (date) => {
    return {
      jour: date.toLocaleDateString('fr-FR', {weekday: 'short'}),
      date: date.toLocaleDateString('fr-FR', {day:'2-digit'}),
      // mois: date.toLocaleDateString('fr-FR', {month:'long'}),
      nmois: date.toLocaleDateString('fr-FR', {month:'2-digit'}),
      an: date.toLocaleDateString('fr-FR', {year:'numeric'})
    }
  }

  const [routeEquipiers, setRouteEquipiers] = useState('/users?pagination=false&isActive=1&withMe=1&order[firstName]=ASC')
  const [equipiers, setEquipiers] = useState([])
  const [optionsEquipiers, setOptionsEquipiers] = useState([])
  const [fetchEquipiers] = useLoadDatas()
  useEffect(() => {
    loadEquipiers()
    async function loadEquipiers() {
      if ( selectedVue === 'mois' || selectedVue === 'semaine' ) {
        const json_datas = await fetchEquipiers(routeEquipiers)
        if ( json_datas["hydra:member"] ) {
          let json_equipiers = []
          let select_options = []
          let selected_equipiers = []
          let query_hoomyIds = ''

          // Gère le user connecté
          // json_equipiers.push({
          //   id: user.hoomyId,
          //   name: 'Moi',
          //   color: user.color
          // })
          // select_options.push({
          //   label: 'Moi',
          //   value: user.hoomyId
          // })
          // if ( init_equipiers.includes(user.hoomyId.toString()) ) {
          //   selected_equipiers.push({
          //     label: 'Moi',
          //     value: user.hoomyId
          //   })
          //   query_hoomyIds += 'hoomyIds[]='+user.hoomyId+'&'
          // }

          // On ajoute les autres équipiers
          json_datas["hydra:member"].forEach((equipier) => {
            const name = equipier.firstName+" "+equipier.lastName
            json_equipiers.push(
              {
                id: equipier.hoomyId,
                name: name,
                color: equipier.color
              }
            )
            const select_option = {
              label: name,
              value: equipier.hoomyId
            }
            select_options.push(select_option)

            // Si l'équipier est pré-sélectionné
            if ( init_equipiers.includes(equipier.hoomyId.toString()) ) {
              selected_equipiers.push(select_option)
              query_hoomyIds += 'hoomyIds[]='+equipier.hoomyId+'&'
            }
          })
          setEquipiers([...json_equipiers])
          setOptionsEquipiers([...select_options])

          // S'il y a des équipiers pré-sélectionnés,
          // on les charge dans le calendrier
          if ( selected_equipiers.length ) {
            setSelectedEquipiers([...selected_equipiers])

            // Modification de la requête pour récupérer les données équipier
            const minDate = selectedDate+cpl_datetime
            const maxDate = lastDate+cpl_datetime_max
            setRouteCalendarEquipiers('/users?pagination=false&isActive=1&withMe=1&order[firstName]=ASC&'+query_hoomyIds+'withEvents=true&events.timeMin='+minDate+'&events.timeMax='+maxDate)
          }
        }
      }
    }
  }, [routeEquipiers, selectedVue])


  const [routeCalendarEquipiers, setRouteCalendarEquipiers] = useState(null)
  const [calendarEquipiers, setCalendarEquipiers] = useState({})
  useEffect(() => {
    loadCalendarEquipiers()
    async function loadCalendarEquipiers() {
      if ( routeCalendarEquipiers && (selectedVue === 'mois' || selectedVue === 'semaine') ) {
        const json_datas = await fetchEquipiers(routeCalendarEquipiers)
        // Si erreur
        if ( json_datas.error ) {
          setIsError(true)
        }
        // Si succès
        else {
          // Détermine si le tableau existe déjà
          let json_equipiers = calendarEquipiers
          if ( Object.keys(json_equipiers).length === 0 ) {
            json_equipiers = {
              users: {
                id: 'users',
                name: 'Équipiers',
                items: []
              }
            }

            // On vérifie que tous les users sont bien dans le calendrier
            // selectedEquipiers.forEach((equipier, i) => {
            //   console.log(equipier)
            //   json_equipiers.users.items.push({
            //     id: equipier.value,
            //     name: equipier.label,
            //     zones: []
            //   })
            // })
          }

          // Pour chaque équpier
          json_datas["hydra:member"].forEach((equipier) => {
            let zones = []
            if ( equipier.events ) {
              // Pour chaque événement de l'équipier
              equipier.events.forEach((event) => {
                const startAt = event.startAt.split('T')
                const startAtTime = startAt[1].split(':00+')[0]
                const startAtDate = (startAt[0] >= selectedDate ? startAt[0] : selectedDate)

                const endAt = event.endAt.split('T')
                const endAtTime = endAt[1].split(':00+')[0]

                // Cas spécifique de la date de fin à minuit, on considère que le jour est la veille
                let tmpEndAt = endAt[0]
                if ( endAtTime === "00:00" ) {
                  const tmpDate = new Date(tmpEndAt)
                  tmpDate.setDate(tmpDate.getDate() -1)
                  const newEndDate = generateDate(tmpDate)
                  tmpEndAt = newEndDate.an+"-"+newEndDate.nmois+"-"+newEndDate.date
                }
                const endAtDate = (tmpEndAt <= lastDate ? tmpEndAt : lastDate)

                let title = ''
                let classe = ''
                let event_desc = ''
                // Si même date
                if ( startAt[0] === endAt[0] ) {
                  title = '⚠️'
                  classe = 'partial'
                  event_desc = startAtTime+" - "+endAtTime
                }
                else {
                  title = ''
                  classe = 'full abs_'+event.eventType.toLowerCase()
                }

                zones.push({
                  id: event.id,
                  title: title,
                  tooltip: {
                    title: event.name,
                    desc: event_desc
                  },
                  // type: event.eventType,
                  dates: {
                    style: classe,
                    startAt: startAtDate,
                    endAt: endAtDate
                  },
                  styles: calculateStyles({
                    startAt: startAtDate,
                    endAt: endAtDate
                  })
                })
              })
            }

            const equipier_datas = {
              id: equipier.hoomyId,
              name: equipier.firstName+" "+equipier.lastName,
              zones: zones
            }

            // Si l'équipier existe déjà dans la liste, on le met à jour
            const equipierIndex = Object.values(json_equipiers.users.items).findIndex((item) => item.id === equipier.hoomyId)
            if ( equipierIndex >= 0 ) {
              json_equipiers.users.items[equipierIndex] = equipier_datas
            }
            // Sinon, on l'ajoute
            else {
              json_equipiers.users.items.push(equipier_datas)
            }
          })

          // MAJ le calendrier
          setCalendarEquipiers({...json_equipiers})
        }
      }
    }
  }, [routeCalendarEquipiers, selectedVue])


  // Equipier Vue Jour
  const [routeDayEquipiers, setRouteDayEquipiers] = useState('/users?pagination=false&isActive=1&withMe=1&order[firstName]=ASC')
  const [dayDatas, setDayDatas] = useState([])
  const [fetchDayEquipiers] = useLoadDatas()
  useEffect(() => {
    loadDayEquipiers()
    async function loadDayEquipiers() {
      if ( selectedVue === 'jour' ) {
        setIsLoading(true)
        const json_datas = await fetchDayEquipiers(routeDayEquipiers)
        if ( json_datas["hydra:member"] ) {
          let datas = [
            {
              id: "non-attribue",
              name: "Non attribué",
              color: null,
              tasks: []
            },
            // On ajoute l'utilisateur connecté
            // {
            //   id: user.hoomyId,
            //   name: 'Moi',
            //   color: user.color,
            //   tasks: []
            // }
          ]
          json_datas["hydra:member"].forEach((equipier) => {
            datas.push(
              {
                id: equipier.hoomyId,
                name: equipier.firstName+" "+equipier.lastName,
                color: equipier.color,
                tasks: []
              }
            )
          })

          // Récupérer les tâches du jour pour chaque équipier
          datas = await getDayTasks(datas) // A FAIRE

          // Met à jour les données
          setDayDatas([...datas])
          setIsLoading(false)
        }
      }
    }
  }, [routeDayEquipiers, selectedVue])


  // Equipier Vue Jour
  useEffect(() => {
    refreshDayTasks()
    async function refreshDayTasks() {
      if ( Object.keys(dayDatas).length > 0 && selectedVue === 'jour' ) {
        // Rafraichissement des tâches
        let new_datas = dayDatas
        Object.values(new_datas).forEach((data, i) => {
          data.tasks = []
        });
        // Récupérer les tâches du jour pour chaque équipier
        new_datas = await getDayTasks(new_datas)

        // Met à jour les données
        setDayDatas([...new_datas])
        setDayRefresh(false)
        setIsLoading(false)
      }
    }
    return () => {
      abortController.abort(); // Cancel the request if component unmounts
    }
  }, [selectedDate, dayRefresh])


  // Ouvre la popin et charge les contenus
  const [popinTasks, setPopinTasks] = useState(null)
  const loadPopinTasks = (renting_name, tasks) => {
    setPopinTasks({
      title: renting_name,
      tasks: tasks
    })

    // On ajoute au body
  	document.getElementsByTagName("body")[0].classList.add("popin-tasks-active")
  }

  const popinUpdateDatas = (todo) => {
    if ( todo ) {
      setIsLoading(true)

      if ( selectedVue === 'jour' ) {
        setDayRefresh(true)
      }
      else {
        setRefresh(true)
      }
    }
  }

  return (
    <>
      <main id="planning-board" className="planning-board">
        <div className="header">
          <h1>Supra planning</h1>
          <div id="filters" className={isFixed ? 'fixed' : ''}>
            <div className="innerMediumWidth">
              <div className={`select_equipier ${selectedVue === 'jour' ? 'hidden' : ''}`}>
                <label htmlFor="planning">planning</label>
                <MultiSelect
                  options={optionsEquipiers}
                  value={selectedEquipiers}
                  onChange={(e) => filterEquipiers(e)}
                  labelledBy="planning"
                  hasSelectAll={false}
                  disableSearch={true}
                  valueRenderer={(selected, _options) => {
                    if ( !selected.length ) {
                      return "Aucun équipier sélectionné"
                    }
                    else if (selected.length === 1 ) {
                      return selected.length+" équipier sélectionné"
                    }
                    else {
                      return selected.length+" équipiers sélectionnés"
                    }
                  }}
                />
              </div>

              <div className="type">
                <label htmlFor="type">Type</label>
                <select id="type" name="type"
                        onChange={(e) => filterByTypeTask(e)}
                        value={(selectedTypeTask ? selectedTypeTask : '')}>
                  <option value="">Tous</option>
                  {list_types.length &&
                    list_types.map((type, key) => {
                      return (
                        <option key={key} value={type.value}>
                          {type.label}
                        </option>
                      )
                    })
                  }
                </select>
              </div>

              <DatePickerCustom
                date={selectedDate}
                onChange={(date) => filterByDate(date)}
              />

              <div className="vue">
                <label htmlFor="vue">Vue</label>
                <select id="vue" name="vue"
                        onChange={(e) => filterByVue(e)}
                        value={(selectedVue ? selectedVue : '')}>
                  <option value="mois">Mensuelle</option>
                  <option value="semaine">2 semaines</option>
                  <option value="jour">Journée</option>
                </select>
              </div>
            </div>
            {selectedVue === 'jour' &&
              <div className="zoomPanel">
                <button onClick={(e) => setZoom(zoom < 5 ? zoom+1 : 5)}>
                  <Icon icon="zoom-in" />
                </button>
                <button onClick={(e) => setZoom(zoom > 1 ? zoom-1 : 1)}>
                  <Icon icon="zoom-out" />
                  </button>
                <button onClick={(e) => setZoom(1)}>
                  <Icon icon="zoom-reset" />
                </button>
              </div>
            }
          </div>
        </div>
        <div className={`content ${isFixed ? 'fixed' : ''}`}>
          {calendrier()}

          <div className={`more-tasks hasloader ${isLoading ? 'loadin' : ''}`}>
            <Loader />
          </div>
        </div>
      </main>

      <PopinTasks datas={popinTasks} updateDatas={popinUpdateDatas} />
    </>
  )
}
