import useUser from './useUser'

const useSaveDatas = () => {
  const { user, checkUser  } = useUser()

  const saveDatas = async (route, options, parameters, force_admin=false) => {
    if ( !route ) return

    // console.log("saveDatas", route, options, parameters)
    let token = options.user_token

    // Si Admin token
    if ( !force_admin && localStorage.getItem('admin_user') ) token = JSON.parse(localStorage.getItem('admin_user')).token

    // Sinon on utilise le token de l'utilisateur
    if ( !token ) token = await checkUser(user)

    const fetchHeaders = new Headers()
    if ( !options || (options && options.type !== "file") ) {
      fetchHeaders.append("Accept", "application/json")
      fetchHeaders.append("Content-Type", "application/json")
    }
    fetchHeaders.append("Authorization", "Bearer "+token)


    // Hack modif API Plateforme
    if ( parameters.location ) {
      parameters.location = '/locations/'+parameters.location
    }
    if ( parameters.taskTodo ) {
      parameters.taskTodo = '/task-todos/'+parameters.taskTodo
    }
    if ( parameters.taskEvent ) {
      parameters.taskEvent = '/task-events/'+parameters.taskEvent
    }
    if ( parameters.taskState ) {
      parameters.taskState = '/task-states/'+parameters.taskState
    }
    if ( parameters.taskType ) {
      parameters.taskType = '/task-types/'+parameters.taskType
    }
    if ( parameters.taskBilling ) {
      parameters.taskBilling = '/task-billings/'+parameters.taskBilling
    }
    if ( parameters.inventory ) {
      parameters.inventory = '/inventories/'+parameters.inventory
    }
    if ( parameters.user ) {
      parameters.user = '/users/'+parameters.user
    }
    if ( parameters.renting ) {
      parameters.renting = '/rentings/'+parameters.renting
    }
    if ( parameters.ticket ) {
      parameters.ticket = '/tickets/'+parameters.ticket
    }
    if ( parameters.level ) {
      parameters.level = '/ticket-levels/'+parameters.level
    }
    if ( parameters.state ) {
      parameters.state = '/ticket-states/'+parameters.state
    }

    return await fetch(process.env.REACT_APP_API+route, {
      method: ((options && options.method) ? options.method : 'POST'),
      headers: fetchHeaders,
      withCredentials: true,
      mode: 'cors',
      body: ((options && options.type === "file") ? parameters : JSON.stringify(parameters))
    })
    .then(response => {
      return {
        ok: response.ok,
        api: response.json()
      }
    })
    .then(json_datas => {
      return json_datas.api.then(function(result) {
        if ( json_datas.ok ) { // Si succès
          return result
        }
        else { // Si erreur
          let errors = []
          if ( result.violations ) {
            result.violations.forEach((violation, i) => {
              errors.push(violation.message)
            })
            return {
              error: true,
              message: "Veuillez ajuster les erreurs suivantes :",
              errors: errors
            }
          }
          else {
            return {
              error: true,
              message: "L'enregistrement a rencontré un problème."
            }
          }
        }
      })
    })
    .catch(error => {
      return {
        error: true,
        message: "Erreur d'API."
      }
    })
  }

  // Unmount
  return [saveDatas]
}

export default useSaveDatas
