// Librairies React
import React from 'react'
import { NavLink } from 'react-router-dom'
import Icon from './Icon'

export default function BlocTicket(props) {
  return (
    <NavLink exact to={`/ticket/${props.id}/`} itemProp="url"
             className={`task${props.show ? " active" : ""}`}>
      <span>{props.value}</span>
      {props.value_more &&
        <em>{props.value_more}</em>
      }
      <div className='icons'>
        {props.state === 'traite' &&
          <Icon icon='checked' title='Ticket traité' />
        }
      </div>
    </NavLink>
  )
}
