import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Loader from '../components/Loader'
import ForgotPassword from '../components/ForgotPassword'
import useForm from '../hooks/useForm'
import KeepAlive, { useAliveController } from 'react-activation'

// PropTypes
Login.propTypes = {
  setUser: PropTypes.func.isRequired
}

export default function Login({ setUser }) {
  // Nettoie les caches à la connexion
  const { clear } = useAliveController()
  clear()

  const [isLoading, setIsLoading] = useState(false)
  // const [isError, setIsError] = useState(false)
  const [forgotPassword, setForgotPassword] = useState(false)

  const { setErrors, showFields, getFields,
          setSuccess, showReturnMessage } = useForm([
    {
      name: "email",
      type: "email",
      label: "Votre email",
      placeholder: "Saisissez votre email",
      error: "Votre email",
      required: true,
      required_format: "email",
      error_format: "Le format de votre email n'est pas correct",
      value: (process.env.NODE_ENV==="development" ? "presquilederhuys@hoomy.fr" : "")
    },
    {
      name: "password",
      type: "password",
      label: "Mot de passe",
      placeholder: "Saisissez votre mot de passe",
      error: "Votre mot de passe",
      required: true,
      // required_format: "password",
      // error_format: "Le mot de passe doit comporter au moins 10 caractères dont au moins 1 lettre et au moins 1 chiffre",
      value: (process.env.NODE_ENV==="development" ? "123soleil" : "")
    }
  ])

  const handleSubmit = async e => {
    e.preventDefault()

    // Réinitialise avant traitement
    setIsLoading(true)
    setErrors({})

    // Traitement
    const parameters = getFields(e.target)
    if ( Object.keys(parameters.errors).length > 0 ) {
      setIsLoading(false)
      setErrors({
        message: "Veuillez compléter ou corriger les champs suivants :",
        fields: parameters.errors
      })
    }
    else {
      const user = await loginUser(parameters.values)
      setIsLoading(false)
      if ( user ) {
        const profile = await getUserProfile(user.token)
        const new_user = {...user, ...profile}
        setUser(new_user)
      }
    }
  }

  const loginUser = async (parameters) => {
    const fetchHeaders = new Headers()
    fetchHeaders.append("Content-Type", "application/json")
    // fetchHeaders.append("Accept", "application/ld+json")
    // fetchHeaders.append("Access-Control-Allow-Origin", "*")
    // fetchHeaders.append("Access-Control-Allow-Credentials", "true")
    // fetchHeaders.append("Access-Control-Allow-Methods", "GET,HEAD,OPTIONS,POST,PUT")
    // fetchHeaders.append("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept, Authorization")

    return fetch(process.env.REACT_APP_API+'/users/get-token', {
      method: 'POST',
      headers: fetchHeaders,
      // mode: 'cors',
      // cache: 'default',
      // credentials: "include", // include, *same-origin, omit
      // referrer: "*client",
      // redirect: "follow",
      body: JSON.stringify(parameters)
    })
    .then(response => {
      return {
        ok: response.ok,
        api: response.json()
      }
    })
    .then(json_datas => {
      setIsLoading(false)
      return json_datas.api.then(function(result) {
        if ( json_datas.ok ) { // Si succès
          return result
        }
        else { // Si erreur
          setErrors({
            message: result['hydra:description']
          })
          return false
        }
      })
    })
    .catch(error => {
      console.log('error', error)
      setErrors({
        message: "Erreur de formulaire"
      })
    })
  }

  const getUserProfile = async (token) => {
    const fetchHeaders = new Headers()
    fetchHeaders.append("Content-Type", "application/json")
    fetchHeaders.append("Authorization", "Bearer "+token)

    return fetch(process.env.REACT_APP_API+'/users/me', {
      method: 'GET',
      headers: fetchHeaders
    })
    .then(response => {
      return {
        ok: response.ok,
        api: response.json()
      }
    })
    .then(json_datas => {
      setIsLoading(false)
      return json_datas.api.then(function(result) {
        if ( json_datas.ok ) { // Si succès
          return result
        }
        else { // Si erreur
          setErrors({
            message: result['hydra:description']
          })
          return false
        }
      })
    })
    .catch(error => {
      setIsLoading(false)
      setErrors({
        message: "Erreur de formulaire"
      })
    })
  }

  return(
    <main className="login">
      <div className="header">
        <h1>Connectez-vous !</h1>
      </div>
      <div className="content innerSmallWidth">
        <form id="loginForm"
              className={`form hasloader ${isLoading ? 'loadin' : ''}`}
              onSubmit={handleSubmit}>

          { showReturnMessage() }

          { showFields() }

          <div className="form-submit">
            <button type="submit" className="btn">Je me connecte</button>
          </div>

          <Loader />
        </form>

        <button type="button" className="link" onClick={() => setForgotPassword(true)}>1ère connexion ou mot de passe oublié ? C'est par ici !</button>

        {forgotPassword &&
          <ForgotPassword onClose={() => setForgotPassword(false)} />
        }
      </div>
    </main>
  )
}
